<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">        
        <div class="modal-header">
          <h3>Sub Type Data</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>        
        <div class="modal-body">
          <form @submit.prevent="submitSubTypeForm">
            <table class="itemTable">              
              <tr>
                <td class="titleTd">Main Type:</td>
                <td>
                  <input class="readOnly" type="text" v-model="subTypeInputs.maintype" readonly />
                </td>
              </tr>
            </table>
            <table  class="itemTable">
              <tr>
                <td class="titleTd">
                  Add Sub Type 
                  <button type="button" class="addLineButton" @click="addSubType">+</button>
                </td>
              </tr>                          
              <tr v-for="(subType, subIndex) in subTypeInputs.subTypes" :key="subIndex">
                <td class="titleTd">
                  Sub Type {{ subIndex + 1 }} Name:
                  <input class="searchInput" type="text" v-model="subType.name" placeholder="Sub type name" />
                </td>
                <td>
                  <div>                    
                    <input class="searchInput" type="text" v-model="subType.materialCodes[0].macode" placeholder="Material Code 1" />
                    <input class="searchInput quantityInput" type="number" v-model="subType.materialCodes[0].maqty" placeholder="Quantity 1" />
                    <button type="button" class="addLineButton" @click="addSubTypeMaterialCode(subIndex)">+</button>
                  </div>
                  <div v-for="(code, codeIndex) in subType.materialCodes.slice(1)" :key="codeIndex">                    
                    <input class="searchInput" type="text" v-model="subType.materialCodes[codeIndex + 1].macode" :placeholder="'Material Code ' + (codeIndex + 2)" />
                    <input class="searchInput quantityInput" type="number" v-model="subType.materialCodes[codeIndex + 1].maqty" :placeholder="'Quantity ' + (codeIndex + 2)" />
                    <button type="button" class="addLineButton" @click="addSubTypeMaterialCode(subIndex)">+</button>
                  </div>
                </td>
              </tr>
            </table>
            <button class="submitButton" type="submit" :disabled="isProcessing">Save</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue';

export default {
  name: 'SubTypeModal',
  props: { mainType: { type: String, required: true }},
  emits: ['onSubmit', 'onClose'],
  setup(props, { emit }) {    
    const subTypeInputs = reactive({ maintype: props.mainType, subTypes: [] });
    const isProcessing = ref(false);
    
    const addSubType = () => { subTypeInputs.subTypes.push({ name: '', materialCodes: [ { macode: '', maqty: '' } ]  }); };
    
    const addSubTypeMaterialCode = (subIndex) => { subTypeInputs.subTypes[subIndex].materialCodes.push({ macode: '', maqty: '' });};
    
    const submitSubTypeForm = () => {
      let subTypeData = {};
      subTypeInputs.subTypes.forEach(sub => {
        if (sub.name) { subTypeData[sub.name] ={ code: '', quantity: 0, material: sub.materialCodes };}
      });
      emit('onSubmit', subTypeData);
      emit('onClose');
    };

    const closeModal = () => { emit('onClose');};

    return {
      subTypeInputs, isProcessing, addSubType, addSubTypeMaterialCode, submitSubTypeForm, closeModal
    };
  }
};
</script>

<style scoped>
@import '../../generalStyle.css';
.modal-wrapper { max-width: 800px;}
</style>
