<template>
  <div class="modal-mask">
  <div class="modal-wrapper">
  <div class="modal-container">
  <div class="modal-header">
    <h3>Add Material</h3>
    <button class="close-button" @click="closeModal">X</button>
  </div>
  <div class="modal-body">
    <form @submit.prevent="submitForm">              
      <table class="itemTable">        
        <tr>
          <td class="titleTd">Line Name:</td>                
          <select class="searchInput" v-model="formInputs.line">
            <option v-for="line in uniqueLines" :key="line">{{ line }}</option><option></option>        
          </select>     
        </tr>
        <tr>
          <td class="titleTd">Type</td>                    
          <td>
            <select class="searchInput" v-model="formInputs.type">
              <option v-for="materialtype in uniqueMaterialType" :key="materialtype">{{ materialtype }}</option><option></option>        
            </select>              
          </td>
        </tr>
        <tr>
          <td class="titleTd">Code</td>
          <td><input class="searchInput" type="text" v-model="formInputs.code"/></td>
        </tr>
        <tr>
          <td class="titleTd">Name</td>
          <td><input class="searchInput" type="text" v-model="formInputs.name"/></td>
        </tr>
        <tr>
          <td class="titleTd">Unit</td>
          <td><input class="searchInput" type="text" v-model="formInputs.unit"/></td>
        </tr>
        <tr>
          <td class="titleTd">Copper Weight</td>
          <td><input class="searchInput" type="text" v-model="formInputs.copperweight"/></td>
        </tr>
        <tr>
          <td class="titleTd">Copper Type</td>
          <td><input class="searchInput" type="text" v-model="formInputs.coppertype"/></td>
        </tr>
        <tr>
          <td class="titleTd">Coating</td>
          <td><input class="searchInput" type="text" v-model="formInputs.coating"/></td>
        </tr>
        <tr>
          <td class="titleTd">Remark</td>
          <td><input class="searchInput" type="text" v-model="formInputs.remark"/></td>
        </tr>
        <tr>
          <td class="titleTd">Row No</td>
          <td><input class="searchInput" type="text" v-model="formInputs.row_no"/></td>
        </tr>        
      </table>          
      <button class="submitButton" type="submit" :class="{ 'readOnly': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
      {{ saveStatus }}  
      </form>
    </div>        
  </div>      
  </div>
  </div>
</template>
  
<script>    
import { ref } from 'vue'; 
import axios from 'axios';   
import { getColumnMapping, makeFormData, useFormInputs, fetchData } from '../utils.js';
  
export default {                    
  setup(props, { emit }) {  
      const saveStatus = ref(''); 
      const isProcessing = ref(false);   
      const dbTableName = "materiallist";      
      const uniqueMaterialType = ref([]);
      const uniqueLines = ref([]);
      
      const { formInputs, dataForm } = useFormInputs();        

      const submitForm = async () => {
        
        isProcessing.value = true; 
        saveStatus.value = 'Processing...';                         

        const columnMapping = getColumnMapping(undefined, formInputs);            
        const formData = makeFormData(undefined, formInputs, dbTableName, columnMapping); 
            
        try {
          await axios.post('/api/post', formData);
          saveStatus.value = 'Completed'; 
        } catch (error) {
          console.error('Error during submission:', error);
          saveStatus.value = 'Error';
        } finally {
          isProcessing.value = false; 
        }     
        closeModal();              
      };     

      const closeModal = () => {  emit("onClose");  };   

      const loadMaterialType = async () => {
        uniqueMaterialType.value = await fetchData('selectoption', 'materialtype');
        uniqueMaterialType.value.sort((a, b) => a.localeCompare(b));
      }; loadMaterialType();   

      const loadLines = async () => {
        uniqueLines.value = await fetchData('machinedata', 'line');
        uniqueLines.value.sort((a, b) => a.localeCompare(b));
      }; loadLines(); 
           
      return { dbTableName, formInputs, submitForm, closeModal, 
               saveStatus, isProcessing, dataForm, uniqueMaterialType, uniqueLines,
      }; 
  },     
};
  
</script>
    
<style scoped>
@import '../generalStyle.css';

.modal-wrapper {
  max-width: 400px;
}
</style>