<!-- modifyModal2.vue -->
<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <!-- mainCategoryName는 상위에서 넘어온 props이지만, 여러 Main Type이 있을 경우 실제 키들은 mainTypes에서 관리 -->
          <h3>Modify Main Category with Sub Types - {{ mainCategoryName }}</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">          
          <form @submit.prevent="submitForm">            
            <button type="submit" class="submitButton">Save</button>
            {{ saveStatus }}
            <div v-for="(mainData, mainKey) in mainTypes" :key="mainKey" class="main-type-section">
              <table class="itemTable">
                <tr>
                  <td class="titleTd" style="text-align: left; width: 200px">Main Type</td>
                  <td>                    
                    <input type="text" v-model="mainTypeNames[mainKey]"  @change="renameMainType(mainKey, mainTypeNames[mainKey])" placeholder="Enter main type name" />
                  </td>
                </tr>
              <!--  <tr>
                  <td class="titleTd" style="text-align: left;">Product(or Semi) Code</td>
                  <td>
                    <input type="text" v-model="mainData.code" placeholder="Enter main code"/>
                  </td>
                </tr> -->
              </table>
              
              <div>
                <label class="subLabel">Sub Types</label>
                <div v-for="(subData, subKey) in getSubTypes(mainData)" :key="subKey" class="subtype-section">
                  <table class="itemTable">
                    <tr>
                      <td class="titleTd" style="text-align: left; width: 200px">Sub Type</td>
                      <td>                        
                        <input type="text" :value="subKey" @change="renameSubType(mainKey, subKey, $event.target.value)" placeholder="Enter sub type key" />
                      </td>
                    </tr>
                    <tr>
                      <td class="titleTd">Sub Product(or Semi) Code</td>
                      <td>
                        <input type="text" v-model="subData.code" placeholder="Enter sub code"/>
                      </td>
                    </tr>
                  </table>                  
                  
                  <label class="subLabel">Materials</label>
                  <table class="itemTable">
                    <tr>
                      <td class="titleTd">Material Code</td>
                      <td class="titleTd">Quantity</td>
                      <td class="titleTd">Remove</td>
                    </tr>
                    <tr v-for="(item, idx) in subData.material" :key="idx">
                      <td>
                        <input style="text-align: center;" type="text" v-model="item.macode" placeholder="Material Code"/>
                      </td>
                      <td>
                        <input style="text-align: center;" type="number" v-model.number="item.maqty" placeholder="0" />
                      </td>
                      <td>
                        <button class="delButton" type="button" @click="removeSubMaterial(mainKey, subKey, idx)">Remove</button>
                      </td>
                    </tr>
                  </table>
                  <button class="addItemButton" type="button" @click="addSubMaterial(mainKey, subKey)">Add Material</button>
                  <button class="delButton2" type="button" @click="removeSubType(mainKey, subKey)">Remove Sub Type</button>
                  <hr />
                </div>
                <button class="addTypeButton" type="button" @click="addSubType(mainKey)">Add Sub Type</button>
              </div>
              <hr />
            </div>            
            <div>
              <button class="addTypeButton" type="button" @click="addMainType">Add Main Type</button>
              <button class="delButton2" type="button" @click="removeMainType">Remove Main Type</button>
            </div>
            <br />
            <button type="submit" class="submitButton">Save</button>
            {{ saveStatus }}
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue';
import axios from 'axios';  
import { getColumnMapping, makeFormData, } from '../../utils.js';

export default {
  name: 'ModifyModal2',
  props: {    
    mainCategoryName: { type: String, required: true },    
    initialData: { type: Object, required: true },
    rowId: { type: [String, Number], required: true }
  },
  emits: ['onSubmit', 'onClose'],
  setup(props, { emit }) {    
    const dbTableName = "linedata";
    const saveStatus = ref(''); 
    const isProcessing = ref(false);   
    const formInputs = reactive({ id: props.rowId, [props.mainCategoryName]: {} });

    const mainTypes = reactive(JSON.parse(JSON.stringify(props.initialData)));
    const mainTypeNames = reactive({});
    Object.keys(mainTypes).forEach(key => { mainTypeNames[key] = key; });
    
    const subTypeCounters = reactive({});
    Object.keys(mainTypes).forEach(mKey => {
      subTypeCounters[mKey] = 1;
    });

    // 특정 Main Type 내부에서 'code', 'quantity' 이외의 key들은 모두 Sub Type으로 간주
    const getSubTypes = (mainData) => {
      const subTypes = {};
      for (const key in mainData) {
        if (key !== 'code' && key !== 'quantity') {
          subTypes[key] = mainData[key];
        }
      }
      return subTypes;
    };
    
    const renameMainType = (oldKey, newKey) => {
      if (newKey && newKey !== oldKey && !mainTypes[newKey]) {
        mainTypes[newKey] = mainTypes[oldKey];
        delete mainTypes[oldKey];
        mainTypeNames[newKey] = newKey;
        delete mainTypeNames[oldKey];
      
        if (subTypeCounters[oldKey]) {
          subTypeCounters[newKey] = subTypeCounters[oldKey];
          delete subTypeCounters[oldKey];
        }
      }
    };
   
    const renameSubType = (mainKey, oldSubKey, newSubKey) => {
      if (newSubKey && newSubKey !== oldSubKey && !mainTypes[mainKey][newSubKey]) {
        mainTypes[mainKey][newSubKey] = mainTypes[mainKey][oldSubKey];
        delete mainTypes[mainKey][oldSubKey];
      }
    };
   
    let mainTypeCounter = 1;
    const addMainType = () => {
      let newKey = `newMainType${mainTypeCounter}`;
      while (mainTypes[newKey]) {
        mainTypeCounter++;
        newKey = `newMainType${mainTypeCounter}`;
      }
      mainTypes[newKey] = {
        code: '',
        quantity: 0
      };
      mainTypeNames[newKey] = newKey;
      subTypeCounters[newKey] = 1;
      mainTypeCounter++;
    };
 
    const removeMainType = () => {
      const keys = Object.keys(mainTypes);
      if (keys.length > 1) {
        const keyToRemove = keys[keys.length - 1];
        delete mainTypes[keyToRemove];
        delete mainTypeNames[keyToRemove];
        delete subTypeCounters[keyToRemove];
      }
    };
   
    const addSubType = (mainKey) => {
      let count = subTypeCounters[mainKey] || 1;
      let newSubKey = `newSubType${count}`;
      while (mainTypes[mainKey][newSubKey]) {
        count++;
        newSubKey = `newSubType${count}`;
      }
      mainTypes[mainKey][newSubKey] = {
        code: newSubKey,
        quantity: 0,
        material: []
      };
      subTypeCounters[mainKey] = count + 1;
    };
   
    const removeSubType = (mainKey, subKey) => {
      delete mainTypes[mainKey][subKey];
    };
 
    const addSubMaterial = (mainKey, subKey) => {
      if (mainTypes[mainKey][subKey] && Array.isArray(mainTypes[mainKey][subKey].material)) {
        mainTypes[mainKey][subKey].material.push({ macode: '', maqty: 0 });
      }
    };
   
    const removeSubMaterial = (mainKey, subKey, index) => {
      if (mainTypes[mainKey][subKey] && Array.isArray(mainTypes[mainKey][subKey].material)) {
        mainTypes[mainKey][subKey].material.splice(index, 1);
      }
    };
   
    const submitForm = async () => {
      isProcessing.value = true; 
      saveStatus.value = 'Processing...';        

      for (const mKey in mainTypes) {
        mainTypes[mKey].quantity = 0;
        const subTypes = getSubTypes(mainTypes[mKey]);
        for (const sKey in subTypes) {
          subTypes[sKey].quantity = 0;
        }
      }
      
      const result = JSON.parse(JSON.stringify(mainTypes));

      if (props.mainCategoryName === 'ok_semi') { formInputs.ok_semi = result; }
      else if (props.mainCategoryName === 'ng_type') { formInputs.ng_type = result; }
      else if (props.mainCategoryName === 'ng_fake') { formInputs.ng_fake = result; }
      else if (props.mainCategoryName === 'ng_comp') { formInputs.ng_comp = result; }

      const columnMapping = getColumnMapping(undefined, formInputs);                    
      const formData = makeFormData(undefined, formInputs, dbTableName, columnMapping); 

      try {
        await axios.post('/api/post', formData);
        saveStatus.value = 'Completed'; 
      } catch (error) {
        console.error('Error during submission:', error);
        saveStatus.value = 'Error';
      } finally {
        isProcessing.value = false; 
      }     
      closeModal();  
    };

    const closeModal = () => { emit('onClose'); };

    return {
      mainTypes, mainTypeNames, getSubTypes, renameMainType, renameSubType, addMainType, removeMainType,
      addSubType, removeSubType, addSubMaterial, removeSubMaterial, submitForm, closeModal, saveStatus,
      isProcessing, formInputs
    };
  }
};
</script>

<style scoped>
@import '../../generalStyle.css';
.modal-wrapper { max-width: 600px; }
.itemTable { width: 100%; margin-bottom: 10px; }
.main-type-section { border: 1px solid #666; padding: 10px; margin-bottom: 15px; }
.subtype-section { border: 1px solid #ccc;  padding: 10px; margin-bottom: 10px; }
</style>
