<template>
    <div class="modal">
      <div class="modal-content">
        <span class="close" @click="$emit('close')">&times;</span>
        <h2>Change Information</h2>
        <table>
          <tbody>
            <tr><td><label>이름(Name)</label></td></tr>          
            <tr><td><input class="readonly" type="text" v-model="formInputs.name" readonly /></td></tr>
            <tr><td><label>부서(Department)</label></td></tr>          
            <tr><td><input type="text" v-model="formInputs.department" /></td></tr>
            <tr><td><label>직위(Position)</label></td></tr>          
            <tr><td><input type="text" v-model="formInputs.position" /></td></tr>
            <tr><td><label>핸드폰 번호(Phone Number)</label></td></tr>
            <tr><td><input type="text" v-model="formInputs.phonenumber" @input="validatePhoneNumber" /></td>
              <td>              
                <button class="checkButton" @click.prevent="checkDuplicate('phonenumber')" :class="{ 'disabled': !isPhoneNumberValid }" :disabled="!isPhoneNumberValid">확인</button>
              </td>
            </tr>
            <tr><td><span v-if="errors.phonenumber" :class="{'error': errors.phonenumber.includes('사용중인 값입니다.') || errors.phonenumber.includes('숫자만 입력 가능합니다.')  || errors.phonenumber.includes('값을 입력해주세요.'), 'success': errors.phonenumber.includes('사용 가능 합니다.') }">{{ errors.phonenumber }}</span></td></tr>
            <tr><td><label>이메일 주소(Email Address)</label></td></tr>
            <tr><td><input class="readonly" type="email" v-model="formInputs.emailaddress" readonly /></td>
              <td>
                <button class="checkButton" @click.prevent="sendVerificationEmail" >확인</button>
              </td>
            </tr>
            <tr><td>              
              <span v-if="isEmailAddressValid && !showVerification">확인 코드 이메일 전송중</span>
              <span v-if="showVerification">확인 코드 이메일 전송완료</span>
            </td></tr>
         
            <tr v-if="showVerification">
              <td>
                <input type="text" v-model="form.verifyCode" placeholder="Enter verification code" />
              </td>
              <td>
                <button class="verifyButton" @click.prevent="verifyCode">검증</button>
              </td>
            </tr>
            <tr>
              <td>
              <span :class="{ 'error': verificationStatus.includes('코드 재확인') || verificationStatus.includes('시간 초과'), 'success': verificationStatus.includes('인증 완료') }">
                {{ verificationStatus }}
              </span>
              </td>
            </tr>          
            <tr><td><label>아이디(ID)</label></td></tr>
            <tr><td><input class="readonly" type="text" v-model="formInputs.loginid" readonly /></td></tr>
            <tr><td><span v-if="errors.loginid" :class="{'error': errors.loginid.includes('사용중인 값입니다.') || errors.loginid.includes('소문자 영문자와 숫자만 입력 가능합니다.') || errors.loginid.includes('값을 입력해주세요.'), 'success': errors.loginid.includes('사용 가능 합니다.') }">{{ errors.loginid }}</span></td></tr>
            <tr><td><label>암호(Password)</label></td></tr>
            <tr><td><input :type="showPassword ? 'text' : 'password'" id="loginPw" v-model="formInputs.loginPw" @input="validatePassword" /></td>
              <td><button class="viewButton" @click="togglePasswordVisibility">{{ showPassword ? '숨기기' : '보기' }}</button></td></tr>
            <tr><td><span v-if="errors.loginPw" class="error">{{ errors.loginPw }}</span></td></tr>
            <tr><td><label>암호 재입력(Password rewrite)</label></td></tr>
            <tr><td><input :type="showPassword ? 'text' : 'password'" id="verifyPw" v-model="form.verifyPw" /></td>
              <td><button class="verifyButton" @click="checkPasswordMatch">검증</button></td></tr>
            <tr><td><span v-if="errors.verifyPw" :class="{'error': errors.verifyPw.includes('암호가 일치하지 않습니다.'), 'success': errors.verifyPw.includes('사용 가능 합니다.') }">{{ errors.verifyPw }}</span></td></tr>
          </tbody>
        </table>
        <button class="saveButton" type="submit" 
                :class="{ 'disabled': isProcessing || !canSave }" 
                @click.prevent="submitForm" 
                :disabled="isProcessing || !canSave">저장 (Save)</button>
        {{ saveStatus }}      
      </div>
    </div>
  </template>
  
  <script>  
  import axios from 'axios';
  import { reactive, ref, computed, watch } from 'vue';
  import { useFormInputs, fetchModifyData} from "../utils.js";
  
  export default {
    props: {
      rowId: {
        type: [String, Number],
        required: true
      }
    },
    setup(props, { emit }) {
    const dbTableName = 'members';
    const saveStatus = ref(''); 
    const isProcessing = ref(false);  
    const form = reactive({ verifyPw: '', verifyCode: '' });
    const originalPhoneNumber = ref('');

    const { formInputs, dataForm } = useFormInputs({id: "", signupdate: "", lastlogindate: "" })
    
    const errors = reactive({ phonenumber: '', emailaddress: '', loginid: '', loginPw: '', verifyPw: '' });
    const isPhoneNumberValid = ref(false);
    const isEmailAddressValid = ref(false);
    const isLoginIdValid = ref(false);
    const isPasswordValid = ref(false);
    const showVerification = ref(false);
    const verificationStatus = ref('');
    const verificationTimeout = ref(null);
    const expectedCode = ref('');
    const showPassword = ref(false);
    const isPasswordMatch = ref(false);

    const canSave = computed(() => {
        return formInputs.name && formInputs.loginPw && 
            isPhoneNumberValid.value &&             
            verificationStatus.value === '인증 완료' &&             
            isPasswordValid.value && 
            isPasswordMatch.value;
    });

    const submitForm = async () => {        
      if (!formInputs.name || !formInputs.phonenumber || !formInputs.emailaddress || !formInputs.loginid || !formInputs.loginPw) {
        alert("Please fill in All data");
        return;
      }

      isProcessing.value = true;
      saveStatus.value = 'Processing...';            
      
      const formData = {
        id: formInputs.id, // Ensure id is included
        phonenumber: formInputs.phonenumber,
        loginpw: formInputs.loginPw,
        department: formInputs.department,
        position: formInputs.position,
      };

      try {
        await axios.put(`/api/changeMembers`, formData);
        saveStatus.value = 'Completed'; 
      } catch (error) {
        console.error('Error during submission:', error);
        saveStatus.value = 'Error';
      }     
      isProcessing.value = false;
      closeModal();
    };

    const fetchSelectedRowData = async () => {
      try {
        const response = await axios.get("/api/materialrequestadd", {
          params: { tableName: dbTableName, id: props.rowId },          
        });
        const rowData = response.data[0];        

        fetchModifyData(formInputs, rowData);
        originalPhoneNumber.value = rowData.phonenumber;
        validatePhoneNumber(); // Add this line to validate phone number after fetching data

      } catch (error) {
        console.error("Error fetching row data:", error.message);
      }
    };
    watch( () => props.rowId, () => { fetchSelectedRowData(); }, { immediate: true } );     

    const validatePhoneNumber = () => {
        const phoneRegex = /^[0-9]*$/;
        if (!phoneRegex.test(formInputs.phonenumber)) {
        errors.phonenumber = '숫자만 입력 가능합니다.';
        isPhoneNumberValid.value = false;
        } else {
        errors.phonenumber = '';
        isPhoneNumberValid.value = true;
        }
    };

    const validatePassword = () => {
        isPasswordValid.value = true;        
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (!passwordRegex.test(formInputs.loginPw)) {
        errors.loginPw = '8자리 이상, 영문/숫자/특수 문자가 최소 1개씩 포함되어야 합니다.';
        isPasswordValid.value = false;
        } else {
        errors.loginPw = '';
        isPasswordValid.value = true;
        }       
    };

    const checkDuplicate = async (field) => {      
        if (field === 'phonenumber' && formInputs.phonenumber === originalPhoneNumber.value) {
          errors.phonenumber = '사용 가능 합니다.';
          isPhoneNumberValid.value = true;
          return;
        }
        if (!formInputs[field]) {
        errors[field] = '값을 입력해주세요.';
        return;
        }        
        try {
        const response = await axios.post('/api/checkDuplicate', { field, value: formInputs[field] });
        if (response.data.exists) {
            formInputs[field] = '';
            errors[field] = '사용중인 값입니다.';
            if (field === 'phonenumber') isPhoneNumberValid.value = false;
            if (field === 'emailaddress') isEmailAddressValid.value = false;            
        } else {
            errors[field] = '사용 가능 합니다.';
            if (field === 'phonenumber') isPhoneNumberValid.value = true;
            if (field === 'emailaddress') isEmailAddressValid.value = true;            
        }
        } catch (error) {
        console.error('Error during duplicate check:', error);
        }
    };

    const sendVerificationEmail = async () => {
        try {
        const response = await axios.post('/api/emailSend', {
            to: formInputs.emailaddress,
            subject: "Verify your email address",          
        });
        
        if (response.data.code) {
            expectedCode.value = response.data.code;
            showVerification.value = true;
            verificationStatus.value = '';
            
            // Reset any existing timeout
            if (verificationTimeout.value) {
            clearTimeout(verificationTimeout.value);
            }
            
            // Set 5 minute timeout
            verificationTimeout.value = setTimeout(() => {
            if (!verificationStatus.value.includes('인증 완료')) {
                verificationStatus.value = '시간 초과';
                expectedCode.value = '';
            }
            }, 5 * 60 * 1000);
        }
        } catch (error) {
        console.error('Error sending verification email:', error);
        }
    };

    const verifyCode = () => {
        if (!expectedCode.value) {
            verificationStatus.value = '시간 초과';
            return;
        }
        if (form.verifyCode === expectedCode.value) {
            verificationStatus.value = '인증 완료';
            clearTimeout(verificationTimeout.value);
        } else {
            verificationStatus.value = '코드 재확인';
        }
    };

    const togglePasswordVisibility = () => {
        showPassword.value = !showPassword.value;
    };

    const checkPasswordMatch = () => {
        if (formInputs.loginPw !== form.verifyPw) {
        errors.verifyPw = '암호가 일치하지 않습니다.';
        isPasswordMatch.value = false;        
        } else {
        errors.verifyPw = '사용 가능 합니다.';
        isPasswordMatch.value = true;        
        }
    };

    const closeModal = () => { emit("close"); };  

    return { 
        dataForm,
        form,
        formInputs,
        submitForm,
        closeModal, 
        saveStatus, 
        isProcessing, 
        isPhoneNumberValid,
        checkDuplicate, 
        errors, 
        validatePhoneNumber,                 
        validatePassword,
        isEmailAddressValid,
        showVerification,
        verificationStatus,
        sendVerificationEmail,
        verifyCode,        
        canSave,
        isLoginIdValid,
        isPasswordValid,
        togglePasswordVisibility,
        checkPasswordMatch,
        showPassword,
        isPasswordMatch
    };
    }
  };
</script>
  
<style scoped>
  @import './joinModalStyle.css';
</style>
