<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Cash Out Upload</h3>           
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">          
          <form @submit.prevent="submitForm">
            <table>
              <tr>
                <td>                          
                <table class="itemTable">
                  <tbody>
                  <tr style="border: 1px solid rgb(156, 156, 156);">
                    <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Date</td>
                    <td style="text-align: left;">
                      <input class="dateInput" type="text" v-model="formInputs.year" placeholder="yyyy" />
                      <input class="dateInput" type="text" v-model="formInputs.month" placeholder="mm" />
                      <input class="dateInput" type="text" v-model="formInputs.day" placeholder="dd" />                  
                    </td>        
                  </tr>            
                  <tr style="border: 1px solid rgb(156, 156, 156);">
                    <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Invest ID</td>                  
                    <td style="text-align: left;">
                      <input class="classInput" type="text" v-model="formInputs.investid" />
                    </td> 
                  </tr>    
                  </tbody>
                </table><br>

                <table class="itemTable">
                  <tbody>                 
                  <tr style="border: 1px solid rgb(156, 156, 156);">                
                    <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Item</td>   
                    <td style="text-align: left;"><input class="readWindow" type="text" v-model="matchInputs.item" readonly /></td>
                  </tr>   
                  <tr style="border: 1px solid rgb(156, 156, 156);">                
                    <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Sub Item</td>   
                    <td style="text-align: left;"><input class="readWindow" type="text" v-model="matchInputs.subitem" readonly /></td>
                  </tr>   
                  <tr style="border: 1px solid rgb(156, 156, 156);">                
                    <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Company</td>                   
                    <td style="text-align: left;"><input class="readWindow" type="text" v-model="matchInputs.company" readonly /></td>
                  </tr>                       
                  </tbody>
                </table><br>                 
                
                <table class="itemTable">
                  <tbody>               
                  <tr style="border: 1px solid rgb(156, 156, 156);">
                    <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Remark</td>
                    <td style="text-align: left;"> <textarea id="remark" v-model="formInputs.remark" rows="4" cols="40"></textarea></td>
                  </tr>              
                  </tbody>
                </table><br>  
              </td>       

                <td style="vertical-align: top;">         
                <table style="margin-left:10px" class="itemTable">
                  <tbody>
                  <tr style="border: 1px solid rgb(156, 156, 156);">                
                    <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Cash Out Amount</td>   
                    <td style="text-align: left;"><input class="classInput" type="text" v-model="formInputs.cash" /></td>
                  </tr>                  
                  <tr style="border: 1px solid rgb(156, 156, 156);">                
                    <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Cash Unit</td>   
                    <td style="text-align: left;"><input class="classInput" type="text" v-model="formInputs.cashunit" /></td>
                  </tr>                  
                  </tbody>
                </table><br>  

                <table style="margin-left:10px" class="itemTable">
                  <tbody>
                    <tr style="border: 1px solid rgb(156, 156, 156);">                
                      <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Upload Excel</td>   
                      <td style="text-align: left;">
                        <label for="file-input" class="fileUploadLabel">Choose file</label>
                        <input id="file-input" type="file" @change="fileChange" accept=".xlsx, .xls" style="display: none" />
                        <button class="fileUploadButton" @click="upExcel('investcash')">Upload</button><br>
                        {{ fileName }}
                      </td>
                    </tr>
                  </tbody>
                </table><br>

                <table style="margin-left:10px" class="itemTable">
                  <tbody>
                    <tr style="border: 1px solid rgb(156, 156, 156);">                
                      <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Down Load<br>Excel Form</td>   
                      <td style="text-align: left;">                        
                        <button class="fileUploadButton" @click="downloadExcelTemplate">Down Load</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>    
             </tr>
          </table>        
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}            
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>    
  import { reactive, watch, ref, onMounted  } from 'vue';  
  import axios from 'axios';    
  import { getColumnMapping, makeFormData, useFormInputs, uploadExcel, onFileChange, } from "../utils.js";   
  import * as XLSX from "xlsx";
   
  export default {          
    data() { return { excelFile: null, fileName: "", }; }, 
    methods: {      
      fileChange(event) { const { file, fileName } = onFileChange(event); this.excelFile = file; this.fileName = fileName; },
      upExcel(tableName) { uploadExcel(tableName, this.excelFile, alert); },
      downloadExcelTemplate: async function () {
        const fileName = "investCashOut_template.xlsx";
        const sheetNames = ["Template"];
        const columns = [["investid", "year", "month", "day", "cash", "cashunit", "remark"]];

        let isDownloadEnabled = false;

        try {
          const response = await axios.get('/api/targetList', { params: { tableName: 'setting' } });
          const settingData = response.data;
          const exceldownloadSetting = settingData.find(s => s.exceldownload === 1);
          isDownloadEnabled = !!exceldownloadSetting;
        } catch (error) {
          console.error("Error fetching setting data:", error.message);
        }

        if (!isDownloadEnabled) {
          alert("Excel download is currently disabled.");
          return;
        }

        let workbook = XLSX.utils.book_new();

        if (Array.isArray(sheetNames) && Array.isArray(columns) && sheetNames.length === columns.length) {
          sheetNames.forEach((sheetName, index) => {
            const emptyData = [columns[index]];
            const worksheet = XLSX.utils.json_to_sheet(emptyData, { skipHeader: true });
            XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
          });
        } else {
          const defaultColumns = columns || [];
          const emptyData = [defaultColumns];
          const worksheet = XLSX.utils.json_to_sheet(emptyData, { skipHeader: true });
          XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        }

        XLSX.writeFile(workbook, fileName);
      }      
    },  
    setup(props, { emit }) {   
        const dbTableName = "investcash"    
        const searchTableName = "invest"    
        const saveStatus = ref(''); 
        const isProcessing = ref(false);   
        const investList = reactive({ data: [], })   
        const matchInputs = reactive({ item: '', subitem: '', company: '', })   

        const { formInputs, dataForm } = useFormInputs();    

        watch(() => formInputs.investid, (newInvestId) => {
          const selectedInvest = investList.data.find(invest => invest.investid === newInvestId);
          if (selectedInvest) {
            matchInputs.item = selectedInvest.item;
            matchInputs.subitem = selectedInvest.subitem;
            matchInputs.company = selectedInvest.company;
          } else {
            matchInputs.item = '';
            matchInputs.subitem = '';
            matchInputs.company = '';
          }
        });

        const submitForm = async () => {       
          isProcessing.value = true; 
          saveStatus.value = 'Processing...';

          const columnMapping = getColumnMapping(undefined, formInputs, ['item', 'subitem', 'company']);  
          const formData = makeFormData(undefined, formInputs, dbTableName, columnMapping); 
        
          try {
            await axios.post('/api/post', formData);
            saveStatus.value = 'Completed'; 
          } catch (error) {
            console.error('Error during submission:', error);
            saveStatus.value = 'Error'; 
          } finally {
            isProcessing.value = false; 
          }            
              
         closeModal();
        };
        const closeModal = () => { emit("onClose"); }; 
        
        const searchData = async () => {
          const tableName = searchTableName
          const formData = { tableName };
          try {
            const res = await axios.get('/api/targetList', { params: formData });
            investList.data = res.data;          
          } catch (error) {
            console.error("Error fetching target data:", error.message);
          }
        };

        onMounted(async () => {
        try {
          await searchData(); // 기존 데이터 검색         
        } catch (error) {
          console.error("Error during data fetching in onMounted:", error.message);
        }
      });
      return { dbTableName, formInputs, submitForm, closeModal, saveStatus, isProcessing, dataForm, matchInputs
              
      };

    },     
    props: { rowId: { type: Number, required: true, }, },
};  
</script>
    
<style scoped>
  /* Reset */
  * { margin: 0; padding: 0; box-sizing: border-box; }

  /* Modal styles */
  .modal-mask {
    position: fixed; z-index: 9998;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
    display: flex; justify-content: flex-start; align-items: flex-start; 
  }     
  .modal-wrapper { width: 100%;  max-width: 700px; }  
  .modal-container {
    background-color: #ffffff;
    border-radius: 12px; padding: 20px;
    max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
  }  
  .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  .modal-header h3 { font-size: 1.2rem; }
  .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  
  label { font-weight: bold; }
  input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

  button[type="submit"] {
    background-color: #4CAF50; color: white;
    padding: 5px 20px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 16px;
    display: inline-block; transition-duration: 0.4s;
  }

  button[type="submit"]:hover { background-color: #45a049; }

  .fileUploadButton {
    background-color: #c6c6c6; color: rgb(0, 0, 0);
    padding: 5px 5px; margin-top: 5px; 
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 12px; font-weight: bold;
    display: inline-block; transition-duration: 0.4s;    
  }
  .fileUploadButton:hover { background-color: #5f5f5f; }  
  .itemInput { margin-left: 5px; margin-right: 5px; width: 90%; } 
  .readWindow[readonly] { background-color: #f0f0f0;  margin-left: 5px; margin-right: 5px; width: 200px; }
  .itemTable tr td {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
  .itemTable th {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
  .classInput { margin-left: 5px; margin-right: 5px; width: 200px; } 
  .factoryInput { margin-left: 5px; margin-right: 5px; width: 70px; } 
  .locationInput { margin-left: 5px; margin-right: 5px; width: 350px; }       
  .dateInput { width: 59px; size: 8px; margin-left: 10px; }  
  .timeInput { width: 80px; size: 8px; margin-left: 10px; }      
  .marginLeft {margin-left: 20px;}
  .fileSign {padding: 0px 30px; vertical-align: top;}
  .uploadTable tr td {text-align: left; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
  .uploadTable th {text-align: left; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
  .uploadTd { background-color: #eaeaea; text-align: center !important}

  .fileUploadLabel { background-color: #f5b560; color: rgb(0, 0, 0);
    padding: 5px 20px; margin: 0px 10px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 12px;
    display: inline-block; transition-duration: 0.4s;}
 
  .disabled { background-color: #ccc; cursor: not-allowed; }
  .addLineButton {margin-left: 10px; margin-right: 10px; padding: 0px 10px; border: 0px; background-color: rgb(77, 69, 195); color:white; font-weight: bold; font-size: 15px; text-align: center }
  .failurestandard {margin-left:10px; font-weight: bold};    
</style>