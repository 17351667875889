<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add Line Data</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table class="itemTable">
              <tr style="border:0px solid "><td style="vertical-align: top;">
              <tr>
                <td class="titleTd">Line Name:</td>                
                <select class="searchInput" v-model="formInputs.line">
                  <option v-for="line in uniqueLines" :key="line">{{ line }}</option><option></option>        
                </select>     
              </tr>
              <tr>
                <td class="titleTd">Model:</td>
                <td><input class="searchInput" type="text" v-model="formInputs.model" /></td>
              </tr>
              <tr>
                <td class="titleTd">Tack Time Real:</td>
                <td><input class="searchInput" type="text" v-model="formInputs.tacktime_real" /></td>
              </tr>
              <tr>
                <td class="titleTd">Tack Time Design:</td>
                <td><input class="searchInput" type="text" v-model="formInputs.tacktime_design" /></td>
              </tr>
              <tr>
                <td class="titleTd">NG Rate:</td>
                <td><input class="searchInput" type="text" v-model="formInputs.ngrate" /></td>
              </tr>     
              <tr>
                <td class="titleTd">Downtime Rate:</td>
                <td><input class="searchInput" type="text" v-model="formInputs.downtimerate" /></td>
              </tr>                
              <tr>
                <td class="titleTd">Number of Wokrers:</td>
                <td><input class="searchInput" type="text" v-model="formInputs.numberofworkers" /></td>
              </tr>            
              </td>
              <td td style="vertical-align: top;">
              <tr>
                <td class="titleTd">Semi OK Type:</td>
                <td><button class="inputButton" type="button" @click="openTypeModal('Semi OK Type')">INPUT</button><br>                  
                  <pre style="margin: 5px 5px;" v-if="Object.keys(formInputs.ok_semi).length">{{ formInputs.ok_semi }}</pre>
                </td>
              </tr>            
              <tr>
                <td class="titleTd">NG Type:</td>
                <td><button class="inputButton" type="button" @click="openTypeModal('NG Type')">INPUT</button><br>
                  <pre style="margin: 5px 5px;" v-if="Object.keys(formInputs.ng_type).length">{{ formInputs.ng_type }}</pre>                
                </td>
              </tr>            
              <tr>
                <td class="titleTd">Fake NG Type:</td>                  
                <td><button class="inputButton" type="button" @click="openTypeModal('Fake NG Type')">INPUT</button><br>
                  <pre style="margin: 5px 5px;" v-if="Object.keys(formInputs.ng_fake).length">{{ formInputs.ng_fake }}</pre>                                
                </td>
              </tr>            
              <tr>
                <td class="titleTd">Component NG Type:</td>
                <td><button class="inputButton" type="button" @click="openTypeModal('Component NG Type')">INPUT</button><br>
                  <pre style="margin: 5px 5px;" v-if="Object.keys(formInputs.ng_comp).length">{{ formInputs.ng_comp }}</pre>                                
                </td>                  
              </tr>           
              <tr v-if="currentUser && currentUser.auth === 1">
                <td class="titleTd">DB Name:</td>
                <td><input class="searchInput" type="text" v-model="formInputs.dbname" /></td>
              </tr>            
              <tr v-if="currentUser && currentUser.auth === 1">
                <td class="titleTd">Production Report:</td>
                <td><input class="searchInput" type="text" v-model="formInputs.productionreport" /></td>
              </tr>           
              <tr v-if="currentUser && currentUser.auth === 1">
                <td class="titleTd">Failure Report:</td>
                <td><input class="searchInput" type="text" v-model="formInputs.failurereport" /></td>
              </tr>           
              <tr>
                <td class="titleTd">Row No:</td>
                <td><input class="searchInput" type="text" v-model="formInputs.row_no" /></td>
              </tr>  
              </td></tr>              
            </table>
            <button class="submitButton" type="submit" :class="{ 'readOnly': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button> 
            {{ saveStatus }}        
            </form>
         </div>
      </div>      
    </div>
    <type-modal v-if="showTypeModal" :mainCategory="mainCategory" @onSubmit="handleTypeSubmit" @onClose="closeTypeModal" />
  </div>
</template>
  
<script>    
import { ref, onMounted, reactive } from 'vue';  
import axios from 'axios';   
import TypeModal from './typeModal.vue';
import { useRoute } from 'vue-router';
import { getColumnMapping, makeFormData, fetchData } from '../../utils.js';
  
export default {     
  name: 'LineDataModal',
  components: { TypeModal },  
  setup(props, { emit }) {       
      const dbTableName = "linedata"      
      const saveStatus = ref(''); 
      const isProcessing = ref(false);   
      const currentUser = ref(null);
      const route = useRoute();
      const uniqueLines = ref([]);
      const showTypeModal = ref(false);
      const mainCategory = ref('');
      const formInputs = reactive({ 
        line: null,
        model: null,
        tacktime_real: null,
        tacktime_design: null,
        ngrate: null,
        downtimerate: null,
        numberofworkers: null,
        ok_semi: {},
        ng_type: {},
        ng_fake: {},
        ng_comp: {},
        dbname: null,
        productionreport: null,
        failurereport: null,
        row_no: null,        
      });

      // INPUT 버튼 클릭 시 typeModal 모달 열기
      const openTypeModal = (categoryText) => { mainCategory.value = categoryText; showTypeModal.value = true; };

      // typeModal의 Save 버튼 클릭 후 데이터 수신
      const handleTypeSubmit = (jsonData) => {
        if (mainCategory.value === 'Semi OK Type') {
          formInputs.ok_semi = jsonData;
        } else if (mainCategory.value === 'NG Type') {
          formInputs.ng_type = jsonData;
        } else if (mainCategory.value === 'Fake NG Type') {
          formInputs.ng_fake = jsonData;
        } else if (mainCategory.value === 'Component NG Type') {
          formInputs.ng_comp = jsonData;
        }
        showTypeModal.value = false;
      };
      const closeTypeModal = () => { showTypeModal.value = false; };

      onMounted(() => { currentUser.value = route.meta.currentUser; });   
      
      
      const submitForm = async () => {
        
        isProcessing.value = true; 
        saveStatus.value = 'Processing...';                         

        const columnMapping = getColumnMapping(undefined, formInputs);            
        const formData = makeFormData(undefined, formInputs, dbTableName, columnMapping); 
            
        try {
          await axios.post('/api/post', formData);
          saveStatus.value = 'Completed'; 
        } catch (error) {
          console.error('Error during submission:', error);
          saveStatus.value = 'Error';
        } finally {
          isProcessing.value = false; 
        }     
        closeModal();              
      };     

      const closeModal = () => { emit("onClose");};

      const loadLines = async () => {
        uniqueLines.value = await fetchData('machinedata', 'line');
        uniqueLines.value.sort((a, b) => a.localeCompare(b));
      }; loadLines();  

    return { dbTableName, formInputs, submitForm, closeModal, currentUser, uniqueLines,
             openTypeModal, handleTypeSubmit, closeTypeModal, saveStatus, isProcessing, showTypeModal, mainCategory
     };

  },       
};
  
</script>
    
<style scoped>
@import '../../generalStyle.css';
.modal-wrapper {
  max-width: 800px;
}
</style>