<template>  
  <div>
    <table class="searchTable" style="width: 400px">
      <tr>
        <td>
        <button class="addButton" @click="showModal = true">+ Add</button>   
        </td>
      <td>
        <label for="file-input" class="custom-file-upload"><b>Choose file</b></label>                
        <input id="file-input" type="file" @change="fileChange" accept=".xlsx, .xls" style="display: none" />
      </td>      
      <td>     
        <button class="excelUpload" @click="upExcel('selectoption')">Upload</button>
      </td>
      <td>
        <button class="excelDownload" @click="downExcel">Down Load</button>
      </td>
      </tr>
      <tr>
        <td>{{ fileName }}</td>
      </tr>
    </table>
  </div>  
  <div class="tableWrapper">
    <table class="contentsTable" style="width: 95%;">     
      <thead class="contentsTableTh">  
      <tr>
        <td rowspan="2">Line Name</td><td rowspan="2">Model</td><td colspan="2">Tack Time</td><td rowspan="2">NG Rate</td><td rowspan="2">Downtime Rate</td><td rowspan="2">Number of Workers</td>
        <td rowspan="2">OK Type</td><td rowspan="2">NG Type</td><td rowspan="2">Fake NG Type</td><td rowspan="2">Comp NG Type</td>
        <td v-if="currentUser && currentUser.auth === 1" rowspan="2">DB Name</td>
        <td v-if="currentUser && currentUser.auth === 1" rowspan="2">Production Report</td>
        <td v-if="currentUser && currentUser.auth === 1" rowspan="2">Failure Report</td>
        <td rowspan="2">ROW NO</td>
        <td rowspan="2">DEL</td>      
      </tr>
      <tr>
        <td>Real</td><td>Design</td>
      </tr>
    </thead>            
    <tbody>
      <tr class="contentsTableTr" v-for="row in item" :key="row.id">          
        <td>{{ row.line }}</td>
        <td @click="editCell(dbTableName, row.id, 'position', $event)">{{ row.model }}</td>
        <td @click="editCell(dbTableName, row.id, 'tacktime_real', $event)">{{ row.tacktime_real }}</td>
        <td @click="editCell(dbTableName, row.id, 'tacktime_design', $event)">{{ row.tacktime_design }}</td>
        <td @click="editCell(dbTableName, row.id, 'ngrate', $event)">{{ row.ngrate}}</td>
        <td @click="editCell(dbTableName, row.id, 'downtimerate', $event)">{{ row.downtimerate }}</td>
        <td @click="editCell(dbTableName, row.id, 'numberofworkers', $event)">{{ row.numberofworkers }}</td>
        <td>
          <button 
            :class="['modifyButton', { darkSkyBlue: isEmptyJson(row.ok_semi) }]"             
            @click="openModifyModal(row, 'ok_semi', row.id)"
          >
            Modify
          </button>
        </td>
        <td>
          <button 
            :class="['modifyButton', { darkSkyBlue: isEmptyJson(row.ng_type) }]" 
            :disabled="isEmptyJson(row.ng_type)" 
            @click="!isEmptyJson(row.ng_type) && openModifyModal(row, 'ng_type', row.id)"
          >
            Modify
          </button>          
        </td>
        <td>
          <button 
            :class="['modifyButton', { darkSkyBlue: isEmptyJson(row.ng_fake) }]"             
            @click="openModifyModal(row, 'ng_fake', row.id)"
          >
            Modify
          </button>         
        </td>
        <td>
          <button 
            :class="['modifyButton', { darkSkyBlue: isEmptyJson(row.ng_comp) }]"             
            @click="openModifyModal(row, 'ng_comp', row.id)"
          >
            Modify
          </button>          
        </td>
        <td v-if="currentUser && currentUser.auth === 1" @click="editCell(dbTableName, row.id, 'dbname', $event)">{{ row.dbname }}</td>
        <td v-if="currentUser && currentUser.auth === 1" @click="editCell(dbTableName, row.id, 'productionreport', $event)">{{ row.productionreport }}</td>
        <td v-if="currentUser && currentUser.auth === 1" @click="editCell(dbTableName, row.id, 'failurereport', $event)">{{ row.failurereport }}</td>
        <td @click="editCell(dbTableName, row.id, 'row_no', $event)">{{ row.row_no }}</td>        
        <td><button :class="['delButton', { disabledButton: !canDelClick() }]" :disabled="!canDelClick()" type="submit" @click="delRow(dbTableName, row.id)">DEL</button></td>
    </tr>
  </tbody>     
  </table>     
  </div>    
  <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/>     
  <modify-modal 
    v-if="showModifyModal && modifyModalType === 'single'" 
    :mainCategoryName="selectedProperty" 
    :initialData="selectedData" 
    :rowId="selectedRowId" 
    @onSubmit="handleModifySubmit" 
    @onClose="closeModifyModal"        
  />
  <modify-modal2 
    v-if="showModifyModal && modifyModalType === 'sub'" 
    :mainCategoryName="selectedProperty" 
    :initialData="selectedData" 
    :rowId="selectedRowId" 
    @onSubmit="handleModifySubmit" 
    @onClose="closeModifyModal"     
  />
</template>

<script>
import { reactive, computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import Modal from "./lineDataModal.vue";
import ModifyModal from "./modifyModal.vue"; // Import 수정 모달
import ModifyModal2 from './modifyModal2.vue';
import axios from "axios";
import { onFileChange, uploadExcel, deleteRow, downloadExcel, editCellValue, } from "../../utils.js"; 

export default {         
  components: { Modal, ModifyModal, ModifyModal2},    
  data() { return { excelFile: null, fileName: "",
    showModifyModal: false,
    selectedRow: null,
    selectedRowId: null, 
    selectedProperty: "",
    selectedData: {},    
    modifyModalType: "single"
    };},
  methods: {    
    fileChange(event) { const { file, fileName } = onFileChange(event); this.excelFile = file; this.fileName = fileName; },
    upExcel(tableName) { uploadExcel(tableName, this.excelFile, alert); },  
    downExcel() { downloadExcel(this.item, "lineData.xlsx"); },
    delRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData);},
    editCell(tableName, id, header, event) {
      const currentValue = event.target.innerText;
      const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);
      if (newValue !== null && newValue !== currentValue) {
          editCellValue(tableName, id, header, newValue, this.callSearchData);
      }
    },
    canDelClick() {
      const currentUser = this.$route.meta.currentUser;        
      if (currentUser) {
          return currentUser.auth === 1 || currentUser.auth === 2 || currentUser.auth === 3  ;
      } else { return false;}
    },   
    openModifyModal(row, property, rowId) {    
      this.selectedRow = row;
      this.selectedProperty = property; 
      this.selectedRowId = rowId; 
     
      let data = row[property];
      if (typeof data === "string") {
        try {
          data = JSON.parse(data);
        } catch (e) {
          console.error("JSON 파싱 에러:", e);
          data = {};
        }
      }
    
      const cloned = JSON.parse(JSON.stringify(data));     
      const topKeys = Object.keys(cloned || {});      
   
      this.modifyModalType = "single";
      this.selectedData = cloned; 
    
      if (
        cloned &&
        typeof cloned === "object" &&
        Object.prototype.hasOwnProperty.call(cloned, "material") &&
        Array.isArray(cloned.material)
      ) {       
        this.modifyModalType = "single";
        this.selectedData = cloned; 
      }    
      else if (topKeys.length > 1) {      
        const allHaveMaterial = topKeys.every(k => {
          const entry = cloned[k];
          return (
            entry &&
            typeof entry === "object" &&
            Array.isArray(entry.material)
          );
        });
        if (allHaveMaterial) {       
          this.modifyModalType = "single";
          this.selectedData = cloned; 
        } else {      
          this.modifyModalType = "sub";
          this.selectedData = cloned;
        }
      }   
      else if (topKeys.length === 1) {
        const onlyKey = topKeys[0];
        const onlyVal = cloned[onlyKey];    
        if (
          onlyVal &&
          typeof onlyVal === "object" &&
          Array.isArray(onlyVal.material)
        ) {
          this.modifyModalType = "single";      
          this.selectedData = onlyVal;
        } else {         
          this.modifyModalType = "sub";        
          this.selectedData = onlyVal;
        }
      }    
      else {      
        this.modifyModalType = "single";
        this.selectedData = cloned;
      }
      this.showModifyModal = true;
    },

    handleModifySubmit(modifiedData) {     
      const newValue = modifiedData[this.selectedProperty];
      this.selectedRow[this.selectedProperty] = newValue;
      this.showModifyModal = false;   
      this.callSearchData();
    },
    closeModifyModal() {
      this.showModifyModal = false;
      this.callSearchData();
    },
    isEmptyJson(value) {   
      if (!value) return true;
      let parsed;
      try {      
        parsed = typeof value === "string" ? JSON.parse(value) : value;
      } catch (e) {     
        return true;
      }    
      return Object.keys(parsed).length === 0;
    }
    },
  setup() {   
    const dbTableName = "linedata";                    
    const targetList = reactive({ data: [], })
    const showModal = ref(false); 
    const currentUser = ref(null);
    const route = useRoute();

    onMounted(() => {
      currentUser.value = route.meta.currentUser;
      searchData();
    });   

    const searchData = async () => {         
      const formData = {tableName: dbTableName, };
      axios.get('/api/targetList', { params: formData })
        .then((res) => {              
          targetList.data = res.data.sort((a, b) => a.row_no - b.row_no); 
        })
        .catch((error) => {
          console.error("Error fetching target data:", error.message);
        });
      };

    const item = computed(() => { return targetList.data.map(d => { return { ...d, };});});     
    const callSearchData = () => { searchData(dbTableName); };   

    return { item, targetList, dbTableName, showModal, callSearchData, currentUser,        
      };        
  }
}
</script>

<style scoped>
@import '../../generalStyle.css';
</style>