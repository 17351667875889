<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <table>
            <tr>
              <td><h3>Add Production Result</h3></td>
              <td><button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button></td>              
              <td>{{ saveStatus }} </td>           
            </tr>
          </table>  
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -시작- -->
            <table>
              <tr>
                <td>
            <div class="form-group date-time-group">
              <label>Year/Month/Day:</label>
              <div class="date-time-inputs">
                <input type="text" id="year" v-model="searchDate.year"  />
                <input type="text" id="month" v-model="searchDate.month" />
                <input type="text" id="day" v-model="searchDate.day" />                  
              </div> 
            </div>                                     
            <div class="form-group">
              <label for="shift">Shift:</label>
              <input type="number" id="shift" v-model="formInputs.shift" readonly class="readWindow" />
            </div>            
            <div class="form-group">
              <label for="start_time">Start Time:</label>              
              <select id="start_time" v-model="formInputs.start_time">            
                <option>01</option><option>02</option><option>03</option><option>04</option>
                <option>05</option><option>06</option><option>07</option><option>08</option>
                <option>09</option><option>10</option><option>11</option><option>12</option>
                <option>13</option><option>14</option><option>15</option><option>16</option>
                <option>17</option><option>18</option><option>19</option><option>20</option>
                <option>21</option><option>22</option><option>23</option><option>24</option>
              </select>      
            </div>
            <div class="form-group">
              <label for="end_time">End Time:</label>
              <select id="end_time" v-model="formInputs.end_time" readonly class="readWindow">            
                <option>01</option><option>02</option><option>03</option><option>04</option>
                <option>05</option><option>06</option><option>07</option><option>08</option>
                <option>09</option><option>10</option><option>11</option><option>12</option>
                <option>13</option><option>14</option><option>15</option><option>16</option>
                <option>17</option><option>18</option><option>19</option><option>20</option>
                <option>21</option><option>22</option><option>23</option><option>24</option>
              </select>
            </div>
            <div class="form-group">
              <label for="work_minute">Work Time(minute):</label>
              <select id="work_minute" v-model="formInputs.work_minute">            
                <option>5</option><option>15</option><option>30</option><option>45</option><option>60</option>    
              </select>                            
            </div>
          </td>
          <td>
            <div class="form-group">
              <label for="model">Model:</label>
              <select id="model" v-model="formInputs.model">            
                <option>ICB</option><option>ICB_AJ</option>
              </select>              
            </div>
            <div class="form-group">
              <label for="target">Target:</label>
              <input type="number" id="target" v-model="formInputs.target" readonly class="readWindow" />
            </div>   
            <div class="form-group">
              <label for="ok">OK:</label>
              <input type="number" id="ok" v-model="formInputs.ok" />
            </div>   
          </td>
          <td>
            <div class="form-group">
              <label>NG:</label>
              <input class="readWindow" readonly type="number" id="ng" v-model="formInputs.ng" />               
            </div>
            <div class="form-group">
              <label for="ng_busbarassembly">NG Busbar assembly :</label>
              <input type="number" id="ng_busbarassembly" v-model="formInputs.ng_busbarassembly" />
            </div>   
            <div class="form-group">
              <label for="ng_pcs">NG PCS:</label>
              <input type="number" id="ng_pcs" v-model="formInputs.ng_pcs" />
            </div>
            <div class="form-group">
              <label for="ng_leaktest">NG Leak Test:</label>
              <input type="number" id="ng_leaktest" v-model="formInputs.ng_leaktest"  />
            </div>
            <div class="form-group">
              <label for="ng_harnessassembly">NG Harness assembly:</label>
              <input type="number" id="ng_harnessassembly" v-model="formInputs.ng_harnessassembly" />
            </div>   
            <div class="form-group">
              <label for="ng_eol">NG EOL:</label>
              <input type="number" id="ng_eol" v-model="formInputs.ng_eol" />
            </div> 
            <div class="form-group">
              <label for="ng_final">NG Final:</label>
              <input type="number" id="ng_final" v-model="formInputs.ng_final" />
            </div>
            <div class="form-group">
              <label for="ng_final">NG OQC:</label>
              <input type="number" id="ng_final" v-model="formInputs.ng_oqc" />
            </div>              
          </td>
          <td>
            <div class="form-group ng-dropdown-container">
              <label>Fake NG</label>
              <button type="button" class="ng-dropdown-button" @click="toggleDropdown('fakeNg')">▼</button>
              <div v-if="dropdownsVisible.fakeNg" class="ng-dropdown-menu">
                <div class="form-group">
                  <label>Fake Busbar Assy:</label>
                  <input type="number" v-model="formInputs.fn_busbarassy" />
                </div>
                <div class="form-group">
                  <label>Fake Leak Test:</label>
                  <input type="number" v-model="formInputs.fn_leaktest" />
                </div>
                <div class="form-group">
                  <label>Fake EOL:</label>
                  <input type="number" v-model="formInputs.fn_eol" />
                </div>
              </div>
            </div>
            <div class="form-group ng-dropdown-container">
              <label>Component NG</label>
              <button type="button" class="ng-dropdown-button" @click="toggleDropdown('compNg')">▼</button>
              <div v-if="dropdownsVisible.compNg" class="ng-dropdown-menu">
                <div>
                  <label>Copm. Nuts:</label>
                  <input type="number" v-model="formInputs.semi_nuts" />
                </div>
                <div>
                  <label>Copm. Pos:</label>
                  <input type="number" v-model="formInputs.semi_pos" />
                </div>
                <div>
                  <label>Copm. Neg:</label>
                  <input type="number" v-model="formInputs.semi_neg" />
                </div>
                <div>
                  <label>Copm. Housing:</label>
                  <input type="number" v-model="formInputs.semi_housing" />
                </div>                            
                <div class="form-group">
                  <label>Copm. PCS:</label>
                  <input type="number" v-model="formInputs.semi_pcs" />
                </div>
                <div class="form-group">
                  <label>Copm. Harness:</label>
                  <input type="number" v-model="formInputs.semi_harness" />
                </div>
                <div class="form-group">
                  <label>Copm. Yellow Label:</label>
                  <input type="number" v-model="formInputs.semi_yellowlabel" />
                </div>
                <div class="form-group">
                  <label>Copm. White Label:</label>
                  <input type="number" v-model="formInputs.semi_whitelabel" />
                </div>
              </div>
            </div>
          </td>
          <td>
            <div class="form-group">
              <label for="remark">Remark:</label>
              <input type="text" id="remark" v-model="formInputs.remark"  />
            </div>
            <div class="form-group">
              <label for="workorder">Work Order:</label>
              <input type="text" id="workorder" v-model="formInputs.workorder"  />
            </div>
            <div class="form-group">
              <label for="supervisor">Supervisor:</label>
              <input type="text" id="supervisor" v-model="formInputs.supervisor" />
            </div>
            <div class="form-group">
              <label for="leader">Leader:</label>
              <input type="text" id="leader" v-model="formInputs.leader" />
            </div>
            <div class="form-group">
              <label for="technician">Technician:</label>
              <input type="text" id="technician" v-model="formInputs.technician" />
            </div>
            <div class="form-group">
              <label for="shift_name">Shift Name:</label>
              <input type="text" id="shift_name" v-model="formInputs.shift_name" />
            </div>
            <div class="form-group">
              <label for="operator_qty">Operator Quantity:</label>
              <input type="number" id="operator_qty" v-model="formInputs.operator_qty" />
            </div>
            <div class="form-group">
              <label for="row_no">Row_no:</label>
              <input type="number" id="row_no" v-model="formInputs.row_no" readonly class="readWindow" />
            </div>     
          </td>                
        </tr>
        </table>           
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -끝- -->
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}              
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>

<script>  
import { useForm } from '../0_formStyle/modal/form_pr_modal';

export default {
  props: { onSubmit: { type: Function, required: true }, lineName: { type: String, required: true } },
  setup(props, { emit }) {
    return useForm(props, emit);
  }
};
</script>
    
<style scoped src="../0_formStyle/modal/style_pr_modal.css"></style>