import axios from 'axios';
import { reactive, ref } from 'vue';

export function setupLoginForm() {
  const loading = ref(false);
  const showJoinModal = ref(false);
  const state = reactive({
    form: {
      loginId: '',
      loginPw: ''
    },
    error: null
  });

  const submit = async () => {
    try {
      loading.value = true;
      const response = await axios.post('/api/accountPost', {
        loginId: state.form.loginId,
        loginPw: state.form.loginPw
      }, { withCredentials: true });
      
      if (response.data) {
        localStorage.setItem("account", JSON.stringify(response.data));
        await axios.post('/api/updateLastLogin', { loginId: state.form.loginId });
        window.location.reload();
        showJoinModal.value = false; // Close the modal
      }
    } catch (error) {
      state.error = error.response?.data?.message || 'Login failed';
      alert(state.error);
    } finally {
      loading.value = false;
    }
  };

  const openJoinModal = () => {
    showJoinModal.value = true;
  };

  return { state, submit, showJoinModal, openJoinModal };
}
