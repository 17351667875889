<template>
    <div class="bmw12v">            
      <div class="act">               
         <h2 class="pagetitle">BMW 12V</h2>         
      </div>    
      <form @submit.prevent="sendDate()" class="searchBar">     
        <select id="year" v-model="searchDate.year">   
            <option disabled selected value="" style="color: gray;">Year</option>           
            <option>2021</option><option>2022</option><option>2023</option><option>2024</option>
            <option>2025</option><option>2026</option><option>2027</option><option>2028</option>
            <option>2029</option><option>2030</option><option>2031</option><option>2032</option>
            <option>2033</option><option>2034</option><option>2035</option><option>2036</option>
            <option>2037</option><option>2038</option><option>2039</option><option>2040</option>
        </select>           
        <select id="month" v-model="searchDate.month">            
            <option disabled selected value="" style="color: gray;">Month</option>
            <option>1</option><option>2</option><option>3</option><option>4</option>
            <option>5</option><option>6</option><option>7</option><option>8</option>
            <option>9</option><option>10</option><option>11</option><option>12</option>            
        </select>           
        <select id="day" v-model="searchDate.day">            
            <option disabled selected value="" style="color: gray;">Day</option>
            <option>1</option><option>2</option><option>3</option><option>4</option>
            <option>5</option><option>6</option><option>7</option><option>8</option>
            <option>9</option><option>10</option><option>11</option><option>12</option> 
            <option>13</option><option>14</option><option>15</option><option>16</option>   
            <option>17</option><option>18</option><option>19</option><option>20</option>   
            <option>21</option><option>22</option><option>23</option><option>24</option>
            <option>25</option><option>26</option><option>27</option><option>28</option>
            <option>29</option><option>30</option><option>31</option>                    
        </select>
        <button class="searchButton" type="submit"  @click="callSearchData">Search</button>  
        <button class="excelButton" @click="downExcel">Excel</button>   
        <button class="button" @click="showModal = true">+ Result</button>
        <button class="button" @click="showdtModal = true">+ Downtime</button>
        <button class="button" @click="showdrModal = true">+ Daily Report</button>
        <button class="qwallButton" @click="showqwModal = true">+ Q-wall</button>      
        <button class="button" @click="showrdModal = true">+ Repeat Data</button>  
      </form> 
      <br>   
      <div class="tables">
      <table id="productionResult" class="custom-table">
        <tr>          
          <th v-for="(header, index) in headers" :key="index">{{ header }}</th>          
        </tr>          
          <tr v-for="row in item" :key="row.id">
            <template v-for="(header, index) in headers">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="header === 'Shift'" :key="index" @click="editCell(dbTableName, row.id, 'shift', $event)" :class="{'shift1': row.shift === 1, 'shift2': row.shift === 2}">{{ row.shift }}</td>
                <td v-if="header === 'Time'" :key="index" class="sticky-time">{{ row.start_time }}~{{ row.end_time }}</td>
                <td v-if="header === 'Model'" :key="index" :class="{ 'model-F4012V': row.model === 'F40 12V', 'model-HAF': row.model === 'HAF'}" @click="editCell(dbTableName, row.id, 'model', $event)">{{ row.model }}</td>
                <td v-if="header === 'Target'" :key="index" @click="editCell(dbTableName, row.id, 'target', $event)">{{ row.target }}</td>
                <td v-if="header === 'Input'" :key="index" >{{ row.input }}</td>
                <td v-if="header === 'OK'" :key="index" class="ok" @click="editCell(dbTableName, row.id, 'ok', $event)">{{ row.ok }}</td>
                <td v-if="header === 'NG'" :key="index" class="ng" :class="{ 'zero-value_ng': row.ng === 0 }" @click="editCell(dbTableName, row.id, 'ng', $event)">{{ row.ng }}</td>
                <td v-if="header === 'Lack of Components'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_lackofcomponents === 0 }" @click="editCell(dbTableName, row.id, 'ng_lackofcomponents', $event)">{{ row.ng_lackofcomponents }}</td>
                <td v-if="header === 'Coating too much'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_coatingtoomuch === 0 }" @click="editCell(dbTableName, row.id, 'ng_coatingtoomuch', $event)">{{ row.ng_coatingtoomuch }}</td>
                <td v-if="header === 'Coating too less'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_coatingtooless === 0 }" @click="editCell(dbTableName, row.id, 'ng_coatingtooless', $event)">{{ row.ng_coatingtooless }}</td>                
                <td v-if="header === 'Coating bubbles'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_coatingbubbles === 0 }" @click="editCell(dbTableName, row.id, 'ng_coatingbubbles', $event)">{{ row.ng_coatingbubbles }}</td>
                <td v-if="header === 'Soldering too much'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_solderingtoomuch === 0 }" @click="editCell(dbTableName, row.id, 'ng_solderingtoomuch', $event)">{{ row.ng_solderingtoomuch }}</td>
                <td v-if="header === 'Soldering too less'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_solderingtooless === 0 }" @click="editCell(dbTableName, row.id, 'ng_solderingtooless', $event)">{{ row.ng_solderingtooless }}</td>
                <td v-if="header === 'Soldering balls'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_solderingballs === 0 }" @click="editCell(dbTableName, row.id, 'ng_solderingballs', $event)">{{ row.ng_solderingballs }}</td>
                <td v-if="header === 'Lack of barcode'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_lackofbarcode === 0 }" @click="editCell(dbTableName, row.id, 'ng_lackofbarcode', $event)">{{ row.ng_lackofbarcode }}</td>
                <td v-if="header === 'Damage barcode'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_damagebarcode === 0 }" @click="editCell(dbTableName, row.id, 'ng_damagebarcode', $event)">{{ row.ng_damagebarcode }}</td>
                <td v-if="header === 'Busbar scraches'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_busbarscraches === 0 }" @click="editCell(dbTableName, row.id, 'ng_busbarscraches', $event)">{{ row.ng_busbarscraches }}</td>
                <td v-if="header === 'Dirty busbar'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_dirtybusbar === 0 }" @click="editCell(dbTableName, row.id, 'ng_dirtybusbar', $event)">{{ row.ng_dirtybusbar }}</td>
                <td v-if="header === 'Damage PCB'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_damagepcb === 0 }" @click="editCell(dbTableName, row.id, 'ng_damagepcb', $event)">{{ row.ng_damagepcb }}</td>
                <td v-if="header === 'Dirty housing'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_dirtyhousing === 0 }" @click="editCell(dbTableName, row.id, 'ng_dirtyhousing', $event)">{{ row.ng_dirtyhousing }}</td>
                <td v-if="header === 'Damage housing'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_damagehousing === 0 }" @click="editCell(dbTableName, row.id, 'ng_damagehousing', $event)">{{ row.ng_damagehousing }}</td>
                <td v-if="header === 'EOL'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_eol === 0 }" @click="editCell(dbTableName, row.id, 'ng_eol', $event)">{{ row.ng_eol }}</td>
                <td v-if="header === 'OQC'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_oqc === 0 }" @click="editCell(dbTableName, row.id, 'ng_oqc', $event)">{{ row.ng_oqc }}</td>
                <td v-if="header === 'NG Rate'" :key="index" class="ng" >{{ row.ngRate }}%</td>
                <td v-if="header === 'Fake Sum'" :key="index" class="fn" :class="{ 'zero-value_fn': row.fakeng === 0 }">{{ row.fakeng }}</td> <!--need to make cal-->
                <td v-if="header === 'Fake Soldering'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_soldering === 0 }" @click="editCell(dbTableName, row.id, 'fn_soldering', $event)">{{ row.fn_soldering }}</td>
                <td v-if="header === 'Fake Coating'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_coating === 0 }" @click="editCell(dbTableName, row.id, 'fn_coating', $event)">{{ row.fn_coating }}</td>
                <td v-if="header === 'Fake EOL'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_eol === 0 }" @click="editCell(dbTableName, row.id, 'fn_eol', $event)">{{ row.fn_eol }}</td>
                <td v-if="header === 'Fake Vision'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_vision === 0 }" @click="editCell(dbTableName, row.id, 'fn_vision', $event)">{{ row.fn_vision }}</td>                                
                <td v-if="header === 'Fake NG Rate'" :key="index" class="fn" >{{ row.fn_ngRate }}%</td> <!--need to make cal-->
                <td v-if="header === 'Remark'" :key="index" @click="editCell(dbTableName, row.id, 'remark', $event)">{{ row.remark }}</td> 
                <td v-if="header === 'Work Order'" :key="index" @click="editCell(dbTableName, row.id, 'workorder', $event)">{{ row.workorder }}</td> 
                <td v-if="header === 'Supervisor'" :key="index" @click="editCell(dbTableName, row.id, 'supervisor', $event)">{{ row.supervisor }}</td> 
                <td v-if="header === 'Leader'" :key="index" @click="editCell(dbTableName, row.id, 'leader', $event)">{{ row.leader }}</td> 
                <td v-if="header === 'Technician'" :key="index" @click="editCell(dbTableName, row.id, 'technician', $event)">{{ row.technician }}</td> 
                <td v-if="header === 'Shift Name'" :key="index" @click="editCell(dbTableName, row.id, 'shift_name', $event)">{{ row.shift_name }}</td> 
                <td v-if="header === 'Operator Quantity'" :key="index" @click="editCell(dbTableName, row.id, 'operator_qty', $event)">{{ row.operator_qty }}</td> 
                <td v-if="header === 'Product code'" :key="index" @click="editCell(dbTableName, row.id, 'productcode', $event)">{{ row.productcode }}</td> 
                <td v-if="header === 'Work Time'" :key="index" @click="editCell(dbTableName, row.id, 'work_minute', $event)">{{ row.work_minute }}</td> 
                <td v-if="header === 'row_no'" :key="index" @click="editCell(dbTableName, row.id, 'row_no', $event)">{{ row.row_no }}</td>                
            </template>
        </tr>
        <tr>
         <th rowspan="2">Shift</th>
         <td>1</td>
          <td>Subtotal</td>
          <td>{{ sums.shiftSums[1].target }}</td>
          <td>{{ sums.shiftSums[1].input }}</td>
          <td>{{ sums.shiftSums[1].ok }}</td>
          <td>{{ sums.shiftSums[1].ng }}</td>
          <td>{{ sums.shiftSums[1].ng_lackofcomponents }}</td>
          <td>{{ sums.shiftSums[1].ng_coatingtoomuch }}</td>
          <td>{{ sums.shiftSums[1].ng_coatingtooless }}</td>
          <td>{{ sums.shiftSums[1].ng_coatingbubbles }}</td>
          <td>{{ sums.shiftSums[1].ng_solderingtoomuch }}</td>          
          <td>{{ sums.shiftSums[1].ng_solderingtooless }}</td>          
          <td>{{ sums.shiftSums[1].ng_solderingballs }}</td>
          <td>{{ sums.shiftSums[1].ng_lackofbarcode }}</td>
          <td>{{ sums.shiftSums[1].ng_damagebarcode }}</td>
          <td>{{ sums.shiftSums[1].ng_busbarscraches }}</td>
          <td>{{ sums.shiftSums[1].ng_dirtybusbar }}</td>
          <td>{{ sums.shiftSums[1].ng_damagepcb }}</td>
          <td>{{ sums.shiftSums[1].ng_dirtyhousing }}</td>
          <td>{{ sums.shiftSums[1].ng_damagehousing }}</td>
          <td>{{ sums.shiftSums[1].ng_eol }}</td>
          <td>{{ sums.shiftSums[1].ng_oqc }}</td>       
          <td>{{ ((sums.shiftSums[1].ng) / (sums.shiftSums[1].input) * 100).toFixed(1) }}%</td>
          <td>{{ sums.shiftSums[1].fn_soldering + sums.shiftSums[1].fn_coating + sums.shiftSums[1].fn_vision + sums.shiftSums[1].fn_eol }}</td>
          <td>{{ sums.shiftSums[1].fn_soldering }}</td>
          <td>{{ sums.shiftSums[1].fn_coating }}</td>
          <td>{{ sums.shiftSums[1].fn_eol }}</td>
          <td>{{ sums.shiftSums[1].fn_vision }}</td>                    
          <td>{{ ((sums.shiftSums[1].fn_soldering + sums.shiftSums[1].fn_coating + sums.shiftSums[1].fn_vision + sums.shiftSums[1].fn_eol)/(sums.shiftSums[1].input) * 100).toFixed(1) }}%</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Subtotal</td>
          <td>{{ sums.shiftSums[2].target }}</td>
          <td>{{ sums.shiftSums[2].input }}</td>
          <td>{{ sums.shiftSums[2].ok }}</td>
          <td>{{ sums.shiftSums[2].ng }}</td>
          <td>{{ sums.shiftSums[2].ng_lackofcomponents }}</td>
          <td>{{ sums.shiftSums[2].ng_coatingtoomuch }}</td>
          <td>{{ sums.shiftSums[2].ng_coatingtooless }}</td>
          <td>{{ sums.shiftSums[2].ng_coatingbubbles }}</td>
          <td>{{ sums.shiftSums[2].ng_solderingtoomuch }}</td>          
          <td>{{ sums.shiftSums[2].ng_solderingtooless }}</td>          
          <td>{{ sums.shiftSums[2].ng_solderingballs }}</td>
          <td>{{ sums.shiftSums[2].ng_lackofbarcode }}</td>
          <td>{{ sums.shiftSums[2].ng_damagebarcode }}</td>
          <td>{{ sums.shiftSums[2].ng_busbarscraches }}</td>
          <td>{{ sums.shiftSums[2].ng_dirtybusbar }}</td>
          <td>{{ sums.shiftSums[2].ng_damagepcb }}</td>
          <td>{{ sums.shiftSums[2].ng_dirtyhousing }}</td>
          <td>{{ sums.shiftSums[2].ng_damagehousing }}</td>
          <td>{{ sums.shiftSums[2].ng_eol }}</td>
          <td>{{ sums.shiftSums[2].ng_oqc }}</td>       
          <td>{{ ((sums.shiftSums[2].ng) / (sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>
          <td>{{ sums.shiftSums[2].fn_soldering + sums.shiftSums[2].fn_coating + sums.shiftSums[2].fn_vision + sums.shiftSums[2].fn_eol }}</td>
          <td>{{ sums.shiftSums[2].fn_soldering }}</td>
          <td>{{ sums.shiftSums[2].fn_coating }}</td>
          <td>{{ sums.shiftSums[2].fn_eol }}</td>
          <td>{{ sums.shiftSums[2].fn_vision }}</td>                    
          <td>{{ ((sums.shiftSums[2].fn_soldering + sums.shiftSums[2].fn_coating + sums.shiftSums[2].fn_vision + sums.shiftSums[2].fn_eol)/(sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>
        </tr>
        <tr>
         <th rowspan="2">Model</th>
          <td>F40 12V</td>
          <td>Subtotal</td>
          <td>{{ sums.modelSums['F40 12V'].target }}</td>
          <td>{{ sums.modelSums['F40 12V'].input }}</td>
          <td>{{ sums.modelSums['F40 12V'].ok - sums.qwmodelSums['F40 12V'].ng}}</td>
          <td>{{ sums.modelSums['F40 12V'].ng + sums.qwmodelSums['F40 12V'].ng }}</td>
          <td>{{ [ sums.modelSums['F40 12V'],sums.qwmodelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_lackofcomponents, 0) }}</td> <!-- obj.ng_lackofcomponents 부분을 수정하면 된다.-->          
          <td>{{ [ sums.modelSums['F40 12V'],sums.qwmodelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_coatingtoomuch, 0) }}</td>
          <td>{{ [ sums.modelSums['F40 12V'],sums.qwmodelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_coatingtooless, 0) }}</td>
          <td>{{ [ sums.modelSums['F40 12V'],sums.qwmodelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_coatingbubbles, 0) }}</td>
          <td>{{ [ sums.modelSums['F40 12V'],sums.qwmodelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_solderingtoomuch, 0) }}</td>          
          <td>{{ [ sums.modelSums['F40 12V'],sums.qwmodelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_solderingtooless, 0) }}</td>
          <td>{{ [ sums.modelSums['F40 12V'],sums.qwmodelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_solderingballs, 0) }}</td>
          <td>{{ [ sums.modelSums['F40 12V'],sums.qwmodelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_lackofbarcode, 0) }}</td>
          <td>{{ [ sums.modelSums['F40 12V'],sums.qwmodelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_damagebarcode, 0) }}</td>
          <td>{{ [ sums.modelSums['F40 12V'],sums.qwmodelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_busbarscraches, 0) }}</td>
          <td>{{ [ sums.modelSums['F40 12V'],sums.qwmodelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_dirtybusbar, 0) }}</td>
          <td>{{ [ sums.modelSums['F40 12V'],sums.qwmodelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_damagepcb, 0) }}</td>
          <td>{{ [ sums.modelSums['F40 12V'],sums.qwmodelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_dirtyhousing, 0) }}</td>
          <td>{{ [ sums.modelSums['F40 12V'],sums.qwmodelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_damagehousing, 0) }}</td>
          <td>{{ [ sums.modelSums['F40 12V'],sums.qwmodelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_eol, 0) }}</td>
          <td>{{ [ sums.modelSums['F40 12V'],sums.qwmodelSums['F40 12V']].reduce((sum, obj) => sum + obj.ng_oqc, 0) }}</td>
          <td>{{ ((sums.modelSums['F40 12V'].ng + sums.qwmodelSums['F40 12V'].ng) / (sums.modelSums['F40 12V'].input) * 100).toFixed(1) }}%</td>
          <td>{{ sums.modelSums['F40 12V'].fn_soldering + sums.modelSums['F40 12V'].fn_coating + sums.modelSums['F40 12V'].fn_vision + sums.modelSums['F40 12V'].fn_eol }}</td>
          <td>{{ sums.modelSums['F40 12V'].fn_soldering }}</td>
          <td>{{ sums.modelSums['F40 12V'].fn_coating }}</td>
          <td>{{ sums.modelSums['F40 12V'].fn_eol }}</td>
          <td>{{ sums.modelSums['F40 12V'].fn_vision }}</td>                    
          <td>{{ ((sums.modelSums['F40 12V'].fn_soldering + sums.modelSums['F40 12V'].fn_coating + sums.modelSums['F40 12V'].fn_vision + sums.modelSums['F40 12V'].fn_eol)/(sums.modelSums['F40 12V'].input) * 100).toFixed(1) }}%</td>
        </tr>
        <tr>
          <td>HAF</td>
          <td>Subtotal</td>
          <td>{{ sums.modelSums['HAF'].target }}</td>
          <td>{{ sums.modelSums['HAF'].input }}</td>
          <td>{{ sums.modelSums['HAF'].ok - sums.qwmodelSums['HAF'].ng}}</td>
          <td>{{ sums.modelSums['HAF'].ng + sums.qwmodelSums['HAF'].ng }}</td>
          <td>{{ [ sums.modelSums['HAF'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_lackofcomponents, 0) }}</td> <!-- obj.ng_lackofcomponents 부분을 수정하면 된다.-->          
          <td>{{ [ sums.modelSums['HAF'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_coatingtoomuch, 0) }}</td>
          <td>{{ [ sums.modelSums['HAF'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_coatingtooless, 0) }}</td>
          <td>{{ [ sums.modelSums['HAF'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_coatingbubbles, 0) }}</td>
          <td>{{ [ sums.modelSums['HAF'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_solderingtoomuch, 0) }}</td>          
          <td>{{ [ sums.modelSums['HAF'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_solderingtooless, 0) }}</td>
          <td>{{ [ sums.modelSums['HAF'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_solderingballs, 0) }}</td>
          <td>{{ [ sums.modelSums['HAF'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_lackofbarcode, 0) }}</td>
          <td>{{ [ sums.modelSums['HAF'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_damagebarcode, 0) }}</td>
          <td>{{ [ sums.modelSums['HAF'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_busbarscraches, 0) }}</td>
          <td>{{ [ sums.modelSums['HAF'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_dirtybusbar, 0) }}</td>
          <td>{{ [ sums.modelSums['HAF'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_damagepcb, 0) }}</td>
          <td>{{ [ sums.modelSums['HAF'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_dirtyhousing, 0) }}</td>
          <td>{{ [ sums.modelSums['HAF'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_damagehousing, 0) }}</td>
          <td>{{ [ sums.modelSums['HAF'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_eol, 0) }}</td>
          <td>{{ [ sums.modelSums['HAF'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_oqc, 0) }}</td>
          <td>{{ ((sums.modelSums['HAF'].ng + sums.qwmodelSums['HAF'].ng) / (sums.modelSums['HAF'].input) * 100).toFixed(1) }}%</td>
          <td>{{ sums.modelSums['HAF'].fn_soldering + sums.modelSums['HAF'].fn_coating + sums.modelSums['HAF'].fn_vision + sums.modelSums['HAF'].fn_eol }}</td>
          <td>{{ sums.modelSums['HAF'].fn_soldering }}</td>
          <td>{{ sums.modelSums['HAF'].fn_coating }}</td>
          <td>{{ sums.modelSums['HAF'].fn_eol }}</td>
          <td>{{ sums.modelSums['HAF'].fn_vision }}</td>                    
          <td>{{ ((sums.modelSums['HAF'].fn_soldering + sums.modelSums['HAF'].fn_coating + sums.modelSums['HAF'].fn_vision + sums.modelSums['HAF'].fn_eol)/(sums.modelSums['HAF'].input) * 100).toFixed(1) }}%</td>
        </tr>        
        <tr>
         <th colspan="3">BMW 12V Total</th>         
          <td>{{ sums.shiftSums[1].target + sums.shiftSums[2].target }}</td>
          <td>{{ sums.shiftSums[1].input + sums.shiftSums[2].input }}</td>
          <td class="ok" >{{ sums.shiftSums[1].ok + sums.shiftSums[2].ok - sums.qwmodelSums['F40 12V'].ng - sums.qwmodelSums['HAF'].ng }}</td>
          <td class="ng" >{{ sums.shiftSums[1].ng + sums.shiftSums[2].ng + sums.qwmodelSums['F40 12V'].ng + sums.qwmodelSums['HAF'].ng }}</td>
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['F40 12V'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_lackofcomponents, 0) }}</td> <!-- obj.ng_lackofcomponents 부분을 수정하면 된다.-->          
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['F40 12V'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_coatingtoomuch, 0) }}</td> 
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['F40 12V'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_coatingtooless, 0) }}</td> 
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['F40 12V'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_coatingbubbles, 0) }}</td> 
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['F40 12V'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_solderingtoomuch, 0) }}</td>           
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['F40 12V'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_solderingtooless, 0) }}</td>     
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['F40 12V'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_solderingballs, 0) }}</td>     
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['F40 12V'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_lackofbarcode, 0) }}</td>     
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['F40 12V'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_damagebarcode, 0) }}</td>     
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['F40 12V'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_busbarscraches, 0) }}</td>     
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['F40 12V'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_dirtybusbar, 0) }}</td>     
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['F40 12V'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_damagepcb, 0) }}</td>     
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['F40 12V'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_dirtyhousing, 0) }}</td>     
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['F40 12V'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_damagehousing, 0) }}</td>     
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['F40 12V'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_eol, 0) }}</td>     
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['F40 12V'],sums.qwmodelSums['HAF']].reduce((sum, obj) => sum + obj.ng_oqc, 0) }}</td>          
          <td class="ng">{{ ((sums.shiftSums[1].ng + sums.shiftSums[2].ng + sums.qwmodelSums['F40 12V'].ng + sums.qwmodelSums['HAF'].ng ) / (sums.shiftSums[1].input + sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>          
          <td class="fn">{{ sums.shiftSums[1].fn_soldering + sums.shiftSums[1].fn_coating + sums.shiftSums[1].fn_vision + sums.shiftSums[1].fn_eol + sums.shiftSums[2].fn_soldering + sums.shiftSums[2].fn_coating + sums.shiftSums[2].fn_vision + sums.shiftSums[2].fn_eol }}</td>
          <td class="fn_type">{{ [ sums.shiftSums[1],sums.shiftSums[2]].reduce((sum, obj) => sum + obj.fn_soldering, 0) }}</td>               
          <td class="fn_type">{{ [ sums.shiftSums[1],sums.shiftSums[2]].reduce((sum, obj) => sum + obj.fn_coating, 0) }}</td>               
          <td class="fn_type">{{ [ sums.shiftSums[1],sums.shiftSums[2]].reduce((sum, obj) => sum + obj.fn_eol, 0) }}</td>               
          <td class="fn_type">{{ [ sums.shiftSums[1],sums.shiftSums[2]].reduce((sum, obj) => sum + obj.fn_vision, 0) }}</td>                    
          <td class="fn">{{ ((sums.shiftSums[1].fn_soldering + sums.shiftSums[1].fn_coating + sums.shiftSums[1].fn_vision + sums.shiftSums[1].fn_eol + sums.shiftSums[2].fn_soldering + sums.shiftSums[2].fn_coating + sums.shiftSums[2].fn_vision + sums.shiftSums[2].fn_eol)/(sums.shiftSums[1].input + sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>
        </tr>   
        <tr v-for="qw in qwTableData.data" :key="qw.id" class="qwall">
          <template v-for="(header, index) in headers">
            <!-- Use header instead of qwheader --> 
            <td v-if="header === 'Shift'" :key="index" colspan="2">Q-WALL</td>          
            <td v-if="header === 'Model'" :key="index" @click="editCell(qwTableName, qw.id, 'model', $event)">{{ qw.model }}</td>
            <td v-if="header === 'Target'" :key="index" ></td>
            <td v-if="header === 'Input'" :key="index" ></td>
            <td v-if="header === 'OK'" :key="index" @click="editCell(qwTableName, qw.id, 'ok', $event)">{{ qw.ok }}</td>
            <td v-if="header === 'NG'" :key="index" @click="editCell(qwTableName, qw.id, 'ng', $event)">{{ qw.ng }}</td>
            <td v-if="header === 'Lack of Components'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_lackofcomponents', $event)">{{ qw.ng_lackofcomponents }}</td>
            <td v-if="header === 'Coating too much'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_coatingtoomuch', $event)">{{ qw.ng_coatingtoomuch }}</td>
            <td v-if="header === 'Coating too much'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_coatingtooless', $event)">{{ qw.ng_coatingtooless }}</td>
            <td v-if="header === 'Coating bubbles'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_coatingbubbles', $event)">{{ qw.ng_coatingbubbles }}</td>
            <td v-if="header === 'Soldering too much'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_solderingtoomuch', $event)">{{ qw.ng_solderingtoomuch }}</td>            
            <td v-if="header === 'Soldering too less'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_solderingtooless', $event)">{{ qw.ng_solderingtooless }}</td>
            <td v-if="header === 'Soldering balls'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_solderingballs', $event)">{{ qw.ng_solderingballs }}</td>
            <td v-if="header === 'Lack of barcode'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_lackofbarcode', $event)">{{ qw.ng_lackofbarcode }}</td>
            <td v-if="header === 'Damage barcode'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_damagebarcode', $event)">{{ qw.ng_damagebarcode }}</td>
            <td v-if="header === 'Busbar scraches'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_busbarscraches', $event)">{{ qw.ng_busbarscraches }}</td>
            <td v-if="header === 'Dirty busbar'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_dirtybusbar', $event)">{{ qw.ng_dirtybusbar }}</td>
            <td v-if="header === 'Damage PCB'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_damagepcb', $event)">{{ qw.ng_damagepcb }}</td>
            <td v-if="header === 'Dirty housing'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_dirtyhousing', $event)">{{ qw.ng_dirtyhousing }}</td>
            <td v-if="header === 'Damage housing'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_damagehousing', $event)">{{ qw.ng_damagehousing }}</td>
            <td v-if="header === 'EOL'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_eol', $event)">{{ qw.ng_eol }}</td>
            <td v-if="header === 'OQC'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_oqc', $event)">{{ qw.ng_oqc }}</td>
          </template>
        </tr> 
      </table>           
      
      <br>
      <p>Daily Report</p>
      <table id="dailyReport">
        <tr><th v-for="(drheader, index) in drHeaders" :key="index">{{ drheader }}</th></tr>      
        <tr v-for="dr in drTableData.data" :key="dr.id">
            <template v-for="(drheader, index) in drHeaders">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="drheader === 'Contents'" :key="index"  @click="editCell(drTableName, dr.id, drheader, $event)">{{ dr.contents }}</td>                
            </template>
          </tr>  
        </table>                     
      <br>
      <p>Down Time</p>
      <table id="downTime">
        <tr><th v-for="(dtheader, index) in dtHeaders" :key="index">{{ dtheader }}</th></tr>      
        <tr v-for="dt in dtTableData.data" :key="dt.id">
            <template v-for="(dtheader, index) in dtHeaders">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="dtheader === 'Shift'" :key="index"  @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.shift }}</td>
                <td v-if="dtheader === 'Model'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.model }}</td>
                <td v-if="dtheader === 'Start_time'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.start_time }}</td>
                <td v-if="dtheader === 'End_time'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.end_time }}</td>
                <td v-if="dtheader === 'Time'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.time }}</td>
                <td v-if="dtheader === 'CLASS1'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.class1 }}</td>
                <td v-if="dtheader === 'CLASS2'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.class2 }}</td>
                <td v-if="dtheader === 'CLASS3'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.class3 }}</td>
                <td v-if="dtheader === 'Detail'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.detail }}</td>                                
                <td v-if="dtheader === 'Note'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.note }}</td>   
            </template>
          </tr>  
        </table>   
      </div>                  
    
      <!--<Modal v-if="showModal" @onClose="showModal = false"/> -->
      <Modal v-if="showModal" @onClose="showModal = false; callSearchData();" :line-name="lineName"/>
      <dtModal v-if="showdtModal" @onClose="showdtModal = false; callSearchData();" :line-name="lineName"/>         
      <drModal v-if="showdrModal" @onClose="showdrModal = false; callSearchData();" :line-name="lineName"/> 
      <qwModal v-if="showqwModal" @onClose="showqwModal = false; callSearchData();" :line-name="lineName"/> 
      <rdModal v-if="showrdModal" @onClose="showrdModal = false; callSearchData();" :line-name="lineName"/> 
  </div>
</template>

<script>

import { reactive, ref, computed, } from 'vue';
import { downloadExcel, editCellValue} from "../../utils.js";
import { searchData, calculateSums } from "../productionUtils.js";
import { targets } from "../../lineData.js";

import Modal from "./bmw12v_prm.vue";
import qwModal from "./bmw12v_qwm.vue";
import dtModal from "../modal_downTime.vue";
import drModal from "../modal_dailyReport.vue";
import rdModal from "../modal_repeatData.vue";

export default {      
    components: { Modal, dtModal, drModal, qwModal, rdModal },  

    methods: {
      downExcel () { downloadExcel([this.prTableData.data, this.dtTableData.data, this.drTableData.data, this.qwTableData.data], "Production Data.xlsx", [], ["PR Data", "DT Data", "DR Data", "QW Data"]);},
      editCell(tableName, id, header, event) {
          const currentValue = event.target.innerText;
          const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);
          if (newValue !== null && newValue !== currentValue) {
              editCellValue(tableName, id, header, newValue, this.callSearchData);
          }
      },
    },     

    setup() {
        const lineName = "BMW12V";  // 설비 변경 시 필수 교체        
        const selectedLine = targets.find(target => target.name === lineName);
        if (!selectedLine) { throw new Error(`Line name "${lineName}" not found in targets`); }

        const dbTableName = selectedLine.pr;
        const dtTableName = selectedLine.dt;
        const drTableName = selectedLine.dr;
        const qwTableName = selectedLine.qw;
  
        //Production result part
        const showModal = ref(false); 
        const headers = ['Shift', 'Time', 'Model', 'Target', 'Input', 'OK', 'NG', "Lack of Components", "Coating too much",
                         "Coating too less", 'Coating bubbles', 'Soldering too much', 'Soldering too less', 'Soldering balls',
                         'Lack of barcode', 'Damage barcode', 'Busbar scraches', 'Dirty busbar', 'Damage PCB', 'Dirty housing',
                         'Damage housing', 'EOL', 'OQC', 'NG Rate', 'Fake Sum', 'Fake Soldering', 'Fake Coating',
                         'Fake EOL', 'Fake Vision', 'Fake NG Rate', 'Remark', 'Work Order', 'Supervisor', 
                         'Leader', 'Technician', 'Shift Name', 'Operator Quantity', 'Product code', 'Work Time', 'row_no']; //설비 변경시 필수 교체
        const prTableData = reactive({ data: [], });     

        //Downtime part
        const showdtModal = ref(false);  
        const dtHeaders = ['Shift', 'Model', 'Start_time', 'End_time', 'Time', 'CLASS1', 'CLASS2', 'CLASS3', 'Detail', 'Note']; 
        const dtTableData = reactive({ data: [], });      

        //Daily repot part
        const showdrModal = ref(false);    
        const drHeaders = ['Contents']; 
        const drTableData = reactive({ data: [], });      

        //QWALL part
        const showqwModal = ref(false);   
        const qwTableData = reactive({ data: [], });   

        //Repeat Data part
        const showrdModal = ref(false);  
   
        //Current Time part
        
        const searchDate = ref({
            year: '',
            month: '',
            day: '',
        });

        const sendDate = async () => {
            const sendDateData = [searchDate.value.year, searchDate.value.month, searchDate.value.day]
            return {sendDateData};    
          
          };            

        const calculateInputAndNgRate = (ok, ng, fn_soldering, fn_coating, fn_vision, fn_eol) => {
          const input = ok + ng;
          const fakeng = fn_soldering + fn_coating + fn_vision + fn_eol 
          let ngRate = 0;
          let fn_ngRate = 0;
          if (ng !== 0) {
            ngRate = ((ng / input) * 100).toFixed(1);
          }
          if (fakeng !== 0) {
            fn_ngRate = ((fakeng / input) * 100).toFixed(1);
          }
          return { input, ngRate, fakeng, fn_ngRate };
        };

        const item = computed(() => {
          return prTableData.data.map(d => {
            const { input, ngRate, fakeng, fn_ngRate } = calculateInputAndNgRate(d.ok, d.ng, d.fn_soldering, d.fn_coating, d.fn_vision, d.fn_eol, );
            return { ...d, input, ngRate, fakeng, fn_ngRate, }; 
          });
        });        

        const qwItem = computed(() => {return qwTableData.data.map(d => {return { ...d,};});});    

        const callSearchData = async () => {
          try {
            const { sendDateData } = await sendDate();
            const tableNames = [dbTableName, dtTableName, drTableName, qwTableName];
            const result = await searchData(tableNames, sendDateData);
            
            prTableData.data = result.prTableData;
            dtTableData.data = result.dtTableData;
            drTableData.data = result.drTableData;
            qwTableData.data = result.qwTableData;
          } catch (error) {
            console.error("Error fetching data:", error.message);
          }
        };

        //데이터 더하기 위한 Template, shift, model 설정
        const sumsTemplate = {
          target: 0, input: 0, ok: 0, ng: 0, ng_lackofcomponents: 0, ng_coatingtoomuch: 0, ng_coatingtooless: 0, ng_coatingbubbles: 0, 
          ng_solderingtoomuch: 0, ng_solderingtooless: 0, ng_solderingballs: 0, ng_lackofbarcode: 0 , ng_damagebarcode: 0, 
          ng_busbarscraches: 0, ng_dirtybusbar: 0, ng_damagepcb: 0, ng_dirtyhousing: 0, ng_damagehousing: 0, ng_eol: 0, ng_oqc: 0,
          fn_soldering: 0, fn_coating: 0, fn_eol: 0, fn_vision: 0
        };

        const shiftKeys = [1, 2]; 
        const modelKeys = ["F40 12V", "HAF",];

        const sums = computed(() => calculateSums(item.value, sumsTemplate, shiftKeys, modelKeys, qwItem.value,));
        //끝

        return { calculateInputAndNgRate, headers, showModal, dtHeaders, dtTableData, 
                 showdtModal,  item, dbTableName, prTableData, searchData, searchDate, lineName, 
                 showdrModal, drHeaders, drTableData, sums, callSearchData, downloadExcel,
                 showqwModal, qwTableData, qwItem, dtTableName, drTableName, qwTableName, sendDate,
                 showrdModal,
                 };        
    },
}
</script>

<style scoped src="../0_formStyle/line/style_line_pr.css"></style>