<template>
    <div class="fca1">            
      <div class="act">               
         <h3 class="pagetitle">Packing</h3>
      </div>    
      <form @submit.prevent="sendDate()" class="searchBar">     
        <select id="year" v-model="searchDate.year">   
            <option disabled selected value="" style="color: gray;">Year</option>           
            <option>2021</option><option>2022</option><option>2023</option><option>2024</option>
            <option>2025</option><option>2026</option><option>2027</option><option>2028</option>
            <option>2029</option><option>2030</option><option>2031</option><option>2032</option>
            <option>2033</option><option>2034</option><option>2035</option><option>2036</option>
            <option>2037</option><option>2038</option><option>2039</option><option>2040</option>
        </select>           
        <select id="month" v-model="searchDate.month">            
            <option disabled selected value="" style="color: gray;">Month</option>
            <option>1</option><option>2</option><option>3</option><option>4</option>
            <option>5</option><option>6</option><option>7</option><option>8</option>
            <option>9</option><option>10</option><option>11</option><option>12</option>            
        </select>           
        <select id="day" v-model="searchDate.day">            
            <option disabled selected value="" style="color: gray;">Day</option>
            <option>1</option><option>2</option><option>3</option><option>4</option>
            <option>5</option><option>6</option><option>7</option><option>8</option>
            <option>9</option><option>10</option><option>11</option><option>12</option> 
            <option>13</option><option>14</option><option>15</option><option>16</option>   
            <option>17</option><option>18</option><option>19</option><option>20</option>   
            <option>21</option><option>22</option><option>23</option><option>24</option>
            <option>25</option><option>26</option><option>27</option><option>28</option>
            <option>29</option><option>30</option><option>31</option>                    
        </select>
        <button class="searchButton" type="submit"  @click="callSearchData">Search</button>  
        <button class="excelButton" @click="downExcel">Excel</button>  
        <button class="button" @click="showModal = true">+ Result</button>       
        <button class="button" @click="showdrModal = true">+ Daily Report</button>            
        <button class="button" @click="showrdModal = true">+ Repeat Data</button>
      </form> 
        
      <div class="tables">
      <table id="productionResult" class="custom-table">
        <tr>          
          <th v-for="(header, index) in headers" :key="index">{{ header }}</th>          
        </tr>          
          <tr v-for="row in item" :key="row.id">
            <template v-for="(header, index) in headers">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="header === 'Shift'" :key="index" @click="editCell(dbTableName, row.id, 'shift', $event)" :class="{'shift1': row.shift === 1, 'shift2': row.shift === 2}">{{ row.shift }}</td>
                <td v-if="header === 'Time'" :key="index" class="sticky-time">{{ row.start_time }}~{{ row.end_time }}</td>
                <td v-if="header === 'EVO 2P'" :key="index" class="ok" :class="{ 'zero-value_ok': row.evo_2p === 0 }" @click="editCell(dbTableName, row.id, 'evo_2p', $event)">{{ row.evo_2p }}</td>
                <td v-if="header === 'EVO 3P'" :key="index" class="ok" :class="{ 'zero-value_ok': row.evo_3p === 0 }" @click="editCell(dbTableName, row.id, 'evo_3p', $event)">{{ row.evo_3p }}</td>
                <td v-if="header === 'EV2020 FR/RR'" :key="index" class="ok" :class="{ 'zero-value_ok': row.ev2020frrr === 0 }" @click="editCell(dbTableName, row.id, 'ev2020frrr', $event)">{{ row.ev2020frrr }}</td>
                <td v-if="header === 'EV2020 FFC'" :key="index" class="ok" :class="{ 'zero-value_ok': row.ev2020ffc === 0 }" @click="editCell(dbTableName, row.id, 'ev2020ffc', $event)">{{ row.ev2020ffc }}</td>
                <td v-if="header === 'BMW 48V'" :key="index" class="ok" :class="{ 'zero-value_ok': row.bmw48v === 0 }" @click="editCell(dbTableName, row.id, 'bmw48v', $event)">{{ row.bmw48v }}</td>
                <td v-if="header === 'BMW 12V'" :key="index" class="ok" :class="{ 'zero-value_ok': row.bmw12v === 0 }" @click="editCell(dbTableName, row.id, 'bmw12v', $event)">{{ row.bmw12v }}</td>
                <td v-if="header === 'Ford Front'" :key="index" class="ok" :class="{ 'zero-value_ok': row.ford_front === 0 }" @click="editCell(dbTableName, row.id, 'ford_front', $event)">{{ row.ford_front }}</td>
                <td v-if="header === 'Ford Rear'" :key="index" class="ok" :class="{ 'zero-value_ok': row.ford_rear === 0 }" @click="editCell(dbTableName, row.id, 'ford_rear', $event)">{{ row.ford_rear }}</td>
                <td v-if="header === 'Remark'" :key="index" @click="editCell(dbTableName, row.id, 'remark', $event)">{{ row.remark }}</td> 
                <td v-if="header === 'Work Order'" :key="index" @click="editCell(dbTableName, row.id, 'workorder', $event)">{{ row.workorder }}</td> 
                <td v-if="header === 'Supervisor'" :key="index" @click="editCell(dbTableName, row.id, 'supervisor', $event)">{{ row.supervisor }}</td> 
                <td v-if="header === 'Leader'" :key="index" @click="editCell(dbTableName, row.id, 'leader', $event)">{{ row.leader }}</td>                 
                <td v-if="header === 'Shift Name'" :key="index" @click="editCell(dbTableName, row.id, 'shift_name', $event)">{{ row.shift_name }}</td> 
                <td v-if="header === 'Operator Quantity'" :key="index" @click="editCell(dbTableName, row.id, 'operator_qty', $event)">{{ row.operator_qty }}</td> 
                <td v-if="header === 'Work Time'" :key="index" @click="editCell(dbTableName, row.id, 'work_minute', $event)">{{ row.work_minute }}</td> 
                <td v-if="header === 'row_no'" :key="index" @click="editCell(dbTableName, row.id, 'row_no', $event)">{{ row.row_no }}</td>                
            </template>
        </tr>
        <tr>
         <th rowspan="2">Shift<br>Subtotal</th>
         <td>1</td>                  
          <td>{{ sums.shiftSums[1].evo_2p }}</td>
          <td>{{ sums.shiftSums[1].evo_3p }}</td>
          <td>{{ sums.shiftSums[1].ev2020frrr }}</td>
          <td>{{ sums.shiftSums[1].ev2020ffc }}</td>
          <td>{{ sums.shiftSums[1].bmw48v }}</td>
          <td>{{ sums.shiftSums[1].bmw12v }}</td>
          <td>{{ sums.shiftSums[1].ford_front }}</td>
          <td>{{ sums.shiftSums[1].ford_rear }}</td>         
        </tr>
        <tr>
          <td>2</td>        
          <td>{{ sums.shiftSums[2].evo_2p }}</td>
          <td>{{ sums.shiftSums[2].evo_3p }}</td>
          <td>{{ sums.shiftSums[2].ev2020frrr }}</td>
          <td>{{ sums.shiftSums[2].ev2020ffc }}</td>
          <td>{{ sums.shiftSums[2].bmw48v }}</td>
          <td>{{ sums.shiftSums[2].bmw12v }}</td>
          <td>{{ sums.shiftSums[2].ford_front }}</td>
          <td>{{ sums.shiftSums[2].ford_rear }}</td>
        </tr>
        <tr>
         <th colspan="2">Packing Total</th>         
          <td>{{ sums.shiftSums[1].evo_2p + sums.shiftSums[2].evo_2p }}</td>
          <td>{{ sums.shiftSums[1].evo_3p + sums.shiftSums[2].evo_3p }}</td>
          <td>{{ sums.shiftSums[1].ev2020frrr + sums.shiftSums[2].ev2020frrr }}</td>
          <td>{{ sums.shiftSums[1].ev2020ffc + sums.shiftSums[2].ev2020ffc }}</td>
          <td>{{ sums.shiftSums[1].bmw48v + sums.shiftSums[2].bmw48v }}</td>
          <td>{{ sums.shiftSums[1].bmw12v + sums.shiftSums[2].bmw12v }}</td>
          <td>{{ sums.shiftSums[1].ford_front + sums.shiftSums[2].ford_front }}</td>
          <td>{{ sums.shiftSums[1].ford_rear + sums.shiftSums[2].ford_rear }}</td>   
        </tr>   
      </table>   
     
      <h1 class="tableTitle">Daily Report</h1>
      <table id="dailyReport">
        <tr><th v-for="(drheader, index) in drHeaders" :key="index">{{ drheader }}</th></tr>      
        <tr v-for="dr in drTableData.data" :key="dr.id">
            <template v-for="(drheader, index) in drHeaders">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="drheader === 'Contents'" :key="index"  @click="editCell(drTableName, dr.id, drheader, $event)">{{ dr.contents }}</td>                
            </template>
          </tr>  
        </table>    
      </div>   
    
      <!--<Modal v-if="showModal" @onClose="showModal = false"/> -->
      <Modal v-if="showModal" @onClose="showModal = false; callSearchData();" :line-name="lineName"/>             
      <drModal v-if="showdrModal" @onClose="showdrModal = false; callSearchData();" :line-name="lineName"/>      
      <rdModal v-if="showrdModal" @onClose="showrdModal = false; callSearchData();" :line-name="lineName"/> 
  </div>
</template>

<script>

import { reactive, ref, computed, } from 'vue';
import { downloadExcel, editCellValue} from "../../utils.js";
import { searchData, calculateSums } from "../productionUtils.js";
import { targets } from "../../lineData.js";

import Modal from "./packing_prm.vue";
import drModal from "./packing_prdrm.vue";
import rdModal from "./packing_rdm.vue";

export default {      
    components: { Modal, drModal, rdModal },  

    methods: {
      downExcel () { downloadExcel([this.prTableData.data, this.drTableData.data], "Production Data.xlsx", [], ["PR Data", "DR Data", ]);},
      editCell(tableName, id, header, event) {
          const currentValue = event.target.innerText;
          const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);
          if (newValue !== null && newValue !== currentValue) {
              editCellValue(tableName, id, header, newValue, this.callSearchData);
          }
      },
    },  

    setup() {
        const lineName = "Packing"  //설비 변경시 필수 교체        
        const selectedLine = targets.find(target => target.name === lineName);
        if (!selectedLine) { throw new Error(`Line name "${lineName}" not found in targets`); }

        const dbTableName = selectedLine.pr;       
        const drTableName = selectedLine.dr;   
  
        //Production result part
        const showModal = ref(false); 
        const headers = ['Shift', 'Time', 'EVO 2P', 'EVO 3P', 'EV2020 FR/RR', 'EV2020 FFC',
                         'BMW 48V', 'BMW 12V', 'Ford Front', 'Ford Rear',
                         'Remark', 'Work Order', 'Supervisor', 
                         'Leader', 'Shift Name', 'Operator Quantity', 'Work Time', 'row_no']; //설비 변경시 필수 교체
        const prTableData = reactive({ data: [], });            

        //Daily repot part
        const showdrModal = ref(false);    
        const drHeaders = ['Contents']; 
        const drTableData = reactive({ data: [], });          

        //Repeat Data part
        const showrdModal = ref(false);     
   
        //Current Time part
        
        const searchDate = ref({ year: '',  month: '',  day: '', });

        const sendDate = async () => {
          const sendDateData = [searchDate.value.year, searchDate.value.month, searchDate.value.day]
          return {sendDateData};              
        };        

        const callSearchData = async () => {
          try {
            const { sendDateData } = await sendDate();
            const tableNames = [dbTableName, drTableName ];
            const result = await searchData(tableNames, sendDateData);
            
            prTableData.data = result.prTableData;           
            drTableData.data = result.dtTableData;  //productionUtils.js의 searchData -> classifyTableData에 따라서 tableNames의 DB순서대로 data를 가져오므로 result.dtTableData로 했다.
            
          } catch (error) {
            console.error("Error fetching data:", error.message);
          }
        };

        const item = computed(() => { return prTableData.data.map(d => {return { ...d };});}); 
        const itemDr = computed(() => { return drTableData.data.map(d => {return { ...d };});}); 

       //데이터 더하기 위한 Template, shift, model 설정
        const sumsTemplate = {
            evo_2p: 0, evo_3p: 0, ev2020frrr: 0, ev2020ffc: 0, bmw48v: 0, bmw12v: 0,
            ford_front: 0, ford_rear: 0
        };

        const shiftKeys = [1, 2]; 
        const modelKeys = [];

        const sums = computed(() => calculateSums(item.value, sumsTemplate, shiftKeys, modelKeys,));
        //끝    

        return { headers, showModal, item, dbTableName, prTableData, searchData, searchDate, lineName, 
                 showdrModal, drHeaders, drTableData, drTableName, itemDr, sums, callSearchData, downloadExcel,
                 sendDate, showrdModal, 
        };        
    },
}
</script>

<style scoped src="../0_formStyle/line/style_line_pr.css"></style>