<template>    
  <div>   
    <table class="searchTable" style="width: 1200px">
      <tr>
        <td>
          <b>Line:</b> <select class="searchSelect" v-model="line">
            <option v-for="line in uniqueLines" :key="line">{{ line }}</option>    
            <option></option>    
          </select>          
        </td>
        <td>
          <b>Type:</b> <select class="searchSelect" v-model="type">
            <option v-for="type in uniqueType" :key="type">{{ type }}</option>    
            <option></option>    
          </select>          
        </td>
        <td>
          <input class="searchInput" type="text" id="word" v-model="code" placeholder="Search Code and Name" />
        </td>        
        <td>
          <button type="submit" class="searchButton" @click="callSearchData">Search</button>
        </td>
        <td><button class="addButton" @click="showModal = true">+ Add</button></td>   
        <td>
          <label for="fileChoose" class="custom-file-upload">Choose file</label>
          <input id="fileChoose" type="file" @change="fileChange" accept=".xlsx, .xls" style="display: none" />
        </td>
        <td><button class="excelUpload" @click="upExcel('materialList')">List Upload</button></td>
        <td><button class="excelDownload" @click="downExcel">Down Load</button></td>
        <td><button class="delButton" :class="{ disabledButton: !canClick() }" :disabled="!canClick()" @click="delAll(dbTableName, {delallcode: '1'} )">ALL DEL</button></td>
      </tr>
      <tr>
        <td>{{ fileName }}</td>
      </tr>
    </table>    
  </div>
  <div class="tableWrapper">
    <table class="contentsTable" style="width: 95%;">      
      <thead class="contentsTableTh"> 
        <td>Line</td><td>Type</td><td>Code</td><td>Name</td><td>Unit</td><td>BOM</td>
        <td>Copper Weight</td><td>Copper Type</td><td>Coating</td><td>Remark</td><td>Row_no</td>      
      </thead>            
      <tbody>
        <tr class="contentsTableTr" v-for="row in item" :key="row.id">           
          <td>{{ row.line }}</td>
          <td>{{ row.type }}</td>
          <td>{{ row.code }}</td>
          <td @click="editCell(dbTableName, row.id, 'name', $event)">{{ row.name }}</td>
          <td @click="editCell(dbTableName, row.id, 'unit', $event)">{{ row.unit }}</td>
          <td><button class="modifyButton" @click="openBomModal(row)">BOM</button></td>          
          <td @click="editCell(dbTableName, row.id, 'copperweight', $event)">{{ typeof row.copperweight === 'number' ? row.copperweight.toFixed(2) : row.copperweight }}</td>          
          <td @click="editCell(dbTableName, row.id, 'coppertype', $event)">{{ row.coppertype }}</td>
          <td @click="editCell(dbTableName, row.id, 'coating', $event)">{{ row.coating }}</td>
          <td @click="editCell(dbTableName, row.id, 'remark', $event)">{{ row.remark }}</td>
          <td @click="editCell(dbTableName, row.id, 'row_no', $event)">{{ row.row_no }}</td>          
         </tr>
      </tbody>     
    </table>
  </div>
  <div>
    <h3 style="margin-left:10px">ROW_NO Standard</h3>
    <table class="bottomTable" style="width:50%; text-align: left;">
      <thead>
        <td>Project</td><td>Finished/Semi/Individual</td><td>Product classification</td><td>Serial No</td>
      </thead>
      <tbody>
        <tr>
          <td>2 digit<br>Toyota : 10<br>Honda : 11<br>ESS : 12</td>        
          <td>1 digit<br>Finished : 1<br>Semi : 2<br>Individual : 3</td>    
          <td>3 digit</td>
          <td>2 digit</td>
        </tr>  
      </tbody>    
    </table>
  </div>       
  <Modal v-if="showModal" @onClose="showModal = false; callSearchData();"/>    
  <bomModal v-if="showBomModal" :code="selectedCode" :type="selectedType" :name="selectedName" :unit="selectedUnit" @onClose="showBomModal = false; callSearchData();" />
</template>

<script>
import { reactive, computed, ref, onMounted} from 'vue';
import axios from "axios";
import { onFileChange, uploadExcel, deleteAll, downloadExcel, editCellValue, fetchData } from "../utils.js"; 
import Modal from "./materialListModal.vue";
import bomModal from './bomModal.vue';

export default {       
  components: { Modal, bomModal,},
  data() { return { excelFile: null, fileName: "", showBomModal: false, selectedCode: null, selectedName: null, selectedType: null, selectedUnit: null, }; },  

  methods: {
    fileChange(event) { const { file, fileName } = onFileChange(event); this.excelFile = file; this.fileName = fileName; },
    upExcel(tableName) { uploadExcel(tableName, this.excelFile, alert); },  
    downExcel() { downloadExcel(this.item, "materialList.xlsx"); },      
    delAll(tableName, filters) { deleteAll(tableName, filters, this.callSearchData); },
    editCell(tableName, id, header, event) {
      const currentValue = event.target.innerText;
      const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);
      if (newValue !== null && newValue !== currentValue) {
          editCellValue(tableName, id, header, newValue, this.callSearchData);
      }
    },
    canClick() {            
      const currentUser = this.$route.meta.currentUser;            
      if (currentUser) { return currentUser.auth === 1 || currentUser.auth === 2;}
      else { return false;}
    },    
    openBomModal(row) {
      this.selectedCode = row.code;
      this.selectedType = row.type;
      this.selectedName = row.name;
      this.selectedUnit = row.unit;
      this.showBomModal = true;},    
  },
  setup() {   
    const dbTableName = "materialList";            
    const Headers = ['Product Code', 'Product Name', 'Material Code', 'Material Name', 'BOM', 'BOM Unit',
                      'Copper Weight', 'Copper Type', 'Coating', 'ROW_NO', 'Remark'
    ];
    const materialList = reactive({ data: [], })
    const line = ref();
    const code = ref();
    const type = ref();
    const uniqueType = ref([]);  
    const uniqueLines = ref([]);  
    const showModal = ref(false);     

    const callSearchData = () => { searchData(dbTableName);  }; 

    onMounted(() => { callSearchData(); });

    const searchData = async () => {              
      const formData = {        
        tableName: dbTableName,                
        line: line.value,
        code: code.value,
        type: type.value,
        searchColumns: {                
          line: ['line'],  
          code: ['name', 'code'],  
          type: ['type',],          
        }
      };  

      axios.get('/api/filteredSearch', { params: formData, })                      
            .then((res) => { materialList.data = res.data.sort((a,b) => a.row_no - b.row_no);
      });  
    };

    const item = computed(() => {return materialList.data.map(d => {return { ...d,};});});       

    const loadType = async () => {
      uniqueType.value = await fetchData(dbTableName, 'type');
      uniqueType.value.sort((a, b) => a.localeCompare(b));
    }; loadType();  

    const loadLines = async () => {
      uniqueLines.value = await fetchData(dbTableName, 'line');
      uniqueLines.value.sort((a, b) => a.localeCompare(b));
    }; loadLines();  
    
    return { item, materialList, editCellValue, Headers, dbTableName, callSearchData,
             uniqueType, code, type, line, showModal, uniqueLines
    };        
  },  
}
</script>

<style scoped>
@import '../generalStyle.css';
</style>