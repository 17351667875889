<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add Select Option</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table class="itemTable">
              <tr>
                <td class="titleTd">Material Type:</td>
                <td><input class="searchInput" type="text" v-model="formInputs.materialtype" /></td>
              </tr>
              <tr>
                <td class="titleTd">Position:</td>
                <td><input class="searchInput" type="text" v-model="formInputs.position" /></td>
              </tr>
              <tr>
                <td class="titleTd">Process:</td>
                <td><input class="searchInput" type="text" v-model="formInputs.process" /></td>
              </tr>
              <tr>
                <td class="titleTd">Row No:</td>
                <td><input class="searchInput" type="text" v-model="formInputs.row_no" /></td>
              </tr>                
            </table>
            <button class="submitButton" type="submit" :class="{ 'readOnly': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button> 
            {{ saveStatus }}        
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
<script>    
import { ref } from 'vue';  
import axios from 'axios';   
import { getColumnMapping, makeFormData, useFormInputs } from '../../utils.js';
  
export default {     
  props: { onSubmit: { type: Function, required: true, }, },           
  setup(props, { emit }) {       
      const dbTableName = "selectoption"      
      const saveStatus = ref(''); 
      const isProcessing = ref(false);   
      
      const { formInputs, dataForm } = useFormInputs();    
      
      
      const submitForm = async () => {
        
        isProcessing.value = true; 
        saveStatus.value = 'Processing...';                         

        const columnMapping = getColumnMapping(undefined, formInputs);            
        const formData = makeFormData(undefined, formInputs, dbTableName, columnMapping); 
            
        try {
          await axios.post('/api/post', formData);
          saveStatus.value = 'Completed'; 
        } catch (error) {
          console.error('Error during submission:', error);
          saveStatus.value = 'Error';
        } finally {
          isProcessing.value = false; 
        }     
        closeModal();              
      };     

      const closeModal = () => { emit("onClose");};

    return { dbTableName, formInputs, submitForm, closeModal, dataForm };

  },       
};
  
</script>
    
<style scoped>
@import '../../generalStyle.css';
.modal-wrapper {
  max-width: 400px;
}
</style>