<template>
    <div class="fcaflexbc">            
      <div class="act">               
         <h3 class="pagetitle">FCA Flexible Busbar Carrier</h3>         
      </div>    
      <form @submit.prevent="sendDate()" class="searchBar">     
        <select id="year" v-model="searchDate.year">   
            <option disabled selected value="" style="color: gray;">Year</option>           
            <option>2021</option><option>2022</option><option>2023</option><option>2024</option>
            <option>2025</option><option>2026</option><option>2027</option><option>2028</option>
            <option>2029</option><option>2030</option><option>2031</option><option>2032</option>
            <option>2033</option><option>2034</option><option>2035</option><option>2036</option>
            <option>2037</option><option>2038</option><option>2039</option><option>2040</option>
        </select>           
        <select id="month" v-model="searchDate.month">            
            <option disabled selected value="" style="color: gray;">Month</option>
            <option>1</option><option>2</option><option>3</option><option>4</option>
            <option>5</option><option>6</option><option>7</option><option>8</option>
            <option>9</option><option>10</option><option>11</option><option>12</option>            
        </select>           
        <select id="day" v-model="searchDate.day">            
            <option disabled selected value="" style="color: gray;">Day</option>
            <option>1</option><option>2</option><option>3</option><option>4</option>
            <option>5</option><option>6</option><option>7</option><option>8</option>
            <option>9</option><option>10</option><option>11</option><option>12</option> 
            <option>13</option><option>14</option><option>15</option><option>16</option>   
            <option>17</option><option>18</option><option>19</option><option>20</option>   
            <option>21</option><option>22</option><option>23</option><option>24</option>
            <option>25</option><option>26</option><option>27</option><option>28</option>
            <option>29</option><option>30</option><option>31</option>                    
        </select>
        <button class="searchButton" type="submit"  @click="callSearchData">Search</button>  
        <button class="excelButton" @click="downExcel">Excel</button> 
        <button class="button" @click="showModal = true">+ Result</button>
        <button class="button" @click="showdtModal = true">+ Downtime</button>
        <button class="button" @click="showdrModal = true">+ Daily Report</button>
        <button class="qwallButton" @click="showqwModal = true">+ Q-wall</button>    
        <button class="button" @click="showrdModal = true">+ Repeat Data</button>   
      </form> 
      <P></P>     
      <div class="tables">
      <table id="productionResult" class="custom-table">
        <tr>          
          <th v-for="(header, index) in headers" :key="index">{{ header }}</th>          
        </tr>          
          <tr v-for="row in item" :key="row.id">
            <template v-for="(header, index) in headers">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="header === 'Shift'" :key="index" @click="editCell(dbTableName, row.id, 'shift', $event)" :class="{'shift1': row.shift === 1, 'shift2': row.shift === 2}">{{ row.shift }}</td>
                <td v-if="header === 'Time'" :key="index" class="sticky-time">{{ row.start_time }}~{{ row.end_time }}</td>                
                <td v-if="header === 'Target'" :key="index" @click="editCell(dbTableName, row.id, 'target', $event)">{{ row.target }}</td>
                <td v-if="header === 'Input'" :key="index" >{{ row.input }}</td>
                <td v-if="header === 'OK'" :key="index" class="ok" @click="editCell(dbTableName, row.id, 'ok', $event)">{{ row.ok }}</td>
                <td v-if="header === 'NG'" :key="index" class="ng" :class="{ 'zero-value_ng': row.ng === 0 }" @click="editCell(dbTableName, row.id, 'ng', $event)">{{ row.ng }}</td>
                <td v-if="header === 'Broken bracket'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_brokenbracket === 0 }" @click="editCell(dbTableName, row.id, 'ng_brokenbracket', $event)">{{ row.ng_brokenbracket }}</td>
                <td v-if="header === 'Damaged busbar'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_damagedbusbar === 0 }" @click="editCell(dbTableName, row.id, 'ng_damagedbusbar', $event)">{{ row.ng_damagedbusbar }}</td>
                <td v-if="header === 'Visible copper'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_visiblecopper === 0 }" @click="editCell(dbTableName, row.id, 'ng_visiblecopper', $event)">{{ row.ng_visiblecopper }}</td>                
                <td v-if="header === 'Damaged screw'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_damagedscrew === 0 }" @click="editCell(dbTableName, row.id, 'ng_damagedscrew', $event)">{{ row.ng_damagedscrew }}</td>                
                <td v-if="header === 'NG Rate'" :key="index" class="ng" >{{ row.ngRate }}%</td>
                <td v-if="header === 'Fake Sum'" :key="index" class="fn" :class="{ 'zero-value_fn': row.fakeng === 0 }">{{ row.fakeng }}</td> <!--need to make cal-->
                <td v-if="header === 'Fake Broken bracket'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_brokenbracket === 0 }" @click="editCell(dbTableName, row.id, 'fn_brokenbracket', $event)">{{ row.fn_brokenbracket }}</td> 
                <td v-if="header === 'Fake Damaged busbar'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_damagedbusbar === 0 }" @click="editCell(dbTableName, row.id, 'fn_damagedbusbar', $event)">{{ row.fn_damagedbusbar }}</td>
                <td v-if="header === 'Fake Visible copper'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_visiblecopper === 0 }" @click="editCell(dbTableName, row.id, 'fn_visiblecopper', $event)">{{ row.fn_visiblecopper }}</td>
                <td v-if="header === 'Fake Damaged screw'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_damagedscrew === 0 }" @click="editCell(dbTableName, row.id, 'fn_damagedscrew', $event)">{{ row.fn_damagedscrew }}</td>
                <td v-if="header === 'Fake NG Rate'" :key="index" class="fn" >{{ row.fn_ngRate }}%</td> <!--need to make cal-->
                <td v-if="header === 'Remark'" :key="index" @click="editCell(dbTableName, row.id, 'remark', $event)">{{ row.remark }}</td> 
                <td v-if="header === 'Work Order'" :key="index" @click="editCell(dbTableName, row.id, 'workorder', $event)">{{ row.workorder }}</td> 
                <td v-if="header === 'Supervisor'" :key="index" @click="editCell(dbTableName, row.id, 'supervisor', $event)">{{ row.supervisor }}</td> 
                <td v-if="header === 'Leader'" :key="index" @click="editCell(dbTableName, row.id, 'leader', $event)">{{ row.leader }}</td> 
                <td v-if="header === 'Technician'" :key="index" @click="editCell(dbTableName, row.id, 'technician', $event)">{{ row.technician }}</td> 
                <td v-if="header === 'Shift Name'" :key="index" @click="editCell(dbTableName, row.id, 'shift_name', $event)">{{ row.shift_name }}</td> 
                <td v-if="header === 'Operator Quantity'" :key="index" @click="editCell(dbTableName, row.id, 'operator_qty', $event)">{{ row.operator_qty }}</td> 
                <td v-if="header === 'Work Time'" :key="index" @click="editCell(dbTableName, row.id, 'work_minute', $event)">{{ row.work_minute }}</td> 
                <td v-if="header === 'row_no'" :key="index" @click="editCell(dbTableName, row.id, 'row_no', $event)">{{ row.row_no }}</td>                
            </template>
        </tr>
        <tr>
         <th rowspan="2">Shift</th>
         <td>1 Subtotal</td>          
          <td>{{ sums.shiftSums[1].target }}</td>
          <td>{{ sums.shiftSums[1].input }}</td>
          <td>{{ sums.shiftSums[1].ok }}</td>
          <td>{{ sums.shiftSums[1].ng }}</td>
          <td>{{ sums.shiftSums[1].ng_brokenbracket }}</td>
          <td>{{ sums.shiftSums[1].ng_damagedbusbar }}</td>
          <td>{{ sums.shiftSums[1].ng_visiblecopper }}</td>
          <td>{{ sums.shiftSums[1].ng_damagedscrew }}</td>          
          <td>{{ ((sums.shiftSums[1].ng) / (sums.shiftSums[1].input) * 100).toFixed(1) }}%</td>
          <td>{{ shift1FnTotal }}</td>
          <td>{{ sums.shiftSums[1].fn_brokenbracket }}</td>
          <td>{{ sums.shiftSums[1].fn_damagedbusbar }}</td>
          <td>{{ sums.shiftSums[1].fn_visiblecopper }}</td>
          <td>{{ sums.shiftSums[1].fn_damagedscrew }}</td>          
          <td>{{ (( shift1FnTotal )/(sums.shiftSums[1].input) * 100).toFixed(1) }}%</td>
        </tr>
        <tr>
          <td>2 Subtotal</td>          
          <td>{{ sums.shiftSums[2].target }}</td>
          <td>{{ sums.shiftSums[2].input }}</td>
          <td>{{ sums.shiftSums[2].ok }}</td>
          <td>{{ sums.shiftSums[2].ng }}</td>
          <td>{{ sums.shiftSums[2].ng_brokenbracket }}</td>
          <td>{{ sums.shiftSums[2].ng_damagedbusbar }}</td>
          <td>{{ sums.shiftSums[2].ng_visiblecopper }}</td>
          <td>{{ sums.shiftSums[2].ng_damagedscrew }}</td>          
          <td>{{ ((sums.shiftSums[2].ng) / (sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>
          <td>{{ shift2FnTotal }}</td>
          <td>{{ sums.shiftSums[2].fn_brokenbracket }}</td>
          <td>{{ sums.shiftSums[2].fn_damagedbusbar }}</td>
          <td>{{ sums.shiftSums[2].fn_visiblecopper }}</td>
          <td>{{ sums.shiftSums[2].fn_damagedscrew }}</td>          
          <td>{{ (( shift2FnTotal )/(sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>
        </tr>    
        <tr>
         <th colspan="2">Busbar Carrier Total</th>         
          <td>{{ sums.prtotalSums.target }}</td>
          <td>{{ sums.prtotalSums.input  }}</td>
          <td class="ok" >{{ sums.prtotalSums.ok - sums.qwtotalSums.ng }}</td>
          <td class="ng" >{{ sums.prtotalSums.ng + sums.qwtotalSums.ng }}</td>
          <td class="ng_type">{{ [ sums.prtotalSums,sums.qwtotalSums ].reduce((sum, obj) => sum + obj.ng_brokenbracket, 0) }}</td> <!-- obj.ng_assembly 부분을 수정하면 된다.-->          
          <td class="ng_type">{{ [ sums.prtotalSums,sums.qwtotalSums ].reduce((sum, obj) => sum + obj.ng_damagedbusbar, 0) }}</td> 
          <td class="ng_type">{{ [ sums.prtotalSums,sums.qwtotalSums ].reduce((sum, obj) => sum + obj.ng_visiblecopper, 0) }}</td> 
          <td class="ng_type">{{ [ sums.prtotalSums,sums.qwtotalSums ].reduce((sum, obj) => sum + obj.ng_damagedscrew, 0) }}</td>           
          <td class="ng">{{ ((sums.prtotalSums.ng + sums.qwtotalSums.ng ) / (sums.prtotalSums.input) * 100).toFixed(1) }}%</td>          
          <td class="fn">{{ fnTotal }}</td>
          <td class="fn_type">{{ sums.prtotalSums.fn_brokenbracket  }}</td>               
          <td class="fn_type">{{ sums.prtotalSums.fn_damagedbusbar  }}</td>               
          <td class="fn_type">{{ sums.prtotalSums.fn_visiblecopper  }}</td>
          <td class="fn_type">{{ sums.prtotalSums.fn_damagedscrew  }}</td>          
          <td class="fn">{{ ((fnTotal)/(sums.prtotalSums.input ) * 100).toFixed(1) }}%</td>
        </tr>   
        <tr v-for="qw in qwTableData.data" :key="qw.id" class="qwall">
          <template v-for="(header, index) in headers">
            <!-- Use header instead of qwheader --> 
            <td v-if="header === 'Shift'" :key="index" colspan="2">Q-WALL</td>                      
            <td v-if="header === 'Target'" :key="index" ></td>
            <td v-if="header === 'Input'" :key="index" ></td>
            <td v-if="header === 'OK'" :key="index" @click="editCell(qwTableName, qw.id, 'ok', $event)">{{ qw.ok }}</td>
            <td v-if="header === 'NG'" :key="index" @click="editCell(qwTableName, qw.id, 'ng', $event)">{{ qw.ng }}</td>
            <td v-if="header === 'Broken bracket'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_brokenbracket', $event)">{{ qw.ng_brokenbracket }}</td>
            <td v-if="header === 'Damaged busbar'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_damagedbusbar', $event)">{{ qw.ng_damagedbusbar }}</td>
            <td v-if="header === 'Visible copper'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_visiblecopper', $event)">{{ qw.ng_visiblecopper }}</td>
            <td v-if="header === 'Damaged screw'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_damagedscrew', $event)">{{ qw.ng_damagedscrew }}</td>            
          </template>
        </tr> 
      </table>           
      
      <br>
      <p>Daily Report</p>
      <table id="dailyReport">
        <tr><th v-for="(drheader, index) in drHeaders" :key="index">{{ drheader }}</th></tr>      
        <tr v-for="dr in drTableData.data" :key="dr.id">
            <template v-for="(drheader, index) in drHeaders">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="drheader === 'Contents'" :key="index"  @click="editCell(drTableName, dr.id, drheader, $event)">{{ dr.contents }}</td>                
            </template>
          </tr>  
        </table>                     
      <br>
      <p>Down Time</p>
      <table id="downTime">
        <tr><th v-for="(dtheader, index) in dtHeaders" :key="index">{{ dtheader }}</th></tr>      
        <tr v-for="dt in dtTableData.data" :key="dt.id">
            <template v-for="(dtheader, index) in dtHeaders">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="dtheader === 'Shift'" :key="index"  @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.shift }}</td>               
                <td v-if="dtheader === 'Start_time'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.start_time }}</td>
                <td v-if="dtheader === 'End_time'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.end_time }}</td>
                <td v-if="dtheader === 'Time'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.time }}</td>
                <td v-if="dtheader === 'CLASS1'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.class1 }}</td>
                <td v-if="dtheader === 'CLASS2'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.class2 }}</td>
                <td v-if="dtheader === 'CLASS3'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.class3 }}</td>
                <td v-if="dtheader === 'Detail'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.detail }}</td>                                
                <td v-if="dtheader === 'Note'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.note }}</td>   
            </template>
          </tr>  
        </table>   
      </div>                  
    
      <!--<Modal v-if="showModal" @onClose="showModal = false"/> -->
      <Modal v-if="showModal" @onClose="showModal = false; callSearchData();" :line-name="lineName"/>
      <dtModal v-if="showdtModal" @onClose="showdtModal = false; callSearchData();" :line-name="lineName"/>         
      <drModal v-if="showdrModal" @onClose="showdrModal = false; callSearchData();" :line-name="lineName"/> 
      <qwModal v-if="showqwModal" @onClose="showqwModal = false; callSearchData();" :line-name="lineName"/> 
      <rdModal v-if="showrdModal" @onClose="showrdModal = false; callSearchData();" :line-name="lineName"/> 
  </div>
</template>

<script>

import { reactive, ref, computed, } from 'vue';
import { downloadExcel, editCellValue} from "../../../utils.js";
import { searchData, calculateSums } from "../../productionUtils.js";
import { targets } from "../../../lineData.js";

import Modal from "./fca_flex_bc_prm.vue";
import qwModal from "./fca_flex_bc_qwm.vue";
import dtModal from "./fca_flex_bc_prdtm.vue";
import drModal from "./fca_flex_bc_prdrm.vue";
import rdModal from "./fca_flex_bc_rdm.vue";

export default {      
    components: { Modal, dtModal, drModal, qwModal, rdModal },   

    methods: {
      downExcel () { downloadExcel([this.prTableData.data, this.dtTableData.data, this.drTableData.data, this.qwTableData.data], "Production Data.xlsx", [], ["PR Data", "DT Data", "DR Data", "QW Data"]);},
      editCell(tableName, id, header, event) {
        const currentValue = event.target.innerText;
        const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);
        if (newValue !== null && newValue !== currentValue) {
            editCellValue(tableName, id, header, newValue, this.callSearchData);
        }
      },
    }, 

    setup() {
        const lineName = "FCA FLEX BC";  // 설비 변경 시 필수 교체        
        const selectedLine = targets.find(target => target.name === lineName);
        if (!selectedLine) { throw new Error(`Line name "${lineName}" not found in targets`); }

        const dbTableName = selectedLine.pr;
        const dtTableName = selectedLine.dt;
        const drTableName = selectedLine.dr;
        const qwTableName = selectedLine.qw;
  
        //Production result part
        const showModal = ref(false); 
        const headers = ['Shift', 'Time', 'Target', 'Input', 'OK', 'NG', "Broken bracket", "Damaged busbar",
                         "Visible copper", 'Damaged screw', 'NG Rate', 'Fake Sum', "Fake Broken bracket", "Fake Damaged busbar",
                         "Fake Visible copper", 'Fake Damaged screw', 'Fake NG Rate', 'Remark', 'Work Order', 'Supervisor', 
                         'Leader', 'Technician', 'Shift Name', 'Operator Quantity', 'Work Time', 'row_no']; //설비 변경시 필수 교체
        const prTableData = reactive({ data: [], });     

        //Downtime part
        const showdtModal = ref(false);  
        const dtHeaders = ['Shift', 'Start_time', 'End_time', 'Time', 'CLASS1', 'CLASS2', 'CLASS3', 'Detail', 'Note']; 
        const dtTableData = reactive({ data: [], });      

        //Daily repot part
        const showdrModal = ref(false);    
        const drHeaders = ['Contents']; 
        const drTableData = reactive({ data: [], });      

        //QWALL part
        const showqwModal = ref(false);   
        const qwTableData = reactive({ data: [], }); 
        
        //Repeat Data part
        const showrdModal = ref(false);  
   
        //Current Time part
        
        const searchDate = ref({
            year: '',
            month: '',
            day: '',
        });

        const sendDate = async () => {
            const sendDateData = [searchDate.value.year, searchDate.value.month, searchDate.value.day]
            return {sendDateData};    
          
          };            

        const calculateInputAndNgRate = (ok, ng, fn_brokenbracket, fn_damagedbusbar, fn_visiblecopper, fn_damagedscrew, ) => {
          const input = ok + ng;
          const fakeng = fn_brokenbracket + fn_damagedbusbar + fn_visiblecopper + fn_damagedscrew
          let ngRate = 0;
          let fn_ngRate = 0;
          if (ng !== 0) {
            ngRate = ((ng / input) * 100).toFixed(1);
          }
          if (fakeng !== 0) {
            fn_ngRate = ((fakeng / input) * 100).toFixed(1);
          }
          return { input, ngRate, fakeng, fn_ngRate };
        };

        const item = computed(() => {
          return prTableData.data.map(d => {
            const { input, ngRate, fakeng, fn_ngRate } = calculateInputAndNgRate(d.ok, d.ng, d.fn_brokenbracket, d.fn_damagedbusbar, d.fn_visiblecopper, d.fn_damagedscrew);
            return { ...d, input, ngRate, fakeng, fn_ngRate, }; 
          });
        });        

        const qwItem = computed(() => {return qwTableData.data.map(d => {return { ...d,};});});    

       const callSearchData = async () => {
          try {
            const { sendDateData } = await sendDate();
            const tableNames = [dbTableName, dtTableName, drTableName, qwTableName];
            const result = await searchData(tableNames, sendDateData);
            
            prTableData.data = result.prTableData;
            dtTableData.data = result.dtTableData;
            drTableData.data = result.drTableData;
            qwTableData.data = result.qwTableData;
          } catch (error) {
            console.error("Error fetching data:", error.message);
          }
        };  

        //데이터 더하기 위한 Template, shift, model 설정
        const sumsTemplate = {
          target: 0, input: 0, ok: 0, ng: 0, ng_brokenbracket: 0, ng_damagedbusbar: 0, ng_visiblecopper: 0,
          ng_damagedscrew: 0, fn_brokenbracket: 0, fn_damagedbusbar: 0, fn_visiblecopper: 0, fn_damagedscrew: 0
        };

        const shiftKeys = [1, 2]; 
        const modelKeys = [""];

        const sums = computed(() => calculateSums(item.value, sumsTemplate, shiftKeys, modelKeys, qwItem.value,));
        //끝
    
        const fnTotal = computed(() => {
          return (
            sums.value.prtotalSums.fn_brokenbracket + sums.value.prtotalSums.fn_damagedbusbar +
            sums.value.prtotalSums.fn_visiblecopper + sums.value.prtotalSums.fn_damagedscrew
          );
        });

        const shift1FnTotal = computed(() => {
          return (
            sums.value.shiftSums[1].fn_brokenbracket + sums.value.shiftSums[1].fn_damagedbusbar +
            sums.value.shiftSums[1].fn_visiblecopper + sums.value.shiftSums[1].fn_damagedscrew
          );
        });

        const shift2FnTotal = computed(() => {
          return (
            sums.value.shiftSums[2].fn_brokenbracket + sums.value.shiftSums[2].fn_damagedbusbar +
            sums.value.shiftSums[2].fn_visiblecopper + sums.value.shiftSums[2].fn_damagedscrew
          );
        });

        return { calculateInputAndNgRate, headers, showModal, dtHeaders, dtTableData, 
                 showdtModal,  item, dbTableName, prTableData, searchData, searchDate, lineName, 
                 showdrModal, drHeaders, drTableData, sums, callSearchData, downloadExcel,
                 showqwModal, qwTableData, qwItem, dtTableName, drTableName, qwTableName, sendDate, fnTotal, shift1FnTotal, shift2FnTotal,
                 showrdModal,
                 };        
    },
}
</script>

<style scoped src="../../0_formStyle/line/style_line_pr.css"></style>