<template>
  <div class="login-form">   
    <h1 class="title">AI TRADE</h1>   
    <label for="loginId">
      <span>ID</span>
      <input type="text" id="loginId" v-model="state.form.loginId"/>
    </label>
    <label for="loginPw">
      <span>Pass word</span>
      <input type="password" id="loginPw" v-model="state.form.loginPw"/>
    </label>
    <hr />
    <div class="login-button-container">
      <button @click="submit()" class="login">Log In</button>
      <button @click="openJoinModal()" class="join">Sign Up</button>
    </div>
    <h1 class="caution">CAUTION</h1>
    <h1 class="legal-statement">
      Unauthorized access, viewing, or use of this website by individuals not authorized by KWON.YS is strictly prohibited and may result in legal consequences. By logging in, you acknowledge and accept this responsibility.
    </h1>
    <JoinModal v-if="showJoinModal" @close="showJoinModal = false" />
  </div>
</template>

<script>
import { setupLoginForm } from './login_form.js';
import JoinModal from './joinModal.vue';

export default {
  components: {
    JoinModal
  },
  setup() {
    return setupLoginForm();
  },
};
</script>

<style scoped>
.login-form {
  width: 300px;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  
}


label {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 10px;
}

span {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin-bottom: 5px;
}

input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;  
}


input:focus {
  border-color: #5b9dd9;
}


button {
  width: 100%;
  padding: 10px;
  background-color: #5b9dd9;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;    
}

button:hover {
  background-color: #4290c4;
}

button:disabled {
  background-color: #d1d1d1;
  cursor: not-allowed;
}

button.join {
  background-color: #4CAF50;
  margin-top: 10px;
}

button.join:hover {
  background-color: #45a049;
}

hr {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #ccc;
}

.title {
  font-size: 22px;
  font-weight: bold;
  color: #2680f5;
  text-align: left;
  margin-left: 5px;
  margin-top: 20px;    
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.legal-statement {
  font-size: 14px;
  font-weight: bold;
  color: #333;  
  padding: 0 5px;  
}

.caution {
  font-size: 22px;
  font-weight: bold;
  color: #ff0202;
  text-align: left;
  margin-left: 5px;
  margin-top: 20px;    
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}
</style>
