import axios from 'axios';
import { reactive, ref, computed } from 'vue';

export function setupForm(props, emit) {
  const saveStatus = ref(''); 
  const isProcessing = ref(false);  
  const form = reactive({ 
    name: '', 
    phonenumber: '', 
    emailaddress: '', 
    loginid: '', 
    loginPw: '',
    verifyCode: '',
    verifyPw: '',    
    department: '',
    position: ''
  });
  const errors = reactive({ phonenumber: '', emailaddress: '', loginid: '', loginPw: '', verifyPw: '' });
  const isPhoneNumberValid = ref(false);
  const isEmailAddressValid = ref(false);
  const isLoginIdValid = ref(false);
  const isPasswordValid = ref(false);
  const showVerification = ref(false);
  const verificationStatus = ref('');
  const verificationTimeout = ref(null);
  const expectedCode = ref('');
  const showPassword = ref(false);
  const isPasswordMatch = ref(false);

  const canSave = computed(() => {
    return form.name && form.loginPw && 
           isPhoneNumberValid.value && 
           isEmailAddressValid.value && 
           verificationStatus.value === '인증 완료' && 
           isLoginIdValid.value && 
           isPasswordValid.value && 
           isPasswordMatch.value;
  });

  const submit = async () => {
    if (!form.name || !form.phonenumber || !form.emailaddress || !form.loginid || !form.loginPw) {
      alert("Please fill in All data");
      return;
    }

    isProcessing.value = true; 
    saveStatus.value = 'Processing...';

    const formData =
    { 
      name: form.name,
      phonenumber: form.phonenumber,
      emailaddress: form.emailaddress,
      loginid: form.loginid,
      loginpw: form.loginPw,      
      department: form.department,
      position: form.position,
    }

    try {
      await axios.post('/api/postMembers', formData);
      saveStatus.value = 'Completed';
    } catch (error) {
      console.error('Error during submission:', error);
      saveStatus.value = 'Error'; 
    } finally {
      isProcessing.value = false; 
    }         
    closeModal();
  };

  const validatePhoneNumber = () => {
    const phoneRegex = /^[0-9]*$/;
    if (!phoneRegex.test(form.phonenumber)) {
      errors.phonenumber = '숫자만 입력 가능합니다.';
      isPhoneNumberValid.value = false;
    } else {
      errors.phonenumber = '';
      isPhoneNumberValid.value = true;
    }
  };

  const validateEmailAddress = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(form.emailaddress)) {
      errors.emailaddress = '이메일 형식이 아닙니다.';
      isEmailAddressValid.value = false;
    } else {
      errors.emailaddress = '';
      isEmailAddressValid.value = true;
    }
  };

  const validateLoginId = () => {
    const loginIdRegex = /^[a-z0-9]*$/;
    if (!loginIdRegex.test(form.loginid)) {
      errors.loginid = '소문자 영문자와 숫자만 입력 가능합니다.';
      isLoginIdValid.value = false;
    } else {
      errors.loginid = '';
      isLoginIdValid.value = true;
    }
  };

  const validatePassword = () => {
    isPasswordValid.value = true;        
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(form.loginPw)) {
      errors.loginPw = '8자리 이상, 영문/숫자/특수 문자가 최소 1개씩 포함되어야 합니다.';
      isPasswordValid.value = false;
    } else {
      errors.loginPw = '';
      isPasswordValid.value = true;
    }       
  };

  const checkDuplicate = async (field) => {      
    if (!form[field]) {
      errors[field] = '값을 입력해주세요.';
      return;
    }        
    try {
      const response = await axios.post('/api/checkDuplicate', { field, value: form[field] });
      if (response.data.exists) {
        form[field] = '';
        errors[field] = '사용중인 값입니다.';
        if (field === 'phonenumber') isPhoneNumberValid.value = false;
        if (field === 'emailaddress') isEmailAddressValid.value = false;
        if (field === 'loginid') isLoginIdValid.value = false;
      } else {
        errors[field] = '사용 가능 합니다.';
        if (field === 'phonenumber') isPhoneNumberValid.value = true;
        if (field === 'emailaddress') isEmailAddressValid.value = true;
        if (field === 'loginid') isLoginIdValid.value = true;
      }
    } catch (error) {
      console.error('Error during duplicate check:', error);
    }
  };

  const sendVerificationEmail = async () => {
    try {
      const response = await axios.post('/api/emailSend', {
        to: form.emailaddress,
        subject: "Verify your email address",          
      });
      
      if (response.data.code) {
        expectedCode.value = response.data.code;
        showVerification.value = true;
        verificationStatus.value = '';
        
        // Reset any existing timeout
        if (verificationTimeout.value) {
          clearTimeout(verificationTimeout.value);
        }
        
        // Set 5 minute timeout
        verificationTimeout.value = setTimeout(() => {
          if (!verificationStatus.value.includes('인증 완료')) {
            verificationStatus.value = '시간 초과';
            expectedCode.value = '';
          }
        }, 5 * 60 * 1000);
      }
    } catch (error) {
      console.error('Error sending verification email:', error);
    }
  };

  const verifyCode = () => {
    if (!expectedCode.value) {
      verificationStatus.value = '시�� 초과';
      return;
    }
    
    if (form.verifyCode === expectedCode.value) {
      verificationStatus.value = '인증 완료';
      clearTimeout(verificationTimeout.value);
    } else {
      verificationStatus.value = '코드 재확인';
    }
  };

  const checkAndSendVerificationEmail = async () => {
    showVerification.value = false;
    await checkDuplicate('emailaddress');
    if (isEmailAddressValid.value) {
      await sendVerificationEmail();
    }
  };

  const togglePasswordVisibility = () => {
    showPassword.value = !showPassword.value;
  };

  const checkPasswordMatch = () => {
    if (form.loginPw !== form.verifyPw) {
      errors.verifyPw = '암호가 일치하지 않습니다.';
      isPasswordMatch.value = false;        
    } else {
      errors.verifyPw = '사용 가능 합니다.';
      isPasswordMatch.value = true;        
    }
  };

  const closeModal = () => { emit("close"); };  

  return { 
    form, 
    submit, 
    closeModal, 
    saveStatus, 
    isProcessing, 
    isPhoneNumberValid,
    checkDuplicate, 
    errors, 
    validatePhoneNumber, 
    validateEmailAddress, 
    validateLoginId,
    validatePassword,
    isEmailAddressValid,
    showVerification,
    verificationStatus,
    sendVerificationEmail,
    verifyCode,
    checkAndSendVerificationEmail,
    canSave,
    isLoginIdValid,
    isPasswordValid,
    togglePasswordVisibility,
    checkPasswordMatch,
    showPassword,
    isPasswordMatch
  };
}
