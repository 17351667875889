<template>
  <div class="target">      
    <hr>
    <div class="parent">
      <table class="searchTable"> 
        <tr>
          <td class="searchTd">
            <div class="addbutton">   
              <label for="file-input" class="custom-file-upload"><b>Choose file</b></label>                
              <input id="file-input" type="file" @change="fileChange" accept=".xlsx, .xls" style="display: none" />
              <span class="fileName">{{ fileName }}</span>   
              <button class="excelbutton" @click="upExcel('target')">Upload</button>
              <button class="excelbutton" @click="downExcel">Down Load</button>  
              <button class="delButton" :class="{ disabledButton: !allDelClick() }" :disabled="!allDelClick()" @click="delAll(targetTableName, { delall: formInputs.delall })">ALL DEL</button>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="table-container">
      <table id="targetTable" class="table">      
        <thead> 
        <th v-for="(header, index) in target_headers" :key="index">{{ header }}</th>                
      </thead>      
     </table>
    </div>
    <div class="table-body">
      <table id="targetTable" class="table"> 
        <tbody>
      <tr v-for="row in targetItem" :key="row.id">     
        <template v-for="(header, index) in target_headers">  
          <td v-if="header === 'Line name'" :key="index" >{{ row.line_name }}</td>
          <td v-if="header === 'Model'" :key="index" @click="editCell(targetTableName, row.id, 'model', $event)">{{ row.model }}</td>
          <td v-if="header === 'Product per Minute'" :key="index" @click="editCell(targetTableName, row.id, 'product', $event)">{{ row.product }}</td>
          <td v-if="header === 'Tact Time'" :key="index" @click="editCell(targetTableName, row.id, 'tacttime', $event)">{{ row.tacttime }}</td>
          <td v-if="header === 'NG Rate'" :key="index" @click="editCell(targetTableName, row.id, 'ngrate', $event)">{{ row.ngrate }}</td>
          <td v-if="header === 'Downtime Rate'" :key="index" @click="editCell(targetTableName, row.id, 'downtimerate', $event)">{{ row.downtimerate }}</td>          
          <td v-if="header === 'Real Tact Time'" :key="index" @click="editCell(targetTableName, row.id, 'real_tacttime', $event)">{{ row.real_tacttime }}</td>
          <td v-if="header === 'Yield'" :key="index"  @click="editCell(targetTableName, row.id, 'yield', $event)">{{ row.yield }}</td>
          <td v-if="header === 'Efficiency'" :key="index" @click="editCell(targetTableName, row.id, 'efficiency', $event)">{{ row.efficiency }}</td>
          <td v-if="header === 'Number of Workers'" :key="index" @click="editCell(targetTableName, row.id, 'numberofworkers', $event)">{{ row.numberofworkers }}</td>          
          <td v-if="header === 'Row No'" :key="index" @click="editCell(targetTableName, row.id, 'row_no', $event)">{{ row.row_no }}</td>          
          <td v-if="header === 'DEL'" :key="index"><button class="delButton" :class="{ disabledButton: !allDelClick(row) }" :disabled="!allDelClick(row)" type="submit" @click="handleDeleteRow(targetTableName, row.id)">DEL</button></td>
        </template>   
      </tr>
    </tbody>     
    </table>
    </div>    
    </div>
  
</template>

<script>
import { reactive, computed, onMounted } from 'vue';
import axios from "axios";
import { onFileChange, uploadExcel, downloadExcel, editCellValue, deleteRow, deleteAll} from "../utils.js"; 

export default {   
    data() { return { excelFile: null, fileName: "", }; }, 

    methods: {
      fileChange(event) { const { file, fileName } = onFileChange(event); this.excelFile = file; this.fileName = fileName; },
      upExcel(tableName) { uploadExcel(tableName, this.excelFile, alert); },  
      downExcel() { downloadExcel(this.targetItem, "target.xlsx"); },
      editCell(tableName, id, header, event) {
        const currentValue = event.target.innerText;
        const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);
        if (newValue !== null && newValue !== currentValue) {
           editCellValue(tableName, id, header, newValue, this.callSearchData);
        }
      },  
      handleDeleteRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData); },
      allDelClick() {  
          const currentUser = this.$route.meta.currentUser;          
          if (currentUser) {
              return currentUser.id === 1 || currentUser.id === 2 || currentUser.id === 3;
          } else { return false; }
      },  
      delAll(tableName, filters) { deleteAll(tableName, filters, this.callSearchData); },
    },      
    setup() {   
        const targetTableName = "target";            
        const target_headers = ['Line name', 'Model', 'Product per Minute', 'Tact Time', 'NG Rate', 'Downtime Rate', 'Real Tact Time', 'Yield', 'Efficiency', 'Number of Workers', 'Row No', 'DEL'];
        const targetList = reactive({ data: [], })
        const formInputs = reactive({ delall: 'ALL_DEL', })

        onMounted(() => { searchData(); });   
       
        const callSearchData = () => { searchData();};

        const searchData = async () => {    
          const formData = { tableName: targetTableName,};

          axios.get('/api/targetList', { params: formData })
            .then((res) => {              
              targetList.data = res.data.sort((a, b) => a.row_no - b.row_no); 
            })
            .catch((error) => {
              console.error("Error fetching target data:", error.message);
            });
          };

        const targetItem = computed(() => { return targetList.data.map(d => { return { ...d, }; }); });

        return { targetItem, targetList, target_headers, targetTableName, callSearchData, formInputs};        
    }
  }
</script>

<style scoped>
  .parent { display: flex; justify-content: space-between; }
  .searchTable{margin-left: 10px; margin-top: 10px;}
  .searchTd { border: 0px !important;}   
  .table-container { overflow-x: hidden; position: relative;}

  .table-header,.table-body { overflow: hidden; width: 100%; }

  .table-body { max-height: 350px; overflow-y: auto; }

  .table { width: 100%; max-width: 100%; margin-bottom: 1rem; border-collapse: collapse; table-layout: fixed; }

  .table th,.table td { padding: 0.75rem; vertical-align: top; border-top: 1px solid #dee2e6; text-align: center;}
  .table thead th {
    position: sticky; top: 0; z-index: 100; background-color: #f8f9fa; font-weight: 600; text-transform: uppercase;
    letter-spacing: 1px; font-size: 0.9rem; vertical-align: bottom; border-bottom: 2px solid #dee2e6;
  }

  .table tbody + tbody { border-top: 2px solid #dee2e6;}

  .table-hover tbody tr:hover { background-color: rgba(0, 0, 0, 0.075);}
  
  .target {  font-family: Arial, Helvetica, sans-serif; }

  .target .table { border: 1px solid #dee2e6; border-radius: 0.25rem; overflow: hidden;  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); }

  .target .table td { text-align: center; }

  .target .table tr:nth-child(odd) { background-color: #f8f9fa; }

  .target .table tr:hover { background-color: #e9ecef;  cursor: pointer; }
  .target hr {
    border: 0;  height: 1px; margin-bottom: 1rem;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.1));    
  }

  .addButton { padding: 5px 10px; background-color: #3498db; color: #fff; border: none;
               border-radius: 4px; font-size: 14px; font-weight: 600; margin-right: 30px; margin-left: 30px;
               margin-bottom: 10px; cursor: pointer; transition: background-color 0.3s;
  }
  .custom-file-upload {
    display: inline-block;  padding: 4px 12px;  cursor: pointer;
    background-color: #c49105;  color: #ffffff; margin-left: 10px;
    border-radius: 4px; font-size: 14px;  transition: background-color 0.3s;
  }
  .custom-file-upload:hover { background-color: #f3a359; }
  .excelbutton {
    background-color: #347003;  color: white;
    border: none; border-radius: 4px; cursor: pointer;  font-weight: bold; margin-right : 10px;
    padding: 0.1rem 0.5rem; transition: background-color 0.3s; margin-left : 10px; margin-bottom : 10px;
  }
  .delButton { padding: 5px 5px; background-color: #c8c8c8; color: #000000; border: none; border-radius: 4px; font-size: 14px;
    font-weight: 600; margin-left: 30px; cursor: pointer; transition: background-color 0.3s;
  }
</style>