<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">        
        <div class="modal-header">
          <h3>Type Data</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>        
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <table class="itemTable">              
              <tr>
                <td class="titleTd">Main Category:</td>
                <td colspan="4">
                  <input class="readOnly" type="text" v-model="typeInputs.maincategory" readonly />
                </td>
              </tr>              
              <tr>
                <td class="titleTd" rowspan="2">Use Sub Type</td>
                <td style="width: 80px;"><input type="radio" id="disable" value="false" v-model="useSubType" /></td>
                <td style="padding: 10px 0px; font-weight: bold">Disable</td>
              </tr>
              <tr>
                <td><input type="radio" id="enable" value="true" v-model="useSubType" /></td>
                <td style="padding: 10px 0px; font-weight: bold">Enable</td>
              </tr>       
            </table>       
            <table class="itemTable">     
              <tr>
                <td class="titleTd">Add Type <button type="button" class="addLineButton" :disabled="!useSubType" @click="addType">+</button></td>
              </tr>              
              <tr v-for="(type, typeIndex) in typeInputs.types" :key="typeIndex">
                <td class="titleTd">                  
                  Type {{ typeIndex + 1 }} Name:
                  <input class="searchInput" type="text" v-model="type.typeName" placeholder="Enter type name" />                  
                  <template v-if="useSubType === 'true'">
                    <button type="button" class="addLineButton" 
                      :disabled="!type.typeName" 
                      @click="openSubTypeModal(typeIndex)">Input Sub Type</button>
                  </template>
                </td>
                <td>                  
                  <template v-if="useSubType === 'false'">
                    <div>                      
                      <input class="searchInput" type="text" v-model="type.materialCodes[0].macode" placeholder="Material Code 1" />
                      <input class="searchInput" type="number" v-model="type.materialCodes[0].maqty" placeholder="Quantity 1" />
                      <button type="button" class="addLineButton" @click="addMaterialCode(typeIndex)">+</button>
                    </div>
                    <div v-for="(code, codeIndex) in type.materialCodes.slice(1)" :key="codeIndex">                      
                      <input class="searchInput" type="text" v-model="type.materialCodes[codeIndex + 1].macode" :placeholder="'Material Code ' + (codeIndex + 2)" />
                      <input class="searchInput" type="number" v-model="type.materialCodes[codeIndex + 1].maqty" :placeholder="'Quantity ' + (codeIndex + 2)" />
                      <button type="button" class="addLineButton" @click="addMaterialCode(typeIndex)">+</button>
                    </div>
                  </template>                  
                  <template v-else>
                    <div v-if="Object.keys(type.subTypes).length">
                      <strong>Sub Types:</strong>
                      <pre>{{ type.subTypes }}</pre>
                    </div>
                  </template>
                </td>
              </tr>
            </table>
            <button class="submitButton" type="submit" :disabled="isProcessing">Save</button>
          </form>
        </div>
      </div>
    </div>    
    <sub-type-modal 
      v-if="showSubTypeModal" :mainType="activeTypeName" @onSubmit="handleSubTypeSubmit" @onClose="closeSubTypeModal"/>
  </div>
</template>

<script>
import { reactive, ref, watch, computed } from 'vue';
import SubTypeModal from './subTypeModal.vue';

export default {
  name: 'TypeModal',
  components: { SubTypeModal },
  props: { mainCategory: { type: String, required: true, default: '' }},
  emits: ['onSubmit', 'onClose'],
  setup(props, { emit }) {    
    const typeInputs = reactive({ maincategory: props.mainCategory, types: [] });
    const useSubType = ref('');
    const isProcessing = ref(false);
    
    const showSubTypeModal = ref(false);
    const activeTypeIndex = ref(null);
    const activeTypeName = computed(() => {
      if (activeTypeIndex.value !== null) { return typeInputs.types[activeTypeIndex.value].typeName; } return '';
    });
    
    const addType = () => {
      if (useSubType.value === 'false') { typeInputs.types.push({ typeName: '', materialCodes: [ { macode: '', maqty: '' } ]  });}
      else { typeInputs.types.push({ typeName: '', subTypes: {} }); }
    };

    const addMaterialCode = (typeIndex) => {
      if (useSubType.value === 'false') { typeInputs.types[typeIndex].materialCodes.push({ macode: '', maqty: '' });}
    };
    
    const openSubTypeModal = (typeIndex) => {
      if (!typeInputs.types[typeIndex].typeName) return;
      activeTypeIndex.value = typeIndex;
      showSubTypeModal.value = true;
    };
    
    const handleSubTypeSubmit = (subTypeData) => {
      if (activeTypeIndex.value !== null) {
        typeInputs.types[activeTypeIndex.value].subTypes = subTypeData;
      }
      showSubTypeModal.value = false;
      activeTypeIndex.value = null;
    };

    const closeSubTypeModal = () => {
      showSubTypeModal.value = false;
      activeTypeIndex.value = null;
    };
    
    watch(() => props.mainCategory, (newVal) => { typeInputs.maincategory = newVal; });
    
    const submitForm = () => {
      let result = {};
      if (useSubType.value === 'false') {        
        typeInputs.types.forEach(type => {
          result[type.typeName] = {
            code: '',
            quantity: 0,
            material: type.materialCodes.map(codeObj => ({
              macode: codeObj.macode,
              maqty: codeObj.maqty
            }))
          };
        });
      } else {        
        typeInputs.types.forEach(type => {
          result[type.typeName] = {
            code: '',
            quantity: 0,
            ...type.subTypes
          };
        });
      }         
      emit('onSubmit', result);
    };

    const closeModal = () => { emit('onClose'); };

    return {
      typeInputs, useSubType, isProcessing, addType, addMaterialCode, submitForm, closeModal,
      showSubTypeModal, openSubTypeModal, handleSubTypeSubmit, closeSubTypeModal, activeTypeName
    };
  }
};
</script>

<style scoped>
@import '../../generalStyle.css';
.modal-wrapper { max-width: 800px;}
</style>
