<template>
  <div class="modal-mask">
  <div class="modal-wrapper">
  <div class="modal-container">
  <div class="modal-header">
    <h3>BOM Add and Change</h3>
    <button class="close-button" @click="closeModal">X</button>
  </div>
  <div class="modal-body">
    <form @submit.prevent="submitForm">
      <table>
        <tr>
          <td style="font-size:15px; font-weight: 600;">Product Information</td>
          <td style="font-size:15px; font-weight: 600; padding: 0px 10px;">Add BOM</td>
        </tr>
        <tr style="vertical-align: top;">   
          <td>
            <table class="itemTable">        
              <tr>
                <td class="titleTd">Type</td>
                <td><input class="readOnly" type="text" v-model="materialInputs.type" readOnly/></td>
                <td class="titleTd">Code</td>
                <td><input class="readOnly" type="text" v-model="materialInputs.code" readOnly/></td>
              </tr>
              <tr>
                <td class="titleTd">Name</td>
                <td><input class="readOnly" type="text" v-model="materialInputs.name" readOnly/></td>
                <td class="titleTd">Unit</td>
                <td><input class="readOnly" type="text" v-model="materialInputs.unit" readOnly/></td>          
              </tr>
            </table>

            <table class="itemTable" style="text-align: center;">
              <tr>
                <td class="titleTd">BOM No</td>
                <td class="titleTd">Code</td>
                <td class="titleTd">Name</td>
                <td class="titleTd">QTY</td>
                <td class="titleTd">EA</td>                
                <td class="titleTd">DEL</td>          
              </tr>     
              <tr v-for="row in item" :key="row.id">
                <td>{{ row.bomno }}</td>
                <td>{{ row.code }}</td>
                <td>{{ row.name }}</td>
                <td>{{ row.quantity }}</td>
                <td>{{ row.unit }}</td>
                <td><button class="delButton" style="font-size: 10px" type="button" @click="handleDeleteRow(dbTableName, row.id, $event)">DEL</button></td>
              </tr>   
            </table>     
          </td>
          <td style="padding: 0px 10px;">
            <table class="itemTable">
              <tr>
                <td class="titleTd">BOM No</td>                  
                <td><input v-model="formInputs.bomno" /></td>      
              </tr>
              <tr>
                <td class="titleTd">Code</td>                  
                <td><input v-model="formInputs.code" /></td>      
              </tr>
              <tr>
                <td class="titleTd">Name</td>                  
                <td><input class="readOnly" v-model="formInputs.name" readOnly/></td>      
              </tr>
              <tr>
                <td class="titleTd">QTY</td>                  
                <td><input v-model="formInputs.quantity" /></td>     
              </tr>
              <tr>
                <td class="titleTd">Unit</td>                  
                <td><input class="readOnly" v-model="formInputs.unit" readOnly/></td>      
              </tr>
            </table> 
            <button class="submitButton" type="submit" :class="{ 'readOnly': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}      
          </td>
        </tr>
      </table>
      
      </form>
    </div>        
  </div>      
  </div>
  </div>
</template>
  
<script>    
import { reactive, ref, computed, watch } from 'vue'; 
import axios from 'axios';   
import { deleteRow, getColumnMapping, makeFormData, useFormInputs } from '../utils.js';

export default {                
  props: {
    //onSubmit: { type: Function, required: true },
    code: { type: [String, Number], required: false },
    type: { type: [String, Number], required: false },
    name: { type: [String, Number], required: false },
    unit: { type: [String, Number], required: false },
  },
  methods: {    
    handleDeleteRow(tableName, rowId, event) {
      event.stopPropagation();
      deleteRow(tableName, rowId, this.callSearchData);
    },            
  },     
  setup(props, { emit }) {  
      const saveStatus = ref(''); 
      const isProcessing = ref(false);   
      const dbTableName = "bomlist";
      const materialDbTableName = "materiallist";              
      const bomList = reactive({ data: [], });
      const materialList = reactive({ data: [], });

      const materialInputs = reactive({
        code: props.code,
        name: props.name,
        type: props.type,
        unit: props.unit,
      });           
      
      const { formInputs, dataForm } = useFormInputs({targetcode: ""});        

      const submitForm = async () => {
        
        isProcessing.value = true; 
        saveStatus.value = 'Processing...';                 
        formInputs.targetcode = props.code;          

        const columnMapping = getColumnMapping(undefined, formInputs);            
        const formData = makeFormData(undefined, formInputs, dbTableName, columnMapping); 
            
        try {
          await axios.post('/api/post', formData);
          saveStatus.value = 'Completed'; 
        } catch (error) {
          console.error('Error during submission:', error);
          saveStatus.value = 'Error';
        } finally {
          isProcessing.value = false; 
        }     
        getBomData();              
      };        

      const getMaterialData = async () => {    
        const formData = { tableName: materialDbTableName};
        axios.get('/api/targetList', { params: formData })
          .then((res) => { materialList.data = res.data; })
          .catch((error) => { console.error("Error fetching target data:", error.message); });
      }; getMaterialData();        

      const getBomData = async () => {              
        const formData = {        
          tableName: dbTableName,                
          targetcode: props.code,            
        };        

        axios.get('/api/filteredSearch', { params: formData, })                      
          .then((res) => { 
            bomList.data = res.data.sort((a, b) => {
              if (a.bomno === b.bomno) {
                return a.code - b.code;
              }
              return a.bomno - b.bomno;
            });
          });  
      }; getBomData();          

      const closeModal = () => {  emit("onClose");  };            

      const item = computed(() => { return bomList.data.map(d => { return { ...d, }; }); });      

      watch(
        () => formInputs.code,
        (newCode) => {
          const matchedItem = materialList.data.find(item => item.code === Number(newCode));
          if (matchedItem) {              
            formInputs.name = matchedItem.name;
            formInputs.unit = matchedItem.unit;
          }
        }
      );

      const callSearchData = () => { getBomData(); };   
        
      return { dbTableName, formInputs, submitForm, closeModal, materialInputs, item, materialList,
              saveStatus, isProcessing, dataForm, callSearchData
      }; 
  },     
};
  
</script>
    
<style scoped>
@import '../generalStyle.css';

.modal-wrapper {
  max-width: 1000px; /* Override max-width for bomModal.vue */
}
</style>