<template>
  <div class="container">
    <div class="search">
      <form @submit.prevent="sendDate()">
        <table class="searchTable">
          <tr>
            <td class="start">
              <input type="number" id="year" v-model="searchDate.year" />
              <input type="number" id="month" v-model="searchDate.month" />
              <input type="number" id="day" v-model="searchDate.day" />
              <select class="withouteop" v-model="formInputs.use">
                <option>Without EOP</option><option>All</option>
              </select>
            </td>
            <td class="searchTd">
              <button type="submit" @click="callSearchData">Search</button>
            </td>
            <td class="searchTd">
              <button class="button" @click="showModal = true">Comment</button>
            </td>
          </tr>
        </table>
      </form>
    </div>    
    <table>
      <tr>
        <th colspan="1"></th>
        <th colspan="3" class="okhead">OK</th>
        <th colspan="4" class="nghead">NG</th>
        <th colspan="3" class="tacttimehead">Tact Time (sec)</th>
        <th colspan="3" class="downtimehead">Down Time (min)</th>
        <th colspan="4" class="oeehead">OEE</th>
      </tr>
      <tr><th v-for="(header, index) in headers" :key="index">{{ header }}</th></tr>
      <tr v-for="(target, index) in filteredTargets" :key="target.name">
        <td><b>{{ target.name }}</b></td>
        <td>{{ rowData[index].okTarget }}</td>
        <td class="okhead"><b>{{ rowData[index].ok }}</b></td>
        <td class="okhead" :style="aS(rowData[index].achieve / 100)"><b>{{ rowData[index].achieve }}%</b></td>
        <td>{{ rowData[index].ngTarget }}</td>
        <td>{{ rowData[index].ng }}</td>
        <td class="nghead"><b>{{ rowData[index].ngRate }}%</b></td>
        <td class="nghead" :style="aS(rowData[index].achieveNG / 100)"><b>{{ rowData[index].achieveNG }}%</b></td>
        <td>{{ rowData[index].tactTimeTarget }}</td>
        <td class="tacttimehead"><b>{{ rowData[index].actualTactTime }}</b></td>
        <td class="tacttimehead" :style="aS(rowData[index].achieveTactTime / 100)"><b>{{ rowData[index].achieveTactTime }}%</b></td>
        <td>{{ (rowData[index].downTimeTarget).toFixed(0) }}</td>
        <td class="downtimehead"><b>{{ rowData[index].downTime }}</b></td>
        <td class="downtimehead" :style="aS(rowData[index].achieveDownTime / 100)"><b>{{ rowData[index].achieveDownTime }}%</b></td>
        <td>{{ rowData[index].oeeAvailable }}%</td>
        <td>{{ rowData[index].oeeEfficiency }}%</td>
        <td>{{ rowData[index].oeeQuality }}%</td>
        <td class="oeehead" :style="aS(rowData[index].oee / 100)"><b>{{ rowData[index].oee }}%</b></td>
        <td>{{ rowData[index].workMinute }}</td>
        <td class="comment">{{ rowData[index].problem }}</td>
        <td class="comment">{{ rowData[index].countermeasure }}</td>
        <td>{{ rowData[index].commentID }}</td>
      </tr>
    </table>
    <Modal v-if="showModal" @onClose="showModal = false; callSearchData();" />
  </div>
</template>

<script>
import { computed, reactive, ref } from 'vue';
import Modal from './reportTwo_modal.vue';
import { createReportUtils, settingValue } from './reportUtils';
import { withoutExceptTargets, tableNames, withoutEOPLines } from '../../lineData';

export default {
  components: { Modal },
  setup() {
    const showModal = ref(false); 
    const searchDate = ref({ year: '', month: '', day: '', });  
    const formInputs = reactive({ use: 'Without EOP', });  
    
    const { state, fetchAllData, fetchTargets, fetchComments, calculateRowData, sums } = createReportUtils(tableNames);

    const headers = ['Name', 'OK Target', 'OK', 'Achieve', 'NG Target', 'NG', 'NG Rate', 'Achieve NG', 'Tact Time Target',
     'Actual Tact Time', 'Achieve Tact Time', 'Down Time Target', 'Down Time', 'Achieve Down Time',
     'OEE Available', 'OEE Efficiency', 'OEE Quality', 'OEE', 'Work Minute', 'Problem', 'Countermeasure', 'Comment ID'
    ];    

    const filteredTargets = computed(() => {
      if (formInputs.use === 'Without EOP') {
        return withoutExceptTargets.filter(target => withoutEOPLines && withoutEOPLines.includes(target.name));
      }
      return withoutExceptTargets;
    });

   const rowData = computed(() => filteredTargets.value.map(target => calculateRowData(target.name, target, targetItem.value)));

   const sendDate = async () => {
    const date = { year: searchDate.value.year, month: searchDate.value.month, day: searchDate.value.day };
    return date;
  };

    const settingData = reactive({ data: [] });  
    (async () => {
      try {
        const { settingData: setting } = await settingValue();
        settingData.data = setting.data;
      } catch (error) {
        console.error("Error initializing settings:", error.message);
      }
    })();

    const callSearchData = async () => {    

      try {          
        const reportSetting = settingData.data.find(s => Object.hasOwn(s, 'report'));  
        const isReportEnabled = reportSetting && reportSetting.report === 1;
        if (!isReportEnabled) {
          alert('Report is currently disabled.');
          return;
        }
        
        const date = await sendDate();
        await fetchTargets();
        await fetchComments(date);
        await fetchAllData(date);
      }
      catch (error) {
        console.error("Report is currently disabled:", error.message);
      }
    };

    const aS = (value) => {
      if (isNaN(value) || value === 0) {
        return { backgroundColor: 'transparent' }; // Transparent color
      } else if (value < 0.8499) {
        return { backgroundColor: 'rgba(255, 0, 0, 0.7)' }; // Light red
      } else if (value < 0.9499) {
        return { backgroundColor: 'rgba(255, 255, 0, 0.3)' }; // Light yellow
      } else {
        return { backgroundColor: 'rgba(144, 238, 144, 0.3)' }; // Light green
      }
    }; 
    
    const targetItem = computed(() => {
      const items = {};
      state.targetList.forEach(d => {
        items[d.line_name] = d;
      });
      return items;
    });


    return {
      headers, state, withoutExceptTargets, rowData, sendDate, callSearchData, aS, formInputs,
      showModal, searchDate, sums, targetItem, filteredTargets
    };
  }
};
</script>

<style scoped>
   * {
    box-sizing: border-box;
  }

  .container {
    margin-top: 0px;
    padding-top: 0px;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  .search {
  position: relative;
  background-color: #ffffff; /* Set a background color for better visibility */  
  padding: 0px; /* Add some padding for better appearance */
  height: auto;
  width: 500px;
}
  .start,
  .end {
    display: flex;
    flex-direction: row;    
    border: 0px;
  }

  .start input,
  .end input {
    margin-right: 5px;
  }

  .separator {
    font-size: 24px;
    margin: 0 10px;
    border: 0px;
  }
  .searchTd {    
    border: 0px;
  }

  input::placeholder {
    color: gray;
  }
  label {
    font-size: 14px;
    font-weight: 600;
    margin-right: 8px;
  }
  
  input[type="number"],
  input[type="text"] {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100px;    
  }
  .withouteop {  padding: 8px; border: 1px solid #ccc;  border-radius: 4px; font-size: 14px; width: 150px;}

  button {
    padding: 5px 5px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  table {
    width: auto;
    border-collapse: collapse;
    margin-bottom: 30px;
  }

  .comment {
    min-width: 300px;
    max-width: 600px;
    white-space: pre-wrap; /* Allows text to wrap to the next line */
    word-wrap: break-word;
    text-align: left;
  }

  th {
    position: sticky;
    top: 0;    
    z-index: 10;
    background-color: rgb(230, 230, 230) ;
    border : 1px solid black;        
    text-align : center;        
    padding-left : 10px;
    padding-right : 10px;
    font-size : 12px; 
    font-weight: 600;
}

  td {
    border: 1px solid #ccc;
    text-align: center;
    padding: 8px;
    font-size: 12px;    
    width: auto;
  }  

  tr:nth-child(even) {
    background-color: #f8f8f8;
  }

  tr:hover {
    background-color: #e6e6e6;
  }
  .ok {
  background-color: #94b7f8; /* Adjust the color as needed */
  font-weight: bold;
}
.ng {
  background-color: #fa7979; /* Adjust the color as needed */
  font-weight: bold;
}
.ng_type {
  background-color: #fdb08d; /* Adjust the color as needed */
}

.okhead {
  background-color: #8dc7fd; /* Adjust the color as needed */
}
.nghead {
  background-color: #fdb08d; /* Adjust the color as needed */
} 
.tacttimehead {
  background-color: #fac069; /* Adjust the color as needed */
} 
.downtimehead {
  background-color: #569e56; /* Adjust the color as needed */
} 
.oeehead {
  background-color: #bbbd4a; /* Adjust the color as needed */
} 
</style>
