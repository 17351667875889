<template>
  <div class="searchPart">          
    <h3 class="pagetitle">Authority Setting</h3>        
      <form @submit.prevent="searchData()">
        <div>                        
          <input class="word" type="text" id="word" v-model="name" placeholder="Search Name" />
          <b>Department:</b> <select v-model="department">
            <option v-for="department in uniqueDepartment" :key="department">{{ department }}</option>    
            <option></option>    
          </select>
          <b>Position:</b> <select  v-model="position">
            <option v-for="position in uniquePosition" :key="position">{{ position }}</option>    
            <option></option>    
          </select>
          <button type="submit" class="searchButton" @click="callSearchData">Search</button>
        </div>                                        
      </form>  
  </div>

  <div class="table-container">
    <table id="targetTable" class="table">      
      <tr> 
        <th>ID</th><th>Name</th><th>Department</th><th>Position</th><th>Phonenumber</th><th>Email Address</th><th>Authority</th><th>DEL</th>
      </tr>
      <tr v-for="row in Item" :key="row.id" >        
        <td @click="editCell(dbTableName, row.id, 'loginid', $event)">{{ row.loginid }}</td>
        <td @click="editCell(dbTableName, row.id, 'name', $event)">{{ row.name }}</td>
        <td @click="editCell(dbTableName, row.id, 'department', $event)">{{ row.department }}</td>      
        <td @click="editCell(dbTableName, row.id, 'position', $event)">{{ row.position }}</td>
        <td @click="editCell(dbTableName, row.id, 'phonenumber', $event)">{{ row.phonenumber }}</td>
        <td @click="editCell(dbTableName, row.id, 'emailaddress', $event)">{{ row.emailaddress }}</td>
        <td @click="editCell(dbTableName, row.id, 'auth', $event)">{{ row.auth }}</td>
        <td><button class="delButton" type="submit" @click="handleDeleteRow(dbTableName, row.id)">DEL</button></td>
    </tr>
  </table>
  </div>            
</template>
  
  <script>
  import { ref, reactive, computed, } from 'vue';
  import axios from 'axios';    
  import { downloadExcel, deleteRow, editCellValue, fetchData } from '../../utils.js'; 
  
  export default {       
      methods: {                        
        editCell(tableName, id, header, event) {
          const currentValue = event.target.innerText;
          const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);
          if (newValue !== null && newValue !== currentValue) {
              editCellValue(tableName, id, header, newValue, this.callSearchData);
          }
        },
        handleDeleteRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData); },
        downExcel() { downloadExcel(this.filteredItems, "membersList.xlsx"); },        
      },     
         
      setup() {                     
          const dbTableName = 'members';                            
          const name = ref();
          const department = ref();
          const position = ref();
          const uniqueDepartment = ref([]);   
          const uniquePosition = ref([]);   
          const listData = reactive({ data: [], })        
  
          const searchData = async () => {              
            const formData = {        
              tableName: dbTableName,                
              name: name.value,
              department: department.value,
              position: position.value,                  
            };  
  
            axios.get('/api/filteredSearch', { params: formData, })                      
                 .then((res) => { listData.data = res.data.sort((a,b) => a.loginid - b.loginid);
            });  
          };
  
          const Item = computed(() => { return listData.data.map(d => { return { ...d, }; }); });  

          const loadDepartment = async () => {
            uniqueDepartment.value = await fetchData(dbTableName, 'department');
            uniqueDepartment.value.sort((a, b) => a.localeCompare(b));
          }; loadDepartment();   
          
          const loadPosition = async () => {
            uniquePosition.value = await fetchData(dbTableName, 'position');
            uniquePosition.value.sort((a, b) => a.localeCompare(b));
          }; loadPosition();              
  
          const callSearchData = () => { searchData(dbTableName); };    
  
          return { Item, dbTableName, callSearchData, searchData,
                   name, department, position, uniquePosition, uniqueDepartment
          };        
      }
    }
  </script>
  
  <style scoped> 
    .table-container { margin-left : 10px; margin-right: 10px;}
    .searchTable{margin-left: 10px; margin-top: 10px;}
    .disabledButton { background-color: gray; cursor: not-allowed; }
    label { font-size: 17px; font-weight: 600; margin-right: 8px; }
    .table { display: table-row; flex-direction: column; align-items: stretch; padding-left : 20px; padding-right : 20px; width: 80%; margin-top: 10px; }
    .table td { border : 1px solid black; text-align : center; padding-left : 10px; padding-right : 10px; font-size : 15px; }
    th { position: sticky; top: 0; z-index: 10; background-color: rgb(230, 230, 230); border : 1px solid black;        
        text-align : center; padding: 10px; font-size : 15px; font-weight: 600;
    }
    .pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 15px;}
    .custom-file-upload { display: inline-block; padding: 6px 12px; cursor: pointer; background-color: #347003; color: #ffffff;
                        border-radius: 4px; font-size: 14px; transition: background-color 0.3s; margin-right : 10px; margin-left : 20px;
    }
    .custom-file-upload:hover { background-color: #009625; }
  
    .addButton { padding: 5px 5px; background-color: #3498db; color: #fff; border: none; border-radius: 4px; font-size: 14px;
            font-weight: 600; margin-right: 20px; cursor: pointer; transition: background-color 0.3s;
    }
    .searchButton { padding: 5px 5px; background-color: #c0c0c0; color: #000000; border: none; border-radius: 4px; font-size: 14px;
        font-weight: 600; margin-right: 30px; cursor: pointer; transition: background-color 0.3s; margin-left: 20px; 
    }
    .moButton { padding: 5px 5px; background-color: #347003; color: #fff; border: none; border-radius: 4px; font-size: 14px;
      font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
    }
    .delButton { padding: 5px 5px; background-color: #c8c8c8; color: #000000; border: none; border-radius: 4px; font-size: 14px;
      font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
    }
    .searchTd { border: 0px !important;}    
    .word { margin: 10px 10px; padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 200px;}
    .maker { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 200px;}
    
    input::placeholder { color: gray; }
    select { margin-left: 10px; margin-right: 10px; padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 100px; }   
    
    .radio { margin-left: 10px; margin-right: 5px; }
    .red-row { background-color: rgb(237, 117, 117);}
    .yellow-row { background-color: rgb(238, 249, 140);}
    .qty { background-color: rgb(247, 227, 124); font-weight: bold }
    .horizontal-form { 
      display: flex; 
      flex-wrap: wrap; 
      gap: 10px; 
      align-items: center; 
    }   
  </style>