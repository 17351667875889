<template>
  <div class="todolist">  
    <div class="RequestLog">
      <h3 class="pagetitle">Invest List</h3>        
      <form @submit.prevent="sendDate()">
        <div class="horizontal-form">        
          <div class="searchTd">
            <input class="yearInput" id="year" v-model="formInputs.year" placeholder="Year"/>                
          </div>               
          <div class="searchTd">
              <button type="submit" class="searchButton" @click="callSearchData">Search</button>                        
          </div>
          <div class="searchTd">                        
              <button class="addButton" :class="{ disabledButton: !canClick() }" :disabled="!canClick()" @click="showModal = true">+ New Invest</button>
          </div>
          <div class="searchTd">
              <button class="custom-file-upload" @click.prevent="downExcel">Down Load</button>     
          </div>
          <div class="searchTd">                        
              <button class="addButton" @click="showCashModal = true">+ Cash Out</button>
          </div>
        </div>      
      </form>  
    </div>          
    <br>
    <div class="table-container">
      <table id="targetTable" class="table">      
        <tr> 
          <th rowspan="2">Category</th><th rowspan="2">Reason</th><th rowspan="2">ID</th><th rowspan="2">Item</th><th rowspan="2">Company</th>
          <th rowspan="2">Department</th><th rowspan="2">Report<br>Date</th><th rowspan="2">PR</th><th rowspan="2">PO</th>
          <th rowspan="2">Asset</th><th colspan="3">Invest Cost</th><th rowspan="2">Offer</th><th rowspan="2">Done Date</th>
          <th colspan="2" @click="toggleCashOutColumns">Total Cost ▶</th>
          <template v-if="colVisible">
            <th v-for="col in dynamicCols" :key="col.key" rowspan="2">{{ col.year }}/{{ col.month }}<br />{{ col.cashunit }}</th>
          </template>
          <th rowspan="2">File</th><th rowspan="2">Modify</th>
          <th rowspan="2">+Sub Item</th><th rowspan="2">DEL</th><th rowspan="2">Remark</th>                              
        </tr>              
        <tr>
          <th>Carry<br>over</th><th>New</th><th>Total</th><th>USD</th><th>MXN</th>
        </tr>      
        <tr v-for="row in Item" :key="row.id" :class="{ 'no-subitem': !row.subitem }">                  
          <td v-if="!row.subitem">{{ row.category }}</td>
          <td v-if="!row.subitem">{{ row.reason }}</td>
          <td v-if="row.subitem" colspan="2"></td>
          <td style="text-align: left;">{{ row.investid }}</td>
          <td style="text-align: left;">
              <span v-if="row.subitem" class="subitem">{{ row.subitem }}</span>
              <span v-else>{{ row.item }}</span>
          </td>
            <td>{{ row.company }}</td>
            <td>{{ row.department }}</td>
            <td>{{ row.reportdate }}</td>
            <td>{{ row.pr }}</td>
            <td>{{ row.po }}</td>
            <td>{{ row.asset }}</td>
            <td :style="row.carryoverprice === 0 ? { color: !row.subitem ? 'rgb(249, 249, 205)' : 'white' } : {}">{{ typeof row.carryoverprice === 'number' ? row.carryoverprice.toFixed(1) : '0' }}</td>            
            <td :style="row.newprice === 0 ? { color: !row.subitem ? 'rgb(249, 249, 205)' : 'white' } : {}">{{ typeof row.newprice === 'number' ? row.newprice.toFixed(1) : '0'}}</td>
            <td :style="row.costTotal === 0 ? { color: !row.subitem ? 'rgb(249, 249, 205)' : 'white' } : {}">{{ typeof row.costTotal === 'number' ? row.costTotal.toFixed(1) : '0' }}</td>
            <td :style="row.offer === 0 ? { color: !row.subitem ? 'rgb(249, 249, 205)' : 'white' } : {}">{{ typeof row.offer === 'number' ? row.offer.toFixed(1) : '0' }}</td>
            <td>{{ row.lastCashOutDate }}</td>  
            <td>{{ typeof row.totalUsd === 'number' ? row.totalUsd.toFixed(1) : '0' }}</td>
            <td>{{ typeof row.totalMxn === 'number' ? row.totalMxn.toFixed(1) : '0' }}</td>   
            <template v-if="colVisible">
              <td v-for="col in dynamicCols" :key="col.key">{{ getCashValue(row, col.key) }}</td>                       
            </template>       
            <td>{{ getFileName(row.file) }}<br>
              <button class="fileDownLoadButton" @click="downloadFile(row.filepath)">Download</button>
            </td>   
            <td><button class="moButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="openSendModal(row)">Modify</button></td>
            <td><button class="subButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="openSubModal(row)">+Sub Item</button></td>
            <td><button class="delButton" :class="{ disabledButton: !canClick(row) }" :disabled="!canClick(row)" type="submit" @click="delRow(row)">DEL</button></td>
            <td>{{ row.remark }}</td>         
            
         </tr>
      </table>         
    </div>
    <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/> 
    <modifyModal  v-if="showMoModal" :row-id="selectedRowId" @onClose="showMoModal = false; callSearchData();"/>                     
    <subModal  v-if="showSubModal" :row-id="selectedRowId" @onClose="showSubModal = false; callSearchData();"/>                     
    <cashModal  v-if="showCashModal" @onClose="showCashModal = false; callSearchData();"/>                     
    </div>  
</template>

<script>
import { reactive, computed, ref } from 'vue';
import axios from "axios";
import Modal from "./investModal.vue";
import modifyModal from "./investMoModal.vue";
import subModal from "./investSubModal.vue";
import cashModal from "./investCashModal.vue";
import { deleteRow, downloadExcel, } from "../utils.js"; 

export default {       
    methods: {
      openSendModal(row) { this.selectedRowId = row.id; this.showMoModal = true; },  
      openSubModal(row) { this.selectedRowId = row.id; this.showSubModal = true; },  
      canClick() {          
        const currentUser = this.$route.meta.currentUser;          
        if (currentUser) { return currentUser.id === 1 || currentUser.id === 2 ||  currentUser.id === 3 ||currentUser.id === 32;}
        else { return false;}
      },    
      downloadFile(filepath) {
        if (!filepath) { alert('No file available for download'); return; }
        window.location.href = filepath;
      },  
      getFileName(filePath) {
        if (!filePath) return ''; const parts = filePath.split('-');
        if (parts.length > 1) { return parts.slice(1).join('-'); }
        return filePath;
      },      
      downExcel() {        
        const itemData = this.Item;
        const allCashRows = this.Item.flatMap(item => item.cashArray || []);
        downloadExcel( [itemData, allCashRows], "InvestList.xlsx", [], ["Invest List", "Cash Out"]);
      },
      delRow(row) {
        if (row.filepath) { alert('First file delete and try again'); }
        else { deleteRow(this.dbTableName, row.id, this.callSearchData); }
      },
    },

    components: { Modal, modifyModal, subModal, cashModal },     

    setup() {                   
        const dbTableName = 'invest';        
        const showModal = ref(false);
        const showSubModal = ref(false);
        const showMoModal = ref(false);     
        const showCashModal = ref(false);     
        const formInputs = reactive({ year: "" });                        
        const investData = reactive({ data: [], })          
        const dynamicCols = ref([]); // investcash(현금) 정보를 저장할 동적 컬럼 목록
        
        const sendDate = async () => {const sendDateData = [formInputs.year]; return { sendDateData };};   
                
        const fetchInvestCash = async () => {        
          const investIds = investData.data.map(d => d.investid);
          if (!investIds.length) return;

          const uniqueIds = [...new Set(investIds)];
          
          axios.get('/api/investcash', {
            params: { investIds: uniqueIds.join(',') }
          }).then(res => {            
            const grouped = {};
            res.data.forEach(row => {              
              const key = `${row.investid}_${row.year}_${row.month}_${row.cashunit}`;
              if (!grouped[key]) {
                grouped[key] = {
                  investid: row.investid,
                  year: row.year,
                  month: row.month,
                  day: row.day,
                  cashunit: row.cashunit,
                  cash: parseFloat(row.cash) || 0
                };
              } else {
                grouped[key].cash += parseFloat(row.cash) || 0;
              }
            });

            const groupedArr = Object.values(grouped);
            
            investData.data.forEach(d => { d.cashArray = groupedArr.filter(g => g.investid === d.investid); });            

            const colSet = new Set();
            groupedArr.forEach(g => {
              const colKey = `${g.year}_${g.month}_${g.cashunit}`;
              colSet.add(colKey);
            });
            
            const colList = Array.from(colSet).map(k => {
              const [year, month, cashunit] = k.split('_');
              return {
                key: k,     
                year,
                month,
                cashunit
              };
            }).sort((a, b) => {              
              if (parseInt(a.year) !== parseInt(b.year)) {
                return parseInt(a.year) - parseInt(b.year);
              }
              return parseInt(a.month) - parseInt(b.month);
            });

            dynamicCols.value = colList;
          });
        };

        const searchData = async () => {   
          const sendDateData = await sendDate();         
          const formData = {        
                tableName: dbTableName,                                
                year: sendDateData.sendDateData[0],
            };  

          console.log('Requesting data with:', formData);

          // invest 테이블 호출
          axios.get('/api/targetList', { params: formData })
            .then((res) => { investData.data = res.data; })
            .then(() => { fetchInvestCash(); }); // invest 데이터 불러온 뒤 investcash 데이터(현금)도 불러와서 합산 처리
        };        
 
        const Item = computed(() => {
          return investData.data
            .map(d => {
              // (a) Invest Cost 계산
              const carryoverprice = parseFloat(d.carryoverprice) || 0;
              const newprice       = parseFloat(d.newprice)       || 0;
              const costTotal      = carryoverprice + newprice;

              // (b) cashArray를 기반으로 마지막 연/월, USD/MXN 합계 구하기
              let lastCashOutDate = "";   // 마지막 (year/month)
              let totalUsd = 0;          // USD 합
              let totalMxn = 0;          // MXN 합

              if (d.cashArray && d.cashArray.length > 0) {
                // 1) 가장 늦은(=가장 큰) year, month, day 찾기
                const sorted = [...d.cashArray].sort((a, b) => {
                  const ay = parseInt(a.year, 10),  by = parseInt(b.year, 10);
                  if (ay !== by) return ay - by;

                  const am = parseInt(a.month, 10), bm = parseInt(b.month, 10);
                  if (am !== bm) return am - bm;

                  // day도 숫자로 비교 (없으면 0으로 처리)
                  const ad = parseInt(a.day, 10) || 0, bd = parseInt(b.day, 10) || 0;
                  return ad - bd;
                });
                const last = sorted[sorted.length - 1];
                // 마지막 연/월
                lastCashOutDate = `${last.year}/${last.month}`;

                // 2) USD, MXN 누적
                d.cashArray.forEach(c => {
                  if (c.cashunit === "USD") {
                    totalUsd += c.cash;
                  } else if (c.cashunit === "MXN") {
                    totalMxn += c.cash;
                  }
                });
              }

              return {
                ...d,
                costTotal,
                lastCashOutDate,
                totalUsd,
                totalMxn,
              };
            })
            .sort((a, b) => {
              // 원하는 순서대로 정렬 (기존 로직 유지)
              if (a.item < b.item) return -1;
              if (a.item > b.item) return 1;
              if (!a.subitem && b.subitem) return -1;
              if (a.subitem && !b.subitem) return 1;
              if (a.investid < b.investid) return -1;
              if (a.investid > b.investid) return 1;
              return 0;
            });
        });    

        const getCashValue = (row, colKey) => {          
          if (!row.cashArray) return '';
          const found = row.cashArray.find( c => `${c.year}_${c.month}_${c.cashunit}` === colKey );
          return found ? found.cash : '';
        };

        const callSearchData = () => { searchData(); };  

        const colVisible = ref(false);        
        const toggleCashOutColumns = () => { colVisible.value = !colVisible.value; };        

        return { Item, deleteRow, dbTableName, callSearchData, showModal, showMoModal, showSubModal, showCashModal,
                 downloadExcel, formInputs, sendDate, colVisible, toggleCashOutColumns, getCashValue, dynamicCols
        };        
    }
  }
</script>

<style scoped> 
  .yearInput { padding: 5px 5px;}
  .table-container { margin-left : 10px; margin-right: 10px;}  
  .disabledButton { background-color: gray; cursor: not-allowed; }
  label { font-size: 17px; font-weight: 600; margin-right: 8px; }
  .table { display: table-row; flex-direction: column; align-items: stretch; padding-left : 20px; padding-right : 20px; width: 80%; margin-top: 10px; }
  .table td { border : 1px solid black; text-align : center; padding-left : 10px; padding-right : 10px; font-size : 15px; word-wrap: break-word; white-space: normal; }
  th { position: sticky; top: 0; z-index: 10; background-color: rgb(230, 230, 230); border : 1px solid black;        
      text-align : center; padding: 10px; font-size : 15px; font-weight: 600;
  }
  .pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 15px;}
  .custom-file-upload { display: inline-block; padding: 6px 12px; cursor: pointer; background-color: #347003; color: #ffffff;
                      border-radius: 4px; font-size: 14px; transition: background-color 0.3s; margin-right : 10px; margin-left : 10px;
  }
  .custom-file-upload:hover { background-color: #009625; }

  .addButton { padding: 5px 5px; background-color: #3498db; color: #fff; border: none; border-radius: 4px; font-size: 14px;
          font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
  .searchButton { padding: 5px 5px; background-color: #c0c0c0; color: #000000; border: none; border-radius: 4px; font-size: 14px;
      font-weight: 600; margin-right: 10px; cursor: pointer; transition: background-color 0.3s; margin-left: 20px; 
  }
  .moButton { padding: 5px 5px; background-color: #347003; color: #fff; border: none; border-radius: 4px; font-size: 14px;
    font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
  .subButton { padding: 5px 5px; background-color: #4f24eb; color: #fff; border: none; border-radius: 4px; font-size: 14px;
    font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
  .delButton { padding: 5px 5px; background-color: #c8c8c8; color: #000000; border: none; border-radius: 4px; font-size: 14px;
    font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
  .fileDownLoadButton { padding: 5px 5px; background-color: #adadad; color: #000000; border: none; border-radius: 4px; font-size: 10px;
            font-weight: 600; margin-right: 3px; cursor: pointer; transition: background-color 0.3s;
  }
  .searchTd { border: 0px !important;}    
  .word { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 200px;}
  .maker { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 200px;}
  .factory { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 200px;}
  
  input::placeholder { color: gray; }
  select { padding: 8px; border: 1px solid #ccc; border-radius: 4px; font-size: 14px; width: 100%; max-width: 100px; }   
  
  .radio { margin-left: 10px; margin-right: 5px; }      
  .subitem::before { content: '• '; margin-left: 10px; } 
  .no-subitem { background-color: rgb(249, 249, 205); font-weight: bold}
  .horizontal-form { margin-top: 10px; margin-left: 10px; display: flex; flex-wrap: wrap; gap: 10px; align-items: center; }   
</style>