<template>
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">          
          <div class="modal-header">
            <h3>Modify Main Category - {{ mainCategoryName }}</h3>            
            <button class="close-button" @click="closeModal">X</button>
          </div>          
          <div class="modal-body">            
            <form @submit.prevent="submitForm">              
              <button type="submit" class="submitButton">Save</button>
              {{ saveStatus }}
              <div v-if="isMultipleCategories">                
                <div v-for="(catObj, catKey) in modifyData" :key="catKey" class="main-category-block">
                  <table class="itemTable">
                    <tr>
                      <td class="titleTd" style="text-align: left; width: 200px">Main Type</td>
                      <input type="text" v-model="catObj.type" placeholder="Enter main type" />
                    </tr>
                    <tr>
                      <td class="titleTd" style="text-align: left;">Product(or Semi) Code</td>
                      <td>
                        <input type="text" v-model="catObj.code" placeholder="Enter main code" />
                      </td>
                    </tr>                    
                  </table>

                  <div>
                    <label class="subLabel">Materials</label>
                    <table class="itemTable">
                      <tr>
                        <td class="titleTd">Material Code</td>
                        <td class="titleTd">Quantity</td>
                        <td class="titleTd">Remove</td>
                      </tr>
                      <tr v-for="(item, index) in catObj.material" :key="index">
                        <td>
                          <input style="text-align: center;" type="text" v-model="item.macode" placeholder="Material Code" />
                        </td>
                        <td>
                          <input style="text-align: center;" type="number" v-model.number="item.maqty" placeholder="0" />
                        </td>
                        <td>
                          <button class="delButton" type="button" @click="removeMaterial(catKey, index)">Remove</button>
                        </td>
                      </tr>
                    </table>
                    <button class="addItemButton" type="button" @click="addMaterial(catKey)">Add Material</button>
                    <button class="delButton2" type="button" @click="removeMainType(catKey)">Remove Main Type</button>
                  </div>                  
                  <hr />
                </div>
                <button class="addTypeButton" type="button" @click="addMainType">Add Main Type</button>                
              </div>
  
              <br />
              <button type="submit" class="submitButton">Save</button>
              {{ saveStatus }}
            </form>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { reactive, computed, ref, } from 'vue';
  import axios from 'axios';  
  import { getColumnMapping, makeFormData, } from '../../utils.js';
  
  export default {
    name: 'ModifyModal',
    props: {      
      mainCategoryName: { type: String, required: true },      
      initialData: { type: Object, required: true },
      rowId: { type: [String, Number], required: true }  
    },
    emits: ['onSubmit', 'onClose'],
    setup(props, { emit }) {      
      const dbTableName = "linedata";
      const saveStatus = ref(''); 
      const isProcessing = ref(false);   
      const formInputs = reactive({ id: props.rowId, [props.mainCategoryName]: {} });

      const modifyData = reactive(JSON.parse(JSON.stringify(props.initialData)));

      const isMultipleCategories = computed(() => {        
        const topKeys = Object.keys(modifyData);        
        if (topKeys.length === 0) {
          return false;
        }        
        if (
          topKeys.includes("code") &&
          topKeys.includes("quantity") &&
          topKeys.includes("material")
        ) {
          return false;
        }        
        return true;
      });
      
      const addMaterial = (catKey) => {        
        modifyData[catKey].material.push({ macode: '', maqty: '' });
      };
      const removeMaterial = (catKey, index) => {
        modifyData[catKey].material.splice(index, 1);
      };

      const removeMainType = (catKey) => {
        delete modifyData[catKey];
      };
   
      const addMainType = () => {
        const newTypeKey = `newType${Object.keys(modifyData).length + 1}`;
        modifyData[newTypeKey] = {
          type: newTypeKey,
          code: '',
          quantity: 0,
          material: []
        };
      };
  
      const submitForm = async () => {
        isProcessing.value = true; 
        saveStatus.value = 'Processing...';  
        
        const result = JSON.parse(JSON.stringify(modifyData));            
       
        Object.keys(result).forEach(key => { result[key].quantity = 0;});

        if (props.mainCategoryName === 'ok_semi') { formInputs.ok_semi = result; }
        else if (props.mainCategoryName === 'ng_type') { formInputs.ng_type = result; }
        else if (props.mainCategoryName === 'ng_fake') { formInputs.ng_fake = result; }
        else if (props.mainCategoryName === 'ng_comp') { formInputs.ng_comp = result; }

        const columnMapping = getColumnMapping(undefined, formInputs);                    
        const formData = makeFormData(undefined, formInputs, dbTableName, columnMapping); 

        try {
          await axios.post('/api/post', formData);
          saveStatus.value = 'Completed'; 
        } catch (error) {
          console.error('Error during submission:', error);
          saveStatus.value = 'Error';
        } finally {
          isProcessing.value = false; 
        }     
        closeModal();  
      };    
  
      const closeModal = () => { emit('onClose'); };
  
      return {
        modifyData, isMultipleCategories, addMaterial, formInputs, saveStatus,
        isProcessing, removeMaterial, addMainType, removeMainType, submitForm, closeModal
      };
    }
  };
  </script>
  
  <style scoped>
  @import '../../generalStyle.css';
  .modal-wrapper { max-width: 600px; }  
  .main-category-block { border: 1px solid #ccc; padding: 10px; margin-bottom: 10px;}
  </style>
  