<template>
  <div class="app">    
    <div v-if="state.account.id">      
      <Ground/>    
      <button @click="logout()" class="logout">Log Out</button>
      <button @click="showChangeInfoModal()" class="changeInformation">Change My Information</button>
      <changeInformation v-if="state.showChangeInfoModal" @close="state.showChangeInfoModal = false" :rowId="state.account.id"/>
    </div>    
    <div v-else>
      <logIn />
    </div>
  </div>  
</template>

<script>
import axios from 'axios';
import { reactive, onMounted } from "vue";
import Ground from "./components/GroundOne.vue";
import logIn from "./components/logIn/logIn.vue";
import changeInformation from "./components/logIn/changeInformation.vue";
import Cookies from 'js-cookie';

export default {
  components: { Ground, logIn, changeInformation },

  setup() {
    const state = reactive({
      account: {
        id: null,
        name: "",
        auth: null,
      },
      form: {
        loginId: "",
        loginPw: "",
      },
      showChangeInfoModal: false
    });

    const logout = () => {   
      axios.delete("/api/accountDelete", { withCredentials: true }).then(() => {
        alert("You have logged out.")
        state.account.id = null;
        state.account.name = "";
        state.account.auth = null;
        localStorage.removeItem('account');  
        Cookies.remove('token', { path: '/', sameSite: 'None', secure: true });      
      }).catch((error) => { 
        console.error("Logout failed: ", error);
      });
    };

    const showChangeInfoModal = () => {
      state.showChangeInfoModal = true;
    };

    onMounted(() => {
      const accountData = localStorage.getItem('account');
      if (accountData) {
        state.account = JSON.parse(accountData);        
      }
    });   

    return { state, logout, showChangeInfoModal };
  },
};
</script>

<style scoped>

.logout {
  bottom: 20px;
  left: 20px;
  padding: 6px 12px; 
  font-size: 14px; 
  font-weight: bold;
  border-radius: 4px;
  width: auto; 
  white-space: nowrap; 
  background-color: #c2c2c2;
  color: #000000; 
  margin-left: 15px;
  margin-bottom: 10px;
}
.changeInformation{
  bottom: 20px;
  left: 20px;
  padding: 6px 12px; 
  font-size: 14px; 
  font-weight: bold;
  border-radius: 4px;
  width: auto; 
  white-space: nowrap; 
  background-color: #9d77f5;
  color: #ffffff; 
  margin-left: 15px;
  margin-bottom: 10px;
}

</style>