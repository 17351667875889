import { reactive, ref } from 'vue';  
import axios from 'axios';  
import { getCurrentDate, makeFormData, getColumnMapping, useFormInputs, getModelsForLine} from "../../../utils.js";   
import { calculateTotalNg } from '../../productionUtils.js';
import { targets, lineData } from "../../../lineData.js";

export function useForm(props, emit) {    
    const selectedLine = targets.find(target => target.name === props.lineName);        
    const models = ref(getModelsForLine(props.lineName, lineData));        

    const dbTableName = selectedLine.qw;        

    const saveStatus = ref(''); 
    const isProcessing = ref(false);  

    const { formInputs, dataForm } = useFormInputs();  
    calculateTotalNg(formInputs); 

    const searchDate = reactive({ ...getCurrentDate(), });         

    const submitForm = async () => {
        if (!searchDate.year || !searchDate.month || !searchDate.day || !formInputs.model) {
        alert("Please fill in all the fields: year, month, day and model.");
        return;
        }

        isProcessing.value = true; 
        saveStatus.value = 'Processing...';

        const columnMapping = getColumnMapping(searchDate, formInputs);
        const formData = makeFormData(searchDate, formInputs, dbTableName, columnMapping);  

        try {      
        await axios.post('/api/post', formData);
        saveStatus.value = 'Completed';
        } catch (error) {
        console.error('Error during submission:', error);
        saveStatus.value = 'Error'; 
        } finally {
        isProcessing.value = false; 
        }           
        closeModal();
    };

    const closeModal = () => { emit("onClose"); };

    return { searchDate, dbTableName, formInputs, submitForm, closeModal, saveStatus, isProcessing, dataForm, models  };
}
 