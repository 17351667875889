<template>
  <div class="modal">
    <div class="modal-content">
      <span class="close" @click="$emit('close')">&times;</span>
      <h2>Sign Up</h2>
      <table>
        <tbody>
          <tr><td><label>이름(Name)</label></td></tr>          
          <tr><td><input type="text" id="name" v-model="form.name" /></td></tr>
          <tr><td><label>부서(Department)</label></td></tr>          
          <tr><td><input type="text" id="name" v-model="form.department" /></td></tr>
          <tr><td><label>직위(Position)</label></td></tr>          
          <tr><td><input type="text" id="name" v-model="form.position" /></td></tr>
          <tr><td><label>핸드폰 번호(Phone Number)</label></td></tr>
          <tr><td><input type="text" id="phone" v-model="form.phonenumber" @input="validatePhoneNumber" /></td>
            <td>              
              <button class="checkButton" @click.prevent="checkDuplicate('phonenumber')" :class="{ 'disabled': !isPhoneNumberValid }" :disabled="!isPhoneNumberValid">확인</button>
            </td>
          </tr>
          <tr><td><span v-if="errors.phonenumber" :class="{'error': errors.phonenumber.includes('사용중인 값입니다.') || errors.phonenumber.includes('숫자만 입력 가능합니다.')  || errors.phonenumber.includes('값을 입력해주세요.'), 'success': errors.phonenumber.includes('사용 가능 합니다.') }">{{ errors.phonenumber }}</span></td></tr>
          <tr><td><label>이메일 주소(Email Address)</label></td></tr>
          <tr><td><input type="email" v-model="form.emailaddress" @input="validateEmailAddress" /></td>
            <td>
              <button class="checkButton" 
                      @click.prevent="checkAndSendVerificationEmail" 
                      :class="{ 'disabled': !isEmailAddressValid }"
                      :disabled="!isEmailAddressValid">확인</button>
            </td>
          </tr>
          <tr><td><span v-if="errors.emailaddress" :class="{'error': errors.emailaddress.includes('사용중인 값입니다.') || errors.emailaddress.includes('이메일 형식이 아닙니다.') || errors.emailaddress.includes('값을 입력해주세요.'), 'success': errors.emailaddress.includes('사용 가능 합니다.') }">
            {{ errors.emailaddress }}
            <span v-if="isEmailAddressValid && !showVerification">확인 코드 이메일 전송중</span>
            <span v-if="showVerification">확인 코드 이메일 전송완료</span>
          </span></td></tr>
       
          <tr v-if="showVerification">
            <td>
              <input type="text" v-model="form.verifyCode" placeholder="Enter verification code" />
            </td>
            <td>
              <button class="verifyButton" @click.prevent="verifyCode">검증</button>
            </td>
          </tr>
          <tr>
            <td>
            <span :class="{ 'error': verificationStatus.includes('코드 재확인') || verificationStatus.includes('시간 초과'), 'success': verificationStatus.includes('인증 완료') }">
              {{ verificationStatus }}
            </span>
            </td>
          </tr>          
          <tr><td><label>아이디(ID)</label></td></tr>
          <tr><td><input type="text" v-model="form.loginid" @input="validateLoginId" /></td>
            <td>
              <button class="checkButton" 
                      @click.prevent="checkDuplicate('loginid')" 
                      :class="{ 'disabled': !isLoginIdValid }"
                      :disabled="!isLoginIdValid">확인</button>
            </td>
          </tr>
          <tr><td><span v-if="errors.loginid" :class="{'error': errors.loginid.includes('사용중인 값입니다.') || errors.loginid.includes('소문자 영문자와 숫자만 입력 가능합니다.') || errors.loginid.includes('값을 입력해주세요.'), 'success': errors.loginid.includes('사용 가능 합니다.') }">{{ errors.loginid }}</span></td></tr>
          <tr><td><label>암호(Password)</label></td></tr>
          <tr><td><input :type="showPassword ? 'text' : 'password'" id="loginPw" v-model="form.loginPw" @input="validatePassword" /></td>
            <td><button class="viewButton" @click="togglePasswordVisibility">{{ showPassword ? '숨기기' : '보기' }}</button></td></tr>
          <tr><td><span v-if="errors.loginPw" class="error">{{ errors.loginPw }}</span></td></tr>
          <tr><td><label>암호 재입력(Password rewrite)</label></td></tr>
          <tr><td><input :type="showPassword ? 'text' : 'password'" id="verifyPw" v-model="form.verifyPw" /></td>
            <td><button class="verifyButton" @click="checkPasswordMatch">검증</button></td></tr>
          <tr><td><span v-if="errors.verifyPw" :class="{'error': errors.verifyPw.includes('암호가 일치하지 않습니다.'), 'success': errors.verifyPw.includes('사용 가능 합니다.') }">{{ errors.verifyPw }}</span></td></tr>
        </tbody>
      </table>
      <button class="saveButton" type="submit" 
              :class="{ 'disabled': isProcessing || !canSave }" 
              @click.prevent="submit" 
              :disabled="isProcessing || !canSave">저장 (Save)</button>
      {{ saveStatus }}      
    </div>
  </div>
</template>

<script>
import { setupForm } from './joinModal_form';

export default {
  setup(props, { emit }) {
    return setupForm(props, emit);
  }
};
</script>

<style scoped>
@import './joinModalStyle.css';
</style>
