<template>
    <div class="container">        
      <div class="search">
        <form @submit.prevent="sendDate()">
          <div class="horizontal-form">                
            <div class="start">
              <input type="number" id="startYear" v-model="startYear" placeholder="Year" />
              <input type="number" id="startMonth" v-model="startMonth" placeholder="Month" />
              <input type="number" id="startDay" v-model="startDay" placeholder="Day" />
            </div>
            <div class="separator">~</div>
            <div class="end">
              <input type="number" id="endYear" v-model="endYear" placeholder="Year" />
              <input type="number" id="endMonth" v-model="endMonth" placeholder="Month" />     
              <input type="number" id="endDay" v-model="endDay" placeholder="Day" />
            </div>
            <div class="searchTd">
              <button type="submit" @click="callSearchData">Search</button>                        
            </div>      
            <div class="searchTd">
              <button class="excelButton" @click="downloadExcel('pr')">Production Excel</button>                                              
            </div>
            <div class="searchTd">
              <button class="excelButton" @click="downloadExcel('dt')">Downtime Excel</button>     
            </div>
            <div class="searchTd">
              <button class="excelButton" @click="downloadExcel('qw')">Q-Wall Excel</button>     
            </div>      
          </div>   
        </form>
      </div>           
      <div v-for="target in withoutPeriodExceptTargets" :key="target.name">
        <b>{{ target.name }}</b>
        <table>
          <tr>            
            <th v-for="(header, index) in (lineData[getLineDataKey(target.name)]?.search_headers || [])" :key="index">{{ header }}</th>          
          </tr>
          <template v-if="lineData[getLineDataKey(target.name)]?.models && lineData[getLineDataKey(target.name)].models.length > 0">
            <tr v-for="model in lineData[getLineDataKey(target.name)].models" :key="model">          
              <td>{{ model }}</td>
              <td>{{ getSumsValue(target.pr, model, 'target') }}</td>
              <td>{{ getSumsValue(target.pr, model, 'input') }}</td>
              <td class="ok">{{ getSumsValue(target.pr, model, 'ok') - getSumsValue(target.qw, model, 'ng') }}</td>
              <td class="ng">{{ getSumsValue(target.pr, model, 'ng') + getSumsValue(target.qw, model, 'ng') }}</td>            
              <td class="ng_type" v-for="ngType in (lineData[getLineDataKey(target.name)]?.ng_types || [])" :key="ngType">{{ getSumsValue(target.pr, model, ngType) + getSumsValue(target.qw, model, ngType) }}</td>          
              <td class="ng">{{ getSumsValue(target.pr, model, 'input') === 0 ? 0 : (((getSumsValue(target.pr, model, 'ng') + getSumsValue(target.qw, model, 'ng')) / getSumsValue(target.pr, model, 'input')) * 100).toFixed(1) }}%</td>
              <template v-if="target.periodFakeNg === '1'">
                <td class="fn">{{ getSumsValue(target.pr, model, 'fakeSum') }}</td>
                <td class="fn_type" v-for="fnType in (lineData[getLineDataKey(target.name)]?.fn_types || [])" :key="fnType">{{ getSumsValue(target.pr, model, fnType) }}</td>
                <td class="fn">{{ getSumsValue(target.pr, model, 'input') === 0 ? 0 : ((getSumsValue(target.pr, model, 'fakeSum') / getSumsValue(target.pr, model, 'input')) * 100).toFixed(1) }}%</td>
              </template>
              <td>{{ getSumsValue(target.pr, model, 'work_minute') }}</td>
              <td>{{ getSumsValue(target.dt, model, 'time') }}</td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="100%">No models available</td>
            </tr>
          </template>
          <tr>
            <td>Total</td>
            <td>{{ getSumsValue(target.pr, 'total', 'target') }}</td>
            <td>{{ getSumsValue(target.pr, 'total', 'input') }}</td>
            <td class="ok">{{ getSumsValue(target.pr, 'total', 'ok') - getSumsValue(target.qw, 'total', 'ng') }}</td>
            <td class="ng">{{ getSumsValue(target.pr, 'total', 'ng') + getSumsValue(target.qw, 'total', 'ng') }}</td>            
            <td class="ng_type" v-for="ngType in (lineData[getLineDataKey(target.name)]?.ng_types || [])" :key="ngType">{{ getSumsValue(target.pr, 'total', ngType) + getSumsValue(target.qw, 'total', ngType) }}</td>
            <td class="ng">{{ getSumsValue(target.pr, 'total', 'input') === 0 ? 0 : (((getSumsValue(target.pr, 'total', 'ng') + getSumsValue(target.qw, 'total', 'ng')) / getSumsValue(target.pr, 'total', 'input')) * 100).toFixed(1) }}%</td>
            <template v-if="target.periodFakeNg === '1'">
              <td class="fn">{{ getSumsValue(target.pr, 'total', 'fakeSum') }}</td>
              <td class="fn_type" v-for="fnType in (lineData[getLineDataKey(target.name)]?.fn_types || [])" :key="fnType">{{ getSumsValue(target.pr, 'total', fnType) }}</td>
              <td class="fn">{{ getSumsValue(target.pr, 'total', 'input') === 0 ? 0 : ((getSumsValue(target.pr, 'total', 'fakeSum') / getSumsValue(target.pr, 'total', 'input')) * 100).toFixed(1) }}%</td>
            </template>
            <td>{{ getSumsValue(target.pr, 'total', 'work_minute') }}</td>
            <td>{{ getSumsValue(target.dt, 'total', 'time') }}</td>
          </tr> 
        </table>
      </div>      
       
      <div>
        <B>Thermistor Tape</B>
        <table>
          <tr><th v-for="(header, index) in lineData.thermistortape.search_headers" :key="index">{{ header }}</th></tr>   
          <tr v-for="model in lineData.thermistortape.models" :key="model">      
            <td>{{ model }}</td>          
            <td>{{ getSumsValue('thermistortapepr', model, 'target') }}</td>
            <td>{{ getSumsValue('thermistortapepr', model, 'input') }}</td>
            <td class="ok">{{ getSumsValue('thermistortapepr', model, 'oktotal') }}</td>
            <td class="ok">{{ getSumsValue('thermistortapepr', model, 'ok_a') }}</td>
            <td class="ok">{{ getSumsValue('thermistortapepr', model, 'ok_b') }}</td>
            <td class="ok">{{ getSumsValue('thermistortapepr', model, 'ok_c') }}</td>
            <td class="ng">{{ getSumsValue('thermistortapepr', model, 'ng') }}</td>
            <td class="ng_type" v-for="ngType in lineData.thermistortape.ng_types" :key="ngType">{{ getSumsValue('thermistortapepr', model, ngType) }}</td>
            <td class="ng">{{ getSumsValue('thermistortapepr', model, 'input') === 0 ? 0 : ((getSumsValue('thermistortapepr', model, 'ng') / getSumsValue('thermistortapepr', model, 'input')) * 100).toFixed(1) }}%</td>
            <td>{{ getSumsValue('thermistortapepr', model, 'work_minute') }}</td>            
            <td>{{ getSumsValue('thermistortapedt', model, 'time') }}</td>   
          </tr>
        <tr>
            <td>Total</td>          
            <td>{{ getSumsValue('thermistortapepr', 'total', 'target') }}</td>
            <td>{{ getSumsValue('thermistortapepr', 'total', 'input') }}</td>
            <td class="ok">{{ getSumsValue('thermistortapepr', 'total', 'oktotal') }}</td>
            <td class="ok">{{ getSumsValue('thermistortapepr', 'total', 'ok_a') }}</td>
            <td class="ok">{{ getSumsValue('thermistortapepr', 'total', 'ok_b') }}</td>
            <td class="ok">{{ getSumsValue('thermistortapepr', 'total', 'ok_c') }}</td>
            <td class="ng">{{ getSumsValue('thermistortapepr', 'total', 'ng') }}</td>
            <td class="ng_type" v-for="ngType in lineData.thermistortape.ng_types" :key="ngType">{{ getSumsValue('thermistortapepr', 'total', ngType) }}</td>
            <td class="ng">{{ getSumsValue('thermistortapepr', 'total', 'input') === 0 ? 0 : ((getSumsValue('thermistortapepr', 'total', 'ng') / getSumsValue('thermistortapepr', 'total', 'input')) * 100).toFixed(1) }}%</td>
            <td>{{ getSumsValue('thermistortapepr', 'total', 'work_minute') }}</td>            
            <td>{{ getSumsValue('thermistortapedt', 'total', 'time') }}</td>         
          </tr>        
        </table> 

        <B>Thermistor Pad</B>
        <table>
          <tr><th v-for="(header, index) in lineData.thermistorpad.search_headers" :key="index">{{ header }}</th></tr>   
          <tr v-for="model in lineData.thermistorpad.models" :key="model">      
            <td>{{ model }}</td>          
            <td>{{ getSumsValue('thermistorpadpr', model, 'target') }}</td>
            <td>{{ getSumsValue('thermistorpadpr', model, 'input') }}</td>
            <td class="ok">{{ getSumsValue('thermistorpadpr', model, 'oktotal') }}</td>
            <td class="ok">{{ getSumsValue('thermistorpadpr', model, 'ok_a') }}</td>
            <td class="ok">{{ getSumsValue('thermistorpadpr', model, 'ok_b') }}</td>
            <td class="ok">{{ getSumsValue('thermistorpadpr', model, 'ok_c') }}</td>
            <td class="ng">{{ getSumsValue('thermistorpadpr', model, 'ng') }}</td>
            <td class="ng_type" v-for="ngType in lineData.thermistorpad.ng_types" :key="ngType">{{ getSumsValue('thermistorpadpr', model, ngType) }}</td>
            <td class="ng">{{ getSumsValue('thermistorpadpr', model, 'input') === 0 ? 0 : ((getSumsValue('thermistorpadpr', model, 'ng') / getSumsValue('thermistorpadpr', model, 'input')) * 100).toFixed(1) }}%</td>
            <td>{{ getSumsValue('thermistorpadpr', model, 'work_minute') }}</td>            
            <td>{{ getSumsValue('thermistorpaddt', model, 'time') }}</td>   
          </tr>
        <tr>
            <td>Total</td>          
            <td>{{ getSumsValue('thermistorpadpr', 'total', 'target') }}</td>
            <td>{{ getSumsValue('thermistorpadpr', 'total', 'input') }}</td>
            <td class="ok">{{ getSumsValue('thermistorpadpr', 'total', 'oktotal') }}</td>
            <td class="ok">{{ getSumsValue('thermistorpadpr', 'total', 'ok_a') }}</td>
            <td class="ok">{{ getSumsValue('thermistorpadpr', 'total', 'ok_b') }}</td>
            <td class="ok">{{ getSumsValue('thermistorpadpr', 'total', 'ok_c') }}</td>
            <td class="ng">{{ getSumsValue('thermistorpadpr', 'total', 'ng') }}</td>
            <td class="ng_type" v-for="ngType in lineData.thermistorpad.ng_types" :key="ngType">{{ getSumsValue('thermistorpadpr', 'total', ngType) }}</td>
            <td class="ng">{{ getSumsValue('thermistorpadpr', 'total', 'input') === 0 ? 0 : ((getSumsValue('thermistorpadpr', 'total', 'ng') / getSumsValue('thermistorpadpr', 'total', 'input')) * 100).toFixed(1) }}%</td>
            <td>{{ getSumsValue('thermistorpadpr', 'total', 'work_minute') }}</td>            
            <td>{{ getSumsValue('thermistorpaddt', 'total', 'time') }}</td>         
          </tr>        
        </table> 

        <B>Bending EVO</B>
        <table>
          <tr><th v-for="(header, index) in lineData.bendingevo.search_headers" :key="index">{{ header }}</th></tr>         
        <tr>
            <td>Total</td>          
            <td>{{ getSumsValue('bendingevopr', 'total', 'target') }}</td>
            <td>{{ getSumsValue('bendingevopr', 'total', 'input') }}</td>
            <td class="ok">{{ getSumsValue('bendingevopr', 'total', 'oktotal') }}</td>
            <td class="ok">{{ getSumsValue('bendingevopr', 'total', 'ok_a') }}</td>
            <td class="ok">{{ getSumsValue('bendingevopr', 'total', 'ok_b') }}</td>
            <td class="ok">{{ getSumsValue('bendingevopr', 'total', 'ok_c') }}</td>
            <td class="ok">{{ getSumsValue('bendingevopr', 'total', 'ok_d') }}</td>
            <td class="ng">{{ getSumsValue('bendingevopr', 'total', 'ngtotal') }}</td>
            <td class="ng_type" v-for="ngType in lineData.bendingevo.ng_types" :key="ngType">{{ getSumsValue('bendingevopr', 'total', ngType) }}</td>
            <td class="ng">{{ getSumsValue('bendingevopr', 'total', 'input') === 0 ? 0 : ((getSumsValue('bendingevopr', 'total', 'ng') / getSumsValue('bendingevopr', 'total', 'input')) * 100).toFixed(1) }}%</td>
            <td>{{ getSumsValue('bendingevopr', 'total', 'work_minute') }}</td>            
            <td>{{ getSumsValue('bendingevodt', 'total', 'time') }}</td>         
          </tr>        
        </table>
      </div>
  </div>
</template> 

<script>

import { reactive, ref,  } from 'vue';
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';
import { createSearchReportUtils, settingValue } from './searchReportUtils';
import { tableNames, lineData, withoutPeriodExceptTargets } from '../../lineData';

export default {
  setup() {
    const startYear = ref(); 
    const startMonth = ref(); 
    const startDay = ref(); 
    const endYear = ref();
    const endMonth = ref(); 
    const endDay = ref(); 
     
    const { fetchAllData, getDataByType, sums } = createSearchReportUtils(tableNames);     

    const sendDate = async () => {
      const sendDateData = {
        startYear: startYear.value,
        startMonth: startMonth.value,
        startDay: startDay.value,
        endYear: endYear.value,
        endMonth: endMonth.value,
        endDay: endDay.value,
      };     
      return sendDateData;
    };

    const settingData = reactive({ data: [] });
    (async () => {
      try {
        const { settingData: setting } = await settingValue();
        settingData.data = setting.data;
      } catch (error) {
        console.error("Error initializing settings:", error.message);
      }
    })();   

    const callSearchData = async () => {
      try {
        const reportSetting = settingData.data.find(s => Object.hasOwn(s, 'report'));
        const isReportEnabled = reportSetting && reportSetting.report === 1;
        if (!isReportEnabled) {
          alert('Report is currently disabled.');
          return;
        }

        const dateRange = await sendDate();
            
        await fetchAllData(dateRange);
        
      } catch (error) {
        console.error("Report is currently disabled:", error.message);
      }
    };  

    const getLineDataKey = (name) => {
      return name.toLowerCase().replace(/\s+/g, '');
    };

    const getSumsValue = (table, model, field) => {
      const tableData = sums.value[table];
      if (tableData && tableData[model] && tableData[model][field] !== undefined) {
        return tableData[model][field];
      }
      return 0;
    }; 

    const downloadExcel = (type) => {
      const dataByType = getDataByType(type);
      const wb = XLSX.utils.book_new();

      Object.keys(dataByType).forEach((tableName) => {
          const sheet = XLSX.utils.json_to_sheet(dataByType[tableName]);          
        
          let baseName = tableName.replace(type, '');
          let lineKey = '';
         
          switch (baseName) {
              case 'tr2bfa2p':
                  lineKey = 'evo3';
                  break;
              case 'tr3bfa2p':
                  lineKey = 'evo1';
                  break;
              case 'tr3bfa3p':
                  lineKey = 'evo2';
                  break;
              default:
                  lineKey = getLineDataKey(baseName);
                  break;
          }
        
          XLSX.utils.book_append_sheet(wb, sheet, `${lineKey}_${type.toUpperCase()} Data`);
      });

      const wbout = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
      const blob = new Blob([wbout], { type: "application/octet-stream" });
      saveAs(blob, `${type.toUpperCase()}_data.xlsx`);
    };

    return {
      startYear, startMonth, startDay, endYear, endMonth, endDay,
      lineData, withoutPeriodExceptTargets,
      sendDate, callSearchData, sums, getSumsValue,
      getLineDataKey,
      downloadExcel
    };
  },
};
</script>



<style scoped>
   * {
    box-sizing: border-box;
  }

  .container {
    margin-top: 0px;
    padding-top: 0px;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  .search {
  position: relative;
  background-color: #ffffff; /* Set a background color for better visibility */  
  padding: 0px; /* Add some padding for better appearance */
  height: auto;
}
  .start,
  .end {
    display: flex;
    flex-direction: row;    
    border: 0px;
  }

  .start input,
  .end input {
    margin-right: 5px;
  }

  .separator {
    font-size: 24px;
    margin: 0 10px;
    border: 0px;
  }
  .searchTd {    
    border: 0px;
  }

  input::placeholder {
    color: gray;
  }

  label {
    font-size: 14px;
    font-weight: 600;
    margin-right: 8px;
  }

  select,
  input[type="number"],
  input[type="text"] {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    max-width: 100px;
  }

  button {
    padding: 5px 5px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s;
  }
 .excelButton {
  padding: 5px 5px;
  background-color: #10a802;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
}
  .excelButton:hover,
  button:hover {
    background-color: #2980b9;
  }

  table {
    width: 80%;
    border-collapse: collapse;
    margin-bottom: 30px;
  }

  th,
  td {
    border: 1px solid #ccc;
    text-align: center;
    padding: 8px;
    font-size: 12px;
  }

  th {
    background-color: #f2f2f2;
    font-weight: 600;
  }

  tr:nth-child(even) {
    background-color: #f8f8f8;
  }

  tr:hover {
    background-color: #e6e6e6;
  }
  .ok {
  background-color: #94b7f8; /* Adjust the color as needed */
  font-weight: bold;
}

.ok_type {
  background-color: #d7e4fc; /* Adjust the color as needed */
  font-weight: bold;
}
.ng {
  background-color: #fa7979; /* Adjust the color as needed */
  font-weight: bold;
}
.ng_type {
  background-color: #fdb08d; /* Adjust the color as needed */
}

.fn {
  background-color: #ff72d5; /* Adjust the color as needed */
}

.fn_type {
  background-color: #ff9bcd; /* Adjust the color as needed */
}
.horizontal-form { margin-bottom: 20px; display: flex; flex-wrap: wrap; gap: 10px; align-items: center; }   
</style> 