<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">          
          <table>
            <tr>
              <td><h3>Add Production Result</h3></td>
              <td><button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button></td>              
              <td>{{ saveStatus }}</td>             
            </tr>
          </table>        
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">           
          <table>
            <tr>
              <td>
              <div class="form-group date-time-group">
                <label>Year/Month/Day:</label>
                <div class="date-time-inputs">
                  <input type="text" id="year" v-model="searchDate.year"  />
                  <input type="text" id="month" v-model="searchDate.month" />
                  <input type="text" id="day" v-model="searchDate.day" />                  
                </div> 
              </div>                                   
            <div class="form-group">
              <label for="shift">Shift:</label>
              <input type="number" id="shift" v-model="formInputs.shift" readonly class="readWindow" />
            </div>            
            <div class="form-group">
              <label for="start_time">Start Time:</label>              
              <select id="start_time" v-model="formInputs.start_time">            
                <option>01</option><option>02</option><option>03</option><option>04</option>
                <option>05</option><option>06</option><option>07</option><option>08</option>
                <option>09</option><option>10</option><option>11</option><option>12</option>
                <option>13</option><option>14</option><option>15</option><option>16</option>
                <option>17</option><option>18</option><option>19</option><option>20</option>
                <option>21</option><option>22</option><option>23</option><option>24</option>
              </select>      
            </div>
            <div class="form-group">
              <label for="end_time">End Time:</label>
              <select id="end_time" v-model="formInputs.end_time" readonly class="readWindow">            
                <option>01</option><option>02</option><option>03</option><option>04</option>
                <option>05</option><option>06</option><option>07</option><option>08</option>
                <option>09</option><option>10</option><option>11</option><option>12</option>
                <option>13</option><option>14</option><option>15</option><option>16</option>
                <option>17</option><option>18</option><option>19</option><option>20</option>
                <option>21</option><option>22</option><option>23</option><option>24</option>
              </select>
            </div>
            <div class="form-group">
              <label for="work_minute">Work Time(minute):</label>
              <select id="work_minute" v-model="formInputs.work_minute">            
                <option>5</option><option>15</option><option>30</option><option>45</option><option>60</option>    
              </select>                            
            </div>
          </td>
          <td>
            <div class="form-group">
              <label for="model">Model:</label>
              <select id="model" v-model="formInputs.model">            
                <option v-for="model in models" :key="model">{{ model }}</option>
              </select>              
            </div>
            <div class="form-group">
              <label for="target">Target:</label>
              <input type="number" id="target" v-model="formInputs.target" readonly class="readWindow" />
            </div>                
            <div class="form-group">
              <label for="ok">OK:</label>
              <input type="number" id="ok" v-model="formInputs.ok" />
            </div>
          </td>
          <td>
            <div class="form-group">
              <label for="ng">NG:</label>
              <input class="readWindow" readonly type="number" id="ng" v-model="formInputs.ng" />
            </div>          
            <div class="form-group">
              <label for="ng_fuse">NG FUSE:</label>
              <input type="number" id="ng_fuse" v-model="formInputs.ng_fuse"  />
            </div>
            <div class="form-group">
              <label for="ng_thermistor">NG Thermistor:</label>
              <input type="number" id="ng_thermistor" v-model="formInputs.ng_thermistor" />
            </div>
            <div class="form-group">
              <label for="ng_fiducial">NG Fiducial:</label>
              <input type="number" id="ng_fiducial" v-model="formInputs.ng_fiducial"  />
            </div>
            <div class="form-group">
              <label for="ng_qrcode">NG QR-CODE:</label>
              <input type="number" id="ng_qrcode" v-model="formInputs.ng_qrcode" />
            </div>
            <div class="form-group">
              <label for="ng_burned">NG Burned:</label>
              <input type="number" id="ng_burned" v-model="formInputs.ng_burned" />
            </div>
            <div class="form-group">
              <label for="ng_blackfuse">NG Black Fuse:</label>
              <input type="number" id="ng_blackfuse" v-model="formInputs.ng_blackfuse" />
            </div>
            <div class="form-group">
              <label for="ng_others">NG Others:</label>
              <input type="number" id="ng_others" v-model="formInputs.ng_others"  />
            </div>
          </td>
          <td>
            <div class="form-group">
              <label for="fn_saoi_fuse">Fake NG SAOI Fuse:</label>
              <input type="number" id="fn_saoi_fuse" v-model="formInputs.fn_saoi_fuse" />
            </div>
            <div class="form-group">
              <label for="fn_saoi_thermistor">Fake NG SAOI Thermistor:</label>
              <input type="number" id="fn_saoi_thermistor" v-model="formInputs.fn_saoi_thermistor"  />
            </div>
            <div class="form-group">
              <label for="fn_finalinspection">Fake NG Final Inspection:</label>
              <input type="number" id="fn_finalinspection" v-model="formInputs.fn_finalinspection"  />
            </div>            
            <div class="form-group">
              <label for="semi_fpcbblock">FBCB Block:</label>
              <input type="number" id="semi_fpcbblock" v-model="formInputs.semi_fpcbblock"  />
            </div>
          </td>
          <td>
            <div class="form-group">
              <label for="weekcode">WEEK CODE:</label>
              <input type="text" id="weekcode" v-model="formInputs.weekcode"  />
            </div>
            <div class="form-group">
              <label for="weektinplating">WEEK TIN PLATING:</label>
              <input type="text" id="weektinplating" v-model="formInputs.weektinplating"  />
            </div>
            <div class="form-group">
              <label for="workorder">Work Order:</label>
              <input type="text" id="workorder" v-model="formInputs.workorder"  />
            </div>
            <div class="form-group">
              <label for="supervisor">Supervisor:</label>
              <input type="text" id="supervisor" v-model="formInputs.supervisor" />
            </div>
            <div class="form-group">
              <label for="leader">Leader:</label>
              <input type="text" id="leader" v-model="formInputs.leader" />
            </div>
            <div class="form-group">
              <label for="technician">Technician:</label>
              <input type="text" id="technician" v-model="formInputs.technician" />
            </div>
            <div class="form-group">
              <label for="shift_name">Shift Name:</label>
              <input type="text" id="shift_name" v-model="formInputs.shift_name" />
            </div>
            <div class="form-group">
              <label for="operator_qty">Operator Quantity:</label>
              <input type="number" id="operator_qty" v-model="formInputs.operator_qty" />
            </div>
            <div class="form-group">
              <label for="row_no">Row_no:</label>
              <input type="number" id="row_no" v-model="formInputs.row_no" readonly class="readWindow" />
            </div>
          </td>
          </tr>  
          </table>       
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>            
            {{ saveStatus }}                    
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
<script>
import { useForm } from '../0_formStyle/modal/form_pr_modal';

export default {
  props: { onSubmit: { type: Function, required: true }, lineName: { type: String, required: true } },
  setup(props, { emit }) {
    return useForm(props, emit);
  }
};
</script>
    
<style scoped src="../0_formStyle/modal/style_pr_modal.css"></style>