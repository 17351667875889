<template>
    <div class="fca1">            
      <div class="act">               
         <h3 class="pagetitle">Thermistor Pad</h3>        
      </div>    
      <form @submit.prevent="sendDate()" class="searchBar">     
        <select id="year" v-model="searchDate.year">   
            <option disabled selected value="" style="color: gray;">Year</option>           
            <option>2021</option><option>2022</option><option>2023</option><option>2024</option>
            <option>2025</option><option>2026</option><option>2027</option><option>2028</option>
            <option>2029</option><option>2030</option><option>2031</option><option>2032</option>
            <option>2033</option><option>2034</option><option>2035</option><option>2036</option>
            <option>2037</option><option>2038</option><option>2039</option><option>2040</option>
        </select>           
        <select id="month" v-model="searchDate.month">            
            <option disabled selected value="" style="color: gray;">Month</option>
            <option>1</option><option>2</option><option>3</option><option>4</option>
            <option>5</option><option>6</option><option>7</option><option>8</option>
            <option>9</option><option>10</option><option>11</option><option>12</option>            
        </select>           
        <select id="day" v-model="searchDate.day">            
            <option disabled selected value="" style="color: gray;">Day</option>
            <option>1</option><option>2</option><option>3</option><option>4</option>
            <option>5</option><option>6</option><option>7</option><option>8</option>
            <option>9</option><option>10</option><option>11</option><option>12</option> 
            <option>13</option><option>14</option><option>15</option><option>16</option>   
            <option>17</option><option>18</option><option>19</option><option>20</option>   
            <option>21</option><option>22</option><option>23</option><option>24</option>
            <option>25</option><option>26</option><option>27</option><option>28</option>
            <option>29</option><option>30</option><option>31</option>                    
        </select>
        <button class="searchButton" type="submit"  @click="callSearchData">Search</button>  
        <button class="excelButton" @click="downExcel">Excel</button>
        <button class="button" @click="showModal = true">+ Result</button>
        <button class="button" @click="showdtModal = true">+ Downtime</button>
        <button class="button" @click="showdrModal = true">+ Daily Report</button>         
        <button class="button" @click="showrdModal = true">+ Repeat Data</button>
      </form> 
      <P></P>     
      <div class="tables">
      <table id="productionResult" class="custom-table">
        <tr>          
          <th v-for="(header, index) in headers" :key="index">{{ header }}</th>          
        </tr>          
          <tr></tr>
          <tr v-for="row in item" :key="row.id">
            <template v-for="(header, index) in headers">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="header === 'Shift'" :key="index" @click="editCell(dbTableName, row.id, 'shift', $event)" :class="{'shift1': row.shift === 1, 'shift2': row.shift === 2}">{{ row.shift }}</td>
                <td v-if="header === 'Time'" :key="index" class="sticky-time">{{ row.start_time }}~{{ row.end_time }}</td>
                <td v-if="header === 'Model'" :key="index" :class="{ 'model-evo2p': row.model === 'EVO_2P', 'model-evo3p': row.model === 'EVO_3P', 'model-mc253p': row.model === 'MC25_3P'}" @click="editCell(dbTableName, row.id, 'model', $event)">{{ row.model }}</td>
                <td v-if="header === 'Target'" :key="index" @click="editCell(dbTableName, row.id, 'target', $event)">{{ row.target }}</td>
                <td v-if="header === 'Input'" :key="index" >{{ row.input }}</td>
                <td v-if="header === 'OK Total'" :key="index" class="ok">{{ row.oktotal }}</td>
                <td v-if="header === 'OK A'" :key="index" class="ok" @click="editCell(dbTableName, row.id, 'ok_a', $event)">{{ row.ok_a }}</td>
                <td v-if="header === 'OK B'" :key="index" class="ok" @click="editCell(dbTableName, row.id, 'ok_b', $event)">{{ row.ok_b }}</td>
                <td v-if="header === 'OK C'" :key="index" class="ok" @click="editCell(dbTableName, row.id, 'ok_c', $event)">{{ row.ok_c }}</td>
                <td v-if="header === 'Verification'" :key="index" class="verify" :class="{ 'zero-value_verify': row.verification === 0 }" @click="editCell(dbTableName, row.id, 'verification', $event)">{{ row.verification }}</td>
                <td v-if="header === 'NG'" :key="index" class="ng" :class="{ 'zero-value_ng': row.ng === 0 }" @click="editCell(dbTableName, row.id, 'ng', $event)">{{ row.ng }}</td>
                <td v-if="header === 'FPCA Damage'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_fpcadamage === 0 }" @click="editCell(dbTableName, row.id, 'ng_fpcadamage', $event)">{{ row.ng_fpcadamage }}</td>
                <td v-if="header === 'Thermistor Damage'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_thermistordamage === 0 }" @click="editCell(dbTableName, row.id, 'ng_thermistordamage', $event)">{{ row.ng_thermistordamage }}</td>
                <td v-if="header === 'NG Rate'" :key="index" class="ng" >{{ row.ngRate }}%</td>               
                <td v-if="header === 'Remark'" :key="index" @click="editCell(dbTableName, row.id, 'remark', $event)">{{ row.remark }}</td> 
                <td v-if="header === 'Leader'" :key="index" @click="editCell(dbTableName, row.id, 'leader', $event)">{{ row.leader }}</td>                
                <td v-if="header === 'Operator Name A'" :key="index" @click="editCell(dbTableName, row.id, 'operator_name_a', $event)">{{ row.operator_name_a }}</td>                 
                <td v-if="header === 'Operator Name B'" :key="index" @click="editCell(dbTableName, row.id, 'operator_name_b', $event)">{{ row.operator_name_b }}</td>                 
                <td v-if="header === 'Operator Name C'" :key="index" @click="editCell(dbTableName, row.id, 'operator_name_c', $event)">{{ row.operator_name_c }}</td>                 
                <td v-if="header === 'Work Time'" :key="index" @click="editCell(dbTableName, row.id, 'work_minute', $event)">{{ row.work_minute }}</td> 
                <td v-if="header === 'row_no'" :key="index" @click="editCell(dbTableName, row.id, 'row_no', $event)">{{ row.row_no }}</td>                
            </template>
        </tr>
        <tr>
         <th rowspan="2">Shift</th>
         <td>1</td>
          <td>Subtotal</td>
          <td>{{ sums.shiftSums[1].target }}</td>
          <td>{{ sums.shiftSums[1].input }}</td>
          <td>{{ sums.shiftSums[1].oktotal }}</td>
          <td>{{ sums.shiftSums[1].ok_a }}</td>
          <td>{{ sums.shiftSums[1].ok_b }}</td>
          <td>{{ sums.shiftSums[1].ok_c }}</td>
          <td>{{ sums.shiftSums[1].verification }}</td>
          <td>{{ sums.shiftSums[1].ng }}</td>
          <td>{{ sums.shiftSums[1].ng_fpcadamage }}</td>                  
          <td>{{ sums.shiftSums[1].ng_thermistordamage }}</td>                  
          <td>{{ ((sums.shiftSums[1].ng) / (sums.shiftSums[1].input) * 100).toFixed(1) }}%</td>          
        </tr>
        <tr>
          <td>2</td>
          <td>Subtotal</td>
          <td>{{ sums.shiftSums[2].target }}</td>
          <td>{{ sums.shiftSums[2].input }}</td>
          <td>{{ sums.shiftSums[2].oktotal }}</td>
          <td>{{ sums.shiftSums[2].ok_a }}</td>
          <td>{{ sums.shiftSums[2].ok_b }}</td>
          <td>{{ sums.shiftSums[2].ok_c }}</td>
          <td>{{ sums.shiftSums[2].verification }}</td>
          <td>{{ sums.shiftSums[2].ng }}</td>
          <td>{{ sums.shiftSums[2].ng_fpcadamage }}</td>                  
          <td>{{ sums.shiftSums[2].ng_thermistordamage }}</td>                                 
          <td>{{ ((sums.shiftSums[2].ng) / (sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>  
        </tr>
        <tr>
         <th rowspan="3">Model</th>
          <td>EVO 2P</td>
          <td>Subtotal</td>
          <td>{{ sums.modelSums['EVO_2P'].target }}</td>
          <td>{{ sums.modelSums['EVO_2P'].input }}</td>
          <td>{{ sums.modelSums['EVO_2P'].oktotal }}</td>
          <td>{{ sums.modelSums['EVO_2P'].ok_a}}</td>
          <td>{{ sums.modelSums['EVO_2P'].ok_b}}</td>
          <td>{{ sums.modelSums['EVO_2P'].ok_c}}</td>
          <td>{{ sums.modelSums['EVO_2P'].verification}}</td>
          <td>{{ sums.modelSums['EVO_2P'].ng}}</td>
          <td>{{ sums.modelSums['EVO_2P'].ng_fpcadamage}}</td>          
          <td>{{ sums.modelSums['EVO_2P'].ng_thermistordamage}}</td>          
          <td>{{ ((sums.modelSums['EVO_2P'].ng) / (sums.modelSums['EVO_2P'].input) * 100).toFixed(1) }}%</td>          
        </tr>
        <tr>
          <td>EVO 3P</td>
          <td>Subtotal</td>
          <td>{{ sums.modelSums['EVO_3P'].target }}</td>
          <td>{{ sums.modelSums['EVO_3P'].input }}</td>
          <td>{{ sums.modelSums['EVO_3P'].oktotal}}</td>
          <td>{{ sums.modelSums['EVO_3P'].ok_a}}</td>
          <td>{{ sums.modelSums['EVO_3P'].ok_b}}</td>
          <td>{{ sums.modelSums['EVO_3P'].ok_c}}</td>
          <td>{{ sums.modelSums['EVO_3P'].verification}}</td>
          <td>{{ sums.modelSums['EVO_3P'].ng}}</td>
          <td>{{ sums.modelSums['EVO_3P'].ng_fpcadamage}}</td>          
          <td>{{ sums.modelSums['EVO_3P'].ng_thermistordamage}}</td>   
          <td>{{ ((sums.modelSums['EVO_3P'].ng) / (sums.modelSums['EVO_3P'].input) * 100).toFixed(1) }}%</td>
        </tr>
         <tr>
          <td>MC25 3P</td>
          <td>Subtotal</td>
          <td>{{ sums.modelSums['MC25_3P'].target }}</td>
          <td>{{ sums.modelSums['MC25_3P'].input }}</td>
          <td>{{ sums.modelSums['MC25_3P'].oktotal}}</td>
          <td>{{ sums.modelSums['MC25_3P'].ok_a}}</td>
          <td>{{ sums.modelSums['MC25_3P'].ok_b}}</td>
          <td>{{ sums.modelSums['MC25_3P'].ok_c}}</td>
          <td>{{ sums.modelSums['MC25_3P'].verification}}</td>
          <td>{{ sums.modelSums['MC25_3P'].ng}}</td>
          <td>{{ sums.modelSums['MC25_3P'].ng_fpcadamage}}</td>          
          <td>{{ sums.modelSums['MC25_3P'].ng_thermistordamage}}</td>   
          <td>{{ ((sums.modelSums['MC25_3P'].ng) / (sums.modelSums['MC25_3P'].input) * 100).toFixed(1) }}%</td>
        </tr>
        <tr>
         <th colspan="3">Total</th>
          <td>{{ sums.shiftSums[1].target + sums.shiftSums[2].target }}</td>
          <td>{{ sums.shiftSums[1].input + sums.shiftSums[2].input }}</td>
          <td class="ok">{{ sums.shiftSums[1].oktotal + sums.shiftSums[2].oktotal }}</td>
          <td class="ok" >{{ sums.shiftSums[1].ok_a + sums.shiftSums[2].ok_a }}</td>
          <td class="ok" >{{ sums.shiftSums[1].ok_b + sums.shiftSums[2].ok_b }}</td>
          <td class="ok" >{{ sums.shiftSums[1].ok_c + sums.shiftSums[2].ok_c }}</td>
          <td class="verify" >{{ sums.shiftSums[1].verification + sums.shiftSums[2].verification }}</td>
          <td class="ng" >{{ sums.shiftSums[1].ng + sums.shiftSums[2].ng }}</td>
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],].reduce((sum, obj) => sum + obj.ng_fpcadamage, 0) }}</td>          
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],].reduce((sum, obj) => sum + obj.ng_thermistordamage, 0) }}</td>  
          <td class="ng">{{ ((sums.shiftSums[1].ng + sums.shiftSums[2].ng ) / (sums.shiftSums[1].input + sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>                    
        </tr>   
      </table>           
      
      <br>
      <p>Daily Report</p>
      <table id="dailyReport">
        <tr><th v-for="(drheader, index) in drHeaders" :key="index">{{ drheader }}</th></tr>      
        <tr v-for="dr in drTableData.data" :key="dr.id">
            <template v-for="(drheader, index) in drHeaders">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="drheader === 'Contents'" :key="index"  @click="editCell(drTableName, dr.id, drheader, $event)">{{ dr.contents }}</td>                
            </template>
          </tr>  
        </table>                     
      <br>
      <p>Down Time</p>
      <table id="downTime">
        <tr><th v-for="(dtheader, index) in dtHeaders" :key="index">{{ dtheader }}</th></tr>      
        <tr v-for="dt in dtTableData.data" :key="dt.id">
            <template v-for="(dtheader, index) in dtHeaders">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="dtheader === 'Shift'" :key="index"  @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.shift }}</td>
                <td v-if="dtheader === 'Model'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.model }}</td>
                <td v-if="dtheader === 'Start_time'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.start_time }}</td>
                <td v-if="dtheader === 'End_time'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.end_time }}</td>
                <td v-if="dtheader === 'Time'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.time }}</td>
                <td v-if="dtheader === 'CLASS1'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.class1 }}</td>
                <td v-if="dtheader === 'CLASS2'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.class2 }}</td>
                <td v-if="dtheader === 'CLASS3'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.class3 }}</td>
                <td v-if="dtheader === 'Detail'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.detail }}</td>                                
                <td v-if="dtheader === 'Note'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.note }}</td>   
            </template>
          </tr>  
        </table>   
      </div>                  
    
      <!--<Modal v-if="showModal" @onClose="showModal = false"/> -->
      <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();" :line-name="lineName"/>      
      <dtModal  v-if="showdtModal" @onClose="showdtModal = false; callSearchData();" :line-name="lineName"/>         
      <drModal  v-if="showdrModal" @onClose="showdrModal = false; callSearchData();" :line-name="lineName"/>       
      <rdModal  v-if="showrdModal" @onClose="showrdModal = false; callSearchData();" :line-name="lineName"/> 
  </div>
</template>

<script>

import { reactive, ref, computed, } from 'vue';
import { downloadExcel, editCellValue} from "../../utils.js";
import { searchData, calculateSums } from "../productionUtils.js";
import { targets } from "../../lineData.js";

import Modal from "./thermistorPad_prm.vue";
import dtModal from "./thermistorPad_prdtm.vue";
import drModal from "./thermistorPad_prdrm.vue";
import rdModal from "./thermistorPad_rdm.vue";

export default {      
    components: { Modal, dtModal, drModal, rdModal },  

    methods: {
      downExcel () { downloadExcel([this.prTableData.data, this.dtTableData.data, this.drTableData.data, ], "Production Data.xlsx", [], ["PR Data", "DT Data", "DR Data",]);},
      editCell(tableName, id, header, event) {
          const currentValue = event.target.innerText;
          const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);
          if (newValue !== null && newValue !== currentValue) {
              editCellValue(tableName, id, header, newValue, this.callSearchData);
          }
      },
    },  

    setup() {
        const lineName = "Thermistor Pad"  //설비 변경시 필수 교체        
        const selectedLine = targets.find(target => target.name === lineName);
        if (!selectedLine) { throw new Error(`Line name "${lineName}" not found in targets`); }

        const dbTableName = selectedLine.pr;
        const dtTableName = selectedLine.dt;
        const drTableName = selectedLine.dr;        
  
        //Production result part
        const showModal = ref(false); 
        const headers = ['Shift', 'Time', 'Model', 'Target', 'Input', 'OK Total','OK A', 'OK B', 'OK C', 'Verification',
                         'NG', "FPCA Damage", "Thermistor Damage", 'NG Rate', 'Remark', 'Leader',
                         'Operator Name A', 'Operator Name B', 'Operator Name C', 'Work Time', 'row_no']; //설비 변경시 필수 교체
        const prTableData = reactive({ data: [], });     

        //Downtime part
        const showdtModal = ref(false);  
        const dtHeaders = ['Shift', 'Model', 'Start_time', 'End_time', 'Time', 'CLASS1', 'CLASS2', 'CLASS3', 'Detail', 'Note']; 
        const dtTableData = reactive({ data: [], });      

        //Daily repot part
        const showdrModal = ref(false);    
        const drHeaders = ['Contents']; 
        const drTableData = reactive({ data: [], });      

        //QWALL part        

        //Repeat Data part
        const showrdModal = ref(false);     
   
        //Current Time part
        
        const searchDate = ref({ year: '', month: '', day: '', });
        const sendDate = async () => {const sendDateData = [searchDate.value.year, searchDate.value.month, searchDate.value.day]; return {sendDateData}; };            

        const calculateInputAndNgRate = (ok_a, ok_b, ok_c, ng, ) => {
          const input = ok_a + ok_b + ok_c + ng;          
          const oktotal = ok_a + ok_b + ok_c;          
          let ngRate = 0;          
          if (ng !== 0) { ngRate = ((ng / input) * 100).toFixed(1); }          
          return { input, oktotal, ngRate, };
        };

        const item = computed(() => {
          return prTableData.data.map(d => { const { input, oktotal, ngRate, } = calculateInputAndNgRate(d.ok_a, d.ok_b, d.ok_c, d.ng,);
          return { ...d, input, oktotal, ngRate, }; });
        });        

        const callSearchData = async () => {
          try {
          const { sendDateData } = await sendDate();
          const tableNames = [dbTableName, dtTableName, drTableName ];
          const result = await searchData(tableNames, sendDateData);
          
          prTableData.data = result.prTableData;
          dtTableData.data = result.dtTableData;
          drTableData.data = result.drTableData;
          
          } catch (error) {
          console.error("Error fetching data:", error.message);
          }
      };  

        //데이터 더하기 위한 Template, shift, model 설정
        const sumsTemplate = {
          target: 0, input: 0, oktotal: 0, ok_a: 0, ok_b: 0, ok_c: 0, verification: 0, ng: 0, ng_fpcadamage: 0, ng_thermistordamage: 0
        };

        const shiftKeys = [1, 2]; 
        const modelKeys = ["EVO_2P", "EVO_3P", "MC25_3P"];

        const sums = computed(() => calculateSums(item.value, sumsTemplate, shiftKeys, modelKeys,));
        //끝

        return { calculateInputAndNgRate, headers, showModal, dtHeaders, dtTableData, 
                 showdtModal,  item, dbTableName, prTableData, searchData, searchDate, lineName, 
                 showdrModal, drHeaders, drTableData, sums, callSearchData, downloadExcel,
                 dtTableName, drTableName, sendDate,
                 showrdModal,
                 };        
    },
}
</script>

<style scoped src="../0_formStyle/line/style_line_pr.css"></style>