<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Modify Invest</h3>           
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">          
          <form @submit.prevent="submitForm">
            <table>
              <tr>
                <td>                          
                <table class="itemTable">
                  <tbody>
                  <tr style="border: 1px solid rgb(156, 156, 156);">
                    <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Date</td>
                    <td style="text-align: left;">
                      <input class="dateInput" type="text" v-model="formInputs.year" placeholder="yyyy" />
                      <input class="dateInput" type="text" v-model="formInputs.month" placeholder="mm" />
                      <input class="dateInput" type="text" v-model="formInputs.day" placeholder="dd" />                  
                    </td>        
                  </tr>            
                  <tr style="border: 1px solid rgb(156, 156, 156);">
                    <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Category</td>
                    <td style="text-align: left;">
                      <select class="classInput" v-model="formInputs.category">
                        <option>Current</option><option>Expansion</option><option>IT</option>
                      </select>                                    
                    </td>   
                  </tr>
                  </tbody>
                </table><br>

                <table class="itemTable">
                  <tbody>
                  <tr style="border: 1px solid rgb(156, 156, 156);">
                    <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Invest ID</td>                  
                    <td style="text-align: left;">
                      <input type="text" class="readWindow" v-model="formInputs.investid" readonly />                  
                    </td> 
                  </tr>     
                  <tr style="border: 1px solid rgb(156, 156, 156);">                
                    <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Item</td>   
                    <td style="text-align: left;">
                      <input class="classInput" :class="{ readWindow: formInputs.subitem !== null && formInputs.subitem !== '' }"
                       type="text" v-model="formInputs.item" :readonly="formInputs.subitem !== null && formInputs.subitem !== ''"/>
                    </td>
                  </tr>                       
                  </tbody>
                </table><br>

                <table class="itemTable">
                  <tbody>   
                  <tr style="border: 1px solid rgb(156, 156, 156);">                
                    <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Sub Item</td>   
                    <td style="text-align: left;">
                      <input class="classInput" :class="{ readWindow: formInputs.subitem === null || formInputs.subitem === '' }"
                       type="text" v-model="formInputs.subitem" :readonly="formInputs.subitem === null || formInputs.subitem === ''"/>
                    </td>
                  </tr>    
                  <tr style="border: 1px solid rgb(156, 156, 156);">                
                    <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Company</td>                   
                    <td style="text-align: left;"><input class="classInput" type="text" v-model="formInputs.company" /></td>
                  </tr>              
                  <tr style="border: 1px solid rgb(156, 156, 156);">                              
                    <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Department</td>
                    <td style="text-align: left;"><input class="classInput" type="text" v-model="formInputs.department" /></td>                    
                  </tr>
                  <tr style="border: 1px solid rgb(156, 156, 156);">                
                    <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Reason</td>   
                    <td style="text-align: left;"><input class="classInput" type="text" v-model="formInputs.reason" /></td>                    
                  </tr>
                  </tbody>
                </table><br>

                <table class="itemTable">
                  <tbody>               
                  <tr style="border: 1px solid rgb(156, 156, 156);">
                    <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">File Upload</td>
                    <td style="text-align: left;">
                      <input type="file" @change="handleFileUpload('file')($event)" /><br>
                      <button class="fileUploadButton" type="button" @click="fileUpload('file')">File Upload</button><br>
                      {{ fileSaveStatus.file }}
                    </td>
                  </tr>              
                  </tbody>
                </table><br>  
                </td>

                <td style="vertical-align: top;">         
                <table style="margin-left:10px" class="itemTable">
                  <tbody>
                  <tr style="border: 1px solid rgb(156, 156, 156);">                
                    <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Carry Over Cost</td>   
                    <td style="text-align: left;"><input class="classInput" type="text" v-model="formInputs.carryoverprice" /></td>
                  </tr>
                  <tr style="border: 1px solid rgb(156, 156, 156);">       
                    <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">New Cost</td>                   
                    <td style="text-align: left;"><input class="classInput" type="text" v-model="formInputs.newprice" /></td>              
                  </tr>
                  <tr style="border: 1px solid rgb(156, 156, 156);">       
                    <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Offer Cost</td>
                    <td style="text-align: left;"><input class="classInput" type="text" v-model="formInputs.offer" /></td>                
                  </tr>
                  </tbody>
                </table><br>   

                <table style="margin-left:10px" class="itemTable">
                  <tbody>
                  <tr style="border: 1px solid rgb(156, 156, 156);">
                    <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Report Date</td>
                    <td style="text-align: left;">
                      <input class="dateInput" type="text" v-model="reportDateInput.reyear" placeholder="yyyy" />
                      <input class="dateInput" type="text" v-model="reportDateInput.remonth" placeholder="mm" />
                      <input class="dateInput" type="text" v-model="reportDateInput.reday" placeholder="dd" />                  
                    </td>        
                  </tr>     
                  <tr style="border: 1px solid rgb(156, 156, 156);">
                    <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">PR Number</td>
                    <td style="text-align: left;"><input class="classInput" type="text" id="pr" v-model="formInputs.pr" /></td>
                  </tr>
                  <tr style="border: 1px solid rgb(156, 156, 156);">
                    <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">PO Number</td>
                    <td style="text-align: left;"><input class="classInput" type="text" id="pr" v-model="formInputs.po" /></td>
                  </tr>
                  <tr style="border: 1px solid rgb(156, 156, 156);">
                    <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Asset Number</td>
                    <td style="text-align: left;"><input class="classInput" type="text" id="pr" v-model="formInputs.asset" /></td>
                  </tr>
                  </tbody>
                </table><br>    
                
                <table style="margin-left:10px" class="itemTable">
                  <tbody>               
                  <tr style="border: 1px solid rgb(156, 156, 156);">
                    <td style="background-color: #eaeaea; border: 0; padding: 5px 5px;">Remark</td>
                    <td style="text-align: left;"> <textarea id="remark" v-model="formInputs.remark" rows="4" cols="40"></textarea></td>
                  </tr>              
                  </tbody>
                </table><br>  
              </td>           
             </tr>
          </table>        
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}            
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>    
  import { reactive, watch, ref  } from 'vue';  
  import axios from 'axios';    
  import { uploadFile, fileDel, getColumnMapping, makeFormData, useFormInputs } from "../utils.js";   
   
  export default {            
    setup(props, { emit }) {   
        const dbTableName = "invest"    
        const saveStatus = ref(''); 
        const isProcessing = ref(false);   
        const selectedFiles = reactive({ file: null });     
        const fileSaveStatus = reactive({ file: '' });                     
        const uploadType = 'invest';      
        const reportDateInput = reactive({ reyear: '', remonth: '', reday: '', });     

        const { formInputs, dataForm } = useFormInputs({ file: "", filepath: "", reportdate: "", serialnumber: "", subserialnumber: "" });    

        const handleFileUpload = (type) => (event) => { selectedFiles[type] = event.target.files[0];};

        const submitForm = async () => {       
          isProcessing.value = true; 
          saveStatus.value = 'Processing...';

          formInputs.reportdate = `${reportDateInput.reyear}-${reportDateInput.remonth}-${reportDateInput.reday}`;          

          const columnMapping = getColumnMapping(undefined, formInputs, [], true);
          const formData = makeFormData(undefined, formInputs, dbTableName, columnMapping, true);
          const id = formInputs.id;
          if (!id) { alert("ID is missing"); return; }

         try {
            await axios.put(`/api/materialput/${id}`, formData);    
            saveStatus.value = 'Completed'; 
          } catch (error) {
            console.error('Error during submission:', error);
            saveStatus.value = 'Error'; 
          } finally {
            isProcessing.value = false;
          }         
              
         closeModal();
        };
        const closeModal = () => { emit("onClose"); };  
        
        const fetchSelectedRowData = async () => {
            try {
              const response = await axios.get("/api/materialrequestadd", {
                params: { tableName: dbTableName, id: props.rowId },
              });
              const rowData = response.data[0];    
              
              const splitDate = (dateString) => {
                return dateString && dateString.includes('-') ? dateString.split('-') : ['', '', ''];
              };

              const [reyear, remonth, reday] = splitDate(rowData.reportdate);                        

              formInputs.id = rowData.id,
              formInputs.investid = rowData.investid,
              formInputs.year = rowData.year,
              formInputs.month = rowData.month,
              formInputs.day = rowData.day,
              formInputs.category = rowData.category,
              formInputs.reason = rowData.reason,
              formInputs.item = rowData.item,
              formInputs.subitem = rowData.subitem,
              formInputs.company = rowData.company,
              formInputs.department = rowData.department,
              reportDateInput.reyear = reyear,
              reportDateInput.remonth = remonth,
              reportDateInput.reday = reday,
              formInputs.pr = rowData.pr,
              formInputs.po = rowData.po,
              formInputs.asset = rowData.asset,
              formInputs.carryoverprice = rowData.carryoverprice,
              formInputs.newprice = rowData.newprice,
              formInputs.offer = rowData.offer,              
              formInputs.file = rowData.file,
              formInputs.filepath = rowData.filepath,
              formInputs.remark = rowData.remark   

            } catch (error) {
              console.error("Error fetching row data:", error.message);
            }
          };
          watch( () => props.rowId, () => { fetchSelectedRowData(); }, { immediate: true } );  

        const fileUpload = (type) => { if (formInputs[`${type}path`]) { alert("First delete file");return; }
            uploadFile(type, selectedFiles[type], uploadType, formInputs, fileSaveStatus, isProcessing);
      };
        const delFile = (type) => fileDel(type, formInputs, fileSaveStatus);  

      return { dbTableName, formInputs, submitForm, closeModal, saveStatus, isProcessing,
               selectedFiles, handleFileUpload, fileSaveStatus, fileUpload, delFile, dataForm,
               reportDateInput,
      };

    },     
    props: { rowId: { type: Number, required: true, }, },
};  
</script>
    
<style scoped>
  /* Reset */
  * { margin: 0; padding: 0; box-sizing: border-box; }

  /* Modal styles */
  .modal-mask {
    position: fixed; z-index: 9998;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
    display: flex; justify-content: flex-start; align-items: flex-start; 
  }     
  .modal-wrapper { width: 100%;  max-width: 700px; }  
  .modal-container {
    background-color: #ffffff;
    border-radius: 12px; padding: 20px;
    max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
  }  
  .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  .modal-header h3 { font-size: 1.2rem; }
  .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  
  label { font-weight: bold; }
  input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }

  button[type="submit"] {
    background-color: #4CAF50; color: white;
    padding: 5px 20px;
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 16px;
    display: inline-block; transition-duration: 0.4s;
  }

  button[type="submit"]:hover { background-color: #45a049; }

  .fileUploadButton {
    background-color: #c6c6c6; color: rgb(0, 0, 0);
    padding: 5px 5px; margin-top: 5px; 
    border: none; border-radius: 4px; cursor: pointer;
    text-align: center; text-decoration: none; font-size: 12px; font-weight: bold;
    display: inline-block; transition-duration: 0.4s;    
  }
  .fileUploadButton:hover { background-color: #5f5f5f; }  
  .itemInput { margin-left: 5px; margin-right: 5px; width: 90%; } 
  .readWindow[readonly] { background-color: #f0f0f0;  margin-left: 5px; margin-right: 5px; width: 200px; }
  .itemTable tr td {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
  .itemTable th {text-align: center; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
  .classInput { margin-left: 5px; margin-right: 5px; width: 200px; } 
  .factoryInput { margin-left: 5px; margin-right: 5px; width: 70px; } 
  .locationInput { margin-left: 5px; margin-right: 5px; width: 350px; }       
  .dateInput { width: 59px; size: 8px; margin-left: 10px; }  
  .timeInput { width: 80px; size: 8px; margin-left: 10px; }      
  .marginLeft {margin-left: 20px;}
  .fileSign {padding: 0px 30px; vertical-align: top;}
  .uploadTable tr td {text-align: left; font-weight: bold; border: 1px solid rgb(156, 156, 156); padding: 5px 5px; }
  .uploadTable th {text-align: left; font-weight: bold; border: 1px solid rgb(156, 156, 156); background-color: #eaeaea; padding: 5px 5px; }
  .uploadTd { background-color: #eaeaea; text-align: center !important}
 
  .disabled { background-color: #ccc; cursor: not-allowed; }
  .addLineButton {margin-left: 10px; margin-right: 10px; padding: 0px 10px; border: 0px; background-color: rgb(77, 69, 195); color:white; font-weight: bold; font-size: 15px; text-align: center }
  .failurestandard {margin-left:10px; font-weight: bold};    
</style>