<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <table>
            <tr>
              <td><h3>Add Production Result</h3></td>
              <td><button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button></td>              
              <td>{{ saveStatus }} </td>             
            </tr>
          </table>  
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -시작- -->
            <table>
              <tr>
                <td>
            <div class="form-group date-time-group">
              <label>Year/Month/Day:</label>
              <div class="date-time-inputs">
                <input type="text" id="year" v-model="searchDate.year"  />
                <input type="text" id="month" v-model="searchDate.month" />
                <input type="text" id="day" v-model="searchDate.day" />                  
              </div> 
            </div>                                   
            <div class="form-group">
              <label for="shift">Shift:</label>
              <input type="number" id="shift" v-model="formInputs.shift" readonly class="readWindow" />
            </div>            
            <div class="form-group">
              <label for="start_time">Start Time:</label>              
              <select id="start_time" v-model="formInputs.start_time">            
                <option>01</option><option>02</option><option>03</option><option>04</option>
                <option>05</option><option>06</option><option>07</option><option>08</option>
                <option>09</option><option>10</option><option>11</option><option>12</option>
                <option>13</option><option>14</option><option>15</option><option>16</option>
                <option>17</option><option>18</option><option>19</option><option>20</option>
                <option>21</option><option>22</option><option>23</option><option>24</option>
              </select>      
            </div>
            <div class="form-group">
              <label for="end_time">End Time:</label>
              <select id="end_time" v-model="formInputs.end_time" readonly class="readWindow">            
                <option>01</option><option>02</option><option>03</option><option>04</option>
                <option>05</option><option>06</option><option>07</option><option>08</option>
                <option>09</option><option>10</option><option>11</option><option>12</option>
                <option>13</option><option>14</option><option>15</option><option>16</option>
                <option>17</option><option>18</option><option>19</option><option>20</option>
                <option>21</option><option>22</option><option>23</option><option>24</option>
              </select>
            </div>
            <div class="form-group">
              <label for="work_minute">Work Time(minute):</label>
              <select id="work_minute" v-model="formInputs.work_minute">            
                <option>5</option><option>15</option><option>30</option><option>45</option><option>60</option>    
              </select>                            
            </div>
          </td>
          <td>
            <div class="form-group">
              <label for="model">Model:</label>
              <select id="model" v-model="formInputs.model">            
                <option>60kW Front</option>
              </select>              
            </div>
            <div class="form-group">
              <label for="target">Target:</label>
              <input type="number" id="target" v-model="formInputs.target" readonly class="readWindow" />
            </div>                
            <div class="form-group">
              <label for="ok">OK:</label>
              <input type="number" id="ok" v-model="formInputs.ok" />
            </div>  
          </td>
          <td>
            <div class="form-group">
              <label for="ng">NG:</label>
              <input class="readWindow" readonly type="number" id="ng" v-model="formInputs.ng" />
            </div>            
            <div class="form-group">
              <label for="ng_busbarassembly">NG BUSBAR ASSEMBLY:</label>
              <input type="number" id="ng_busbarassembly" v-model="formInputs.ng_busbarassembly"  />
            </div>
            <div class="form-group">
              <label for="ng_heatstacking">NG HEAT STACKING:</label>
              <input type="number" id="ng_heatstacking" v-model="formInputs.ng_heatstacking" />
            </div>
            <div class="form-group">
              <label for="ng_eol">NG EOL:</label>
              <input type="number" id="ng_eol" v-model="formInputs.ng_eol"  />
            </div>
            <div class="form-group">
              <label for="ng_laserwelding">NG LASER WELDING:</label>
              <input type="number" id="ng_laserwelding" v-model="formInputs.ng_laserwelding" />
            </div>
            <div class="form-group">
              <label for="ng_slot">NG SLOT:</label>
              <input type="number" id="ng_slot" v-model="formInputs.ng_slot" />
            </div>
            <div class="form-group">
              <label for="ng_holepositionjig">NG HOLE POSITION JIG:</label>
              <input type="number" id="ng_holepositionjig" v-model="formInputs.ng_holepositionjig" />
            </div>
            <div class="form-group">
              <label for="ng_finalinspection">NG FINAL INSPECTION:</label>
              <input type="number" id="ng_finalinspection" v-model="formInputs.ng_finalinspection"  />
            </div>
            <div class="form-group">
              <label for="ng_others">NG OTHERS:</label>
              <input type="number" id="ng_others" v-model="formInputs.ng_others" />
            </div>
            <div class="form-group">
              <label for="ng_weldingpeeltest">NG WELDING PEEL TEST:</label>
              <input type="number" id="ng_weldingpeeltest" v-model="formInputs.ng_weldingpeeltest" />
            </div>
            <div class="form-group">
              <label for="ng_test">Test:</label>
              <input type="number" id="ng_test" v-model="formInputs.ng_test" />
            </div>
          </td>          
          <td>
            <div class="form-group">
              <label for="fn_heatstackingvision">FAKE NG HEAT STACKING VISION:</label>
              <input type="number" id="fn_heatstackingvision" v-model="formInputs.fn_heatstackingvision" />
            </div>
            <div class="form-group">
              <label for="fn_laservision">FAKE NG LASER VISION:</label>
              <input type="number" id="fn_laservision" v-model="formInputs.fn_laservision" />
            </div>            
            <div class="form-group">
              <label for="fn_eol">FAKE NG EOL:</label>
              <input type="number" id="fn_eol" v-model="formInputs.fn_eol" />
            </div>
            <div class="form-group">
              <label for="semi_hookbroken">SEMI HOOK BROKEN:</label>
              <input type="number" id="semi_hookbroken" v-model="formInputs.semi_hookbroken" />
            </div>            
            <div class="form-group">
              <label for="semi_fpcbending">SEMI FPCB BENDING:</label>
              <input type="number" id="semi_fpcbending" v-model="formInputs.semi_fpcbending" />
            </div>            
            <div class="form-group">
              <label for="semi_etc">SEMI ETC:</label>
              <input type="number" id="semi_etc" v-model="formInputs.semi_etc" />
            </div>   
          </td>
          <td>
            <div class="form-group">
              <label for="workorder">Work Order:</label>
              <input type="text" id="workorder" v-model="formInputs.workorder"  />
            </div>
            <div class="form-group">
              <label for="supervisor">Supervisor:</label>
              <input type="text" id="supervisor" v-model="formInputs.supervisor" />
            </div>
            <div class="form-group">
              <label for="leader">Leader:</label>
              <input type="text" id="leader" v-model="formInputs.leader" />
            </div>
            <div class="form-group">
              <label for="technician">Technician:</label>
              <input type="text" id="technician" v-model="formInputs.technician" />
            </div>
            <div class="form-group">
              <label for="shift_name">Shift Name:</label>
              <input type="text" id="shift_name" v-model="formInputs.shift_name" />
            </div>
            <div class="form-group">
              <label for="operator_qty">Operator Quantity:</label>
              <input type="number" id="operator_qty" v-model="formInputs.operator_qty" />
            </div>
            <div class="form-group">
              <label for="row_no">Row_no:</label>
              <input type="number" id="row_no" v-model="formInputs.row_no" readonly class="readWindow" />
            </div>   
          </td>
        </tr>
        </table>             
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -끝- -->
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }}                            
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>  
  import { reactive, watch, computed, ref } from 'vue';  
  import axios from 'axios';  
  import { calculateTotalNg, callTargetProduct } from '../productionUtils.js';

  export default {    
    props: { onSubmit: { type: Function, required: true }, lineName: { type: String, required: true } },
    setup(props, { emit }) {
        //Database Table 이름. 이것에 따라서 api에서 요청하는 이름이 달라짐
        const dbTableName = "ev2020_60frpr"
        const lineName = "EV2020 60FR"
        const saveStatus = ref(''); 
        const isProcessing = ref(false);   

        //년.월.일을 제외하고는 다 여기에 입력해줘야 함 -시작-
        const formInputs = reactive({
          shift: "",
          start_time: "",
          end_time: "",
          work_minute: "",
          model: "",
          target: "",        
          ok: "",               
          ng: "0",
          ng_busbarassembly: "0",
          ng_heatstacking: "0",
          ng_eol: "0",
          ng_laserwelding: "0",
          ng_slot: "0",
          ng_holepositionjig: "0",
          ng_finalinspection: "0",
          ng_others: "0",
          ng_weldingpeeltest: "0",
          ng_test: "0",
          fn_heatstackingvision: "0",
          fn_laservision: "0",
          fn_eol: "0",
          semi_hookbroken: "0",
          semi_fpcbending: "0",
          semi_etc: "0",                              
          workorder: "",
          supervisor: "",
          leader: "",
          technician: "",
          shift_name: "",
          operator_qty: "",
          row_no: "",          
        });
        // -끝-
        calculateTotalNg(formInputs);

        //시작시간에 따라서 shift와 row_no 자동 입력 -시작-
        watch(() => formInputs.start_time, (newValue) => {
          const start_time_num = parseInt(newValue, 10);
          formInputs.shift = start_time_num >= 6 && start_time_num <= 17 ? 1 : 2;

          if (start_time_num >= 6 && start_time_num <= 24) {
            formInputs.row_no = start_time_num - 5;
          } else if (start_time_num >= 1 && start_time_num <= 5) {
            formInputs.row_no = start_time_num + 19;
          }
        });
        //끝

        //target DB table에서 product 값을 가져와 가동분에 곱해 target값 계산 -시작- 
        
        const targetProduct = callTargetProduct(props.lineName, formInputs);      
                
       //target DB table에서 product 값을 가져와 가동분에 곱해 target값 계산 -끝- 
      
      //년.월.일을 지금 시작으로 자동 입력해 주는거 당일 아침 6시부터 명일  아침 5시59분까지 같은 날로 취급
      //시작
         const getCurrentDate = () => {
          let today = new Date();          
          const day = today.getDate();
          const hour = today.getHours();          

          if (hour < 6) {
            today.setDate(day - 1);
          }

          const adjustedYear = today.getFullYear();
          const adjustedMonth = today.getMonth() + 1;
          const adjustedDay = today.getDate();

          return { year: adjustedYear, month: adjustedMonth, day: adjustedDay };
        };

        const searchDate = reactive({
          ...getCurrentDate(),
        });        
        //끝

        //반복 입력되는 부분 불러오기 -시작- 
  
        const repeadtData = reactive({ data: [], })     
        const rdItem = computed(() => {return repeadtData.data.map(d => {return { ...d,};});});        
        watch(
            () => formInputs.shift,
            (newShift) => {
              if (newShift) {
                const repeatFormData = {
                  tableName: "repeatdata",
                  values: {
                    year: searchDate.year,
                    month: searchDate.month,
                    day: searchDate.day,
                    line_name: lineName,
                    shift: newShift,
                  },
                };
                axios
                  .get('/api/repeatData', { params: repeatFormData })
                  .then((res) => {
                    console.log('Response from /api/repeatData:', res.data);
                    repeadtData.data = res.data;
                  })
                  .catch((error) => {
                    console.error('Error fetching target data:', error.message);
                  });
              }
            }
          );

          watch(
            () => rdItem.value,
            (newRdItem) => {
              if (newRdItem && newRdItem.length > 0) {
                const lastItem = newRdItem[newRdItem.length - 1];
                formInputs.supervisor = lastItem.supervisor;
                formInputs.leader = lastItem.leader;
                formInputs.technician = lastItem.technician;
                formInputs.shift_name = lastItem.shiftname;
                formInputs.operator_qty = lastItem.operatorqty;
                formInputs.workorder = lastItem.workorder;
              }
            }
          );

        //반복 입력되는 부분 불러오기 -끝- 

        //input, select에 있는 id 이름과 database table 이름을 맞추는거, 
        //그래서 필히 id 이름이 database table 이름과 동일해야 함
        //시작 

        const getColumnMapping = () => {
          const inputs = document.querySelectorAll("input, select");
          const mapping = {};

          inputs.forEach(input => {
            mapping[input.id] = input.id;
          });

         return mapping;
        };

        //끝 

        //api-server.js로 data 저장 요청하는거 values: 안에는 table에 있는 이름으로 다 적어줘야 함 -시작-
        const submitForm = async () => {
          if (!searchDate.year || !searchDate.month || !searchDate.day || !formInputs.model) {
            alert("Please fill in all the fields: year, month, day and model.");
            return;
          }

          isProcessing.value = true; // 작업 시작 전 isProcessing을 true로 설정
          saveStatus.value = 'Processing...';

          const columnMapping = getColumnMapping();

          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year,
              month: searchDate.month,
              day: searchDate.day,
              shift: formInputs.shift,
              start_time: formInputs.start_time,
              end_time: formInputs.end_time,
              work_minute: formInputs.work_minute,
              model: formInputs.model,
              target: formInputs.target,        
              ok: formInputs.ok,                  
              ng: formInputs.ng,
              ng_busbarassembly: formInputs.ng_busbarassembly,
              ng_heatstacking: formInputs.ng_heatstacking,
              ng_eol: formInputs.ng_eol,
              ng_laserwelding: formInputs.ng_laserwelding,
              ng_slot: formInputs.ng_slot,
              ng_holepositionjig: formInputs.ng_holepositionjig,
              ng_finalinspection: formInputs.ng_finalinspection,
              ng_others: formInputs.ng_others,
              ng_weldingpeeltest: formInputs.ng_weldingpeeltest,
              ng_test: formInputs.ng_test,
              fn_heatstackingvision: formInputs.fn_heatstackingvision,
              fn_laservision: formInputs.fn_laservision,
              fn_eol: formInputs.fn_eol,
              semi_hookbroken: formInputs.semi_hookbroken,
              semi_fpcbending: formInputs.semi_fpcbending,
              semi_etc: formInputs.semi_etc,              
              workorder: formInputs.workorder,
              supervisor: formInputs.supervisor,
              leader: formInputs.leader,
              technician: formInputs.technician,
              shift_name: formInputs.shift_name,
              operator_qty: formInputs.operator_qty,
              row_no: formInputs.row_no,    
            }
         };
         
         try {
            await axios.post('/api/post', formData);
            saveStatus.value = 'Completed'; // 성공적으로 데이터 전송 완료
          } catch (error) {
            console.error('Error during submission:', error);
            saveStatus.value = 'Error'; // 오류 발생 시 상태 업데이트
          } finally {
            isProcessing.value = false; // 작업 완료 후 isProcessing을 false로 설정
          }
         
          closeModal();
        };

        const closeModal = () => {
          emit("onClose");
        };

      //api-server.js로 data 저장 요청하는거 values: 안에는 table에 있는 이름으로 다 적어줘야 함 -끝-   
      
      watch(
        () => formInputs.start_time,
        (newStartTime) => {
          const startTime = parseInt(newStartTime, 10);
          if (startTime < 24) {
            formInputs.end_time = (startTime + 1).toString().padStart(2, '0');
          } else {
            formInputs.end_time = '01';
          }
        }
      );

      return { searchDate, dbTableName, formInputs, submitForm, closeModal, targetProduct, saveStatus, isProcessing };

    },     
  };   
  </script>
    
    <style scoped>
    /* Reset */
    * { margin: 0; padding: 0; box-sizing: border-box; }
  
    /* Modal styles */
    .modal-mask {
      position: fixed; z-index: 9998;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
      display: flex; justify-content: flex-start; align-items: flex-start; 
    }   
    
    .modal-wrapper { width: 100%;  max-width: 1000px; }
    
    .modal-container {
      background-color: #ffffff;
      border-radius: 12px; padding: 20px;
      max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
    }
    
    .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  
    .modal-header h3 { font-size: 1.2rem; }
  
    .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  
    .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }
  
    label { font-weight: bold; }
  
    input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
  
    input[type="number"] { width: 100%;}  
  
    .readWindow[readonly] { background-color: #f0f0f0; }
  
    button[type="submit"] {
      background-color: #4CAF50; color: white;
      padding: 5px 20px;
      border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 16px;
      display: inline-block; transition-duration: 0.4s;
    }
  
    button[type="submit"]:hover { background-color: #45a049; }
  
    table { table-layout: fixed; width: 100%; }
  
    td { width: 20%; vertical-align: top; padding: 0 15px; }
    
    .time-inputs { display: flex; gap: 5px; }
    .time-inputs input[type="number"] { width: 50px; }
    .date-time-inputs { display: flex; gap: 5px;}
    .date-time-inputs input { width: 50px; size: 8px; }
  
    .ng-dropdown-container { position: relative; display: inline-block; }
    .ng-dropdown-button { font-size: 12px; padding: 2px 4px; margin-left: 4px;}
    .ng-dropdown-menu { 
      position: absolute; padding: 4px;
      left: 0; top: 100%; z-index: 10; 
      background-color: white; border: 1px solid #ccc;  
    }
    .ng-dropdown-menu > div { cursor: pointer; padding: 2px 8px; }
    .ng-dropdown-menu > div:hover { background-color: #f5f5f5; }
  
    .ngmaintype{ max-width: 120px; min-width: 120px;}
    .disabled { background-color: #ccc; cursor: not-allowed; }
  </style>