<template>  
  <div>
    <table class="searchTable" style="width: 400px">
      <tr>
        <td>
        <button class="addButton" @click="showModal = true">+ Add</button>   
        </td>
      <td>
        <label for="file-input" class="custom-file-upload"><b>Choose file</b></label>                
        <input id="file-input" type="file" @change="fileChange" accept=".xlsx, .xls" style="display: none" />
      </td>      
      <td>     
        <button class="excelUpload" @click="upExcel('selectoption')">Upload</button>
      </td>
      <td>
        <button class="excelDownload" @click="downExcel">Down Load</button>
      </td>
      </tr>
      <tr>
        <td>{{ fileName }}</td>
      </tr>
    </table>
  </div>  
  <div class="tableWrapper">
    <table class="contentsTable" style="width: 95%;">     
      <thead class="contentsTableTh">         
        <td>Material Type</td><td>Position</td><td>Process</td><td>ROW NO</td><td>DEL</td>      
    </thead>            
    <tbody>
      <tr class="contentsTableTr" v-for="row in item" :key="row.id">          
        <td @click="editCell(dbTableName, row.id, 'materialtype', $event)">{{ row.materialtype }}</td>
        <td @click="editCell(dbTableName, row.id, 'position', $event)">{{ row.position }}</td>
        <td @click="editCell(dbTableName, row.id, 'process', $event)">{{ row.process }}</td>
        <td @click="editCell(dbTableName, row.id, 'row_no', $event)">{{ row.row_no }}</td>
        <td><button :class="['delButton', { disabledButton: !canDelClick() }]" :disabled="!canDelClick()" type="submit" @click="delRow(dbTableName, row.id)">DEL</button></td>
    </tr>
  </tbody>     
  </table> 
  </div>    
  <Modal  v-if="showModal" @onClose="showModal = false; callSearchData();"/>       
</template>

<script>
import { reactive, computed, onMounted, ref } from 'vue';
import Modal from "./selectOptionModal.vue";
import axios from "axios";
import { onFileChange, uploadExcel, deleteRow, downloadExcel, editCellValue, } from "../../utils.js"; 

export default {         
    components: { Modal, },    
    data() { return { excelFile: null, fileName: "", };},
    methods: {    
      fileChange(event) { const { file, fileName } = onFileChange(event); this.excelFile = file; this.fileName = fileName; },
      upExcel(tableName) { uploadExcel(tableName, this.excelFile, alert); },  
      downExcel() { downloadExcel(this.item, "selectOption.xlsx"); },
      delRow(tableName, rowId) { deleteRow(tableName, rowId, this.callSearchData);},
      editCell(tableName, id, header, event) {
        const currentValue = event.target.innerText;
        const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);
        if (newValue !== null && newValue !== currentValue) {
           editCellValue(tableName, id, header, newValue, this.callSearchData);
        }
      },
      canDelClick() {
        const currentUser = this.$route.meta.currentUser;        
        if (currentUser) {
            return currentUser.auth === 1 || currentUser.auth === 2 || currentUser.auth === 3  ;
        } else { return false;}
      },
    },
    setup() {   
        const dbTableName = "selectoption";                    
        const targetList = reactive({ data: [], })
        const showModal = ref(false); 

        onMounted(() => { searchData(); });   

        const searchData = async () => {         

          const formData = {tableName: dbTableName, };

          console.log('Requesting data with:', formData);

          axios.get('/api/targetList', { params: formData })
            .then((res) => {
              console.log("Response from /api/target:", res.data);
              targetList.data = res.data.sort((a, b) => a.row_no - b.row_no); 
            })
            .catch((error) => {
              console.error("Error fetching target data:", error.message);
            });
          };

        const item = computed(() => { return targetList.data.map(d => { return { ...d, };});});     
        
        const callSearchData = () => { searchData(dbTableName); };   

        return { item, targetList, dbTableName, showModal, callSearchData,  };        
    }
  }
</script>

<style scoped>
@import '../../generalStyle.css';
</style>