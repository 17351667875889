import { reactive, computed, watch, watchEffect } from 'vue';
import axios from 'axios';
import { lineData } from '../lineData';

export const searchData = async (tableNames, sendDateData) => {  
    const formData = {
        tableNames: tableNames.join(','),
        values: {
            year: sendDateData[0],
            month: sendDateData[1],
            day: sendDateData[2],
        }
    };  

    try {
        const response = await axios.get('/api/productionData', { params: formData });
        return classifyTableData(response.data, tableNames);
    } catch (error) {
        console.error("Error fetching data:", error.message);
        throw error;
    }
};

const classifyTableData = (data, tableNames) => {
    const classifiedData = {
        prTableData: [],
        dtTableData: [],
        drTableData: [],
        qwTableData: []
    };

    data.forEach(table => {
        switch (table.tableName) {
            case tableNames[0]:
                classifiedData.prTableData = table.data.sort((a, b) => a.row_no - b.row_no);
                break;
            case tableNames[1]:
                classifiedData.dtTableData = table.data;
                break;
            case tableNames[2]:
                classifiedData.drTableData = table.data;
                break;
            case tableNames[3]:
                classifiedData.qwTableData = table.data;
                break;
            default:
                console.warn(`Unknown table name: ${table.tableName}`);
                break;
        }
    });

    return classifiedData;
};

export const calculateSums = (itemData, sumsTemplate, shiftKeys, modelKeys, qwItemData = null, ) => {
  const initializeSums = (template) => {
    let sums = {};
    for (const key in template) {
      sums[key] = template[key];
    }
    return sums;
  };

  let prtotalSums = initializeSums(sumsTemplate);

  let shiftSums = {};
  for (const shift of shiftKeys) {
    shiftSums[shift] = initializeSums(sumsTemplate);
  }

  let modelSums = {};
  for (const model of modelKeys) {
    modelSums[model] = initializeSums(sumsTemplate);
  }

  let qwtotalSums = initializeSums(sumsTemplate);

  for (const row of itemData) {
    for (const key in row) {
      if (key in prtotalSums) {
        prtotalSums[key] += row[key] || 0;
      }
    }

    if (Object.prototype.hasOwnProperty.call(shiftSums, row.shift)) {
      for (const key in row) {
        if (key in shiftSums[row.shift]) {
          shiftSums[row.shift][key] += row[key] || 0;
        }
      }
    }

    if (Object.prototype.hasOwnProperty.call(modelSums, row.model)) {
      for (const key in row) {
        if (key in modelSums[row.model]) {
          modelSums[row.model][key] += row[key] || 0;
        }
      }
    }
  }

  if (qwItemData) {
    let qwmodelSums = {};
    for (const model of modelKeys) {
      qwmodelSums[model] = initializeSums(sumsTemplate);
    }

    for (const row of qwItemData) {

      for (const key in row) {
        if (key in qwtotalSums) {
          qwtotalSums[key] += row[key] || 0;
        }
      }

      if (Object.prototype.hasOwnProperty.call(qwmodelSums, row.model)) {
        for (const key in row) {
          if (key in qwmodelSums[row.model]) {
            qwmodelSums[row.model][key] += row[key] || 0;
          }
        }
      }
    }
    return { prtotalSums, shiftSums, modelSums, qwmodelSums, qwtotalSums };
  }

  return { prtotalSums, shiftSums, modelSums };
};

export function putInShiftRow(formInputs) {  //시간에 따라 shift와 Row_no 자동 입력
  watch(() => formInputs.start_time, (newValue) => {
    const start_time_num = parseInt(newValue, 10);
    formInputs.shift = start_time_num >= 6 && start_time_num <= 17 ? 1 : 2;

    if (start_time_num >= 6 && start_time_num <= 24) {
      formInputs.row_no = start_time_num - 5;
    } else if (start_time_num >= 1 && start_time_num <= 5) {
      formInputs.row_no = start_time_num + 19;
    }
  });
}

/*
export function callTargetProduct(lineName, formInputs) {
  const targetProduct = reactive({ data: [] });
  const targetTableName = 'target';
  const columnName = 'line_name';

  axios
    .get('/api/where', { params: { value: lineName, table_name: targetTableName, column_name: columnName } })
    .then((res) => {      
      targetProduct.data = res.data;
    })
    .catch((error) => {
      console.error('Error fetching target data:', error.message);
    });

  const productValue = computed(() => targetProduct.data[0]?.product || null);

  watch(
    [productValue, () => formInputs.work_minute],
    ([currentProductValue, currentWorkMinute]) => {
      if (currentProductValue && currentWorkMinute) {
        formInputs.target = parseFloat((currentProductValue * currentWorkMinute).toFixed(0));
      }
    }
  );

  return targetProduct;
}
*/

export function callTargetProduct(lineName, formInputs) {
  const targetProduct = reactive({ data: [] });
  const targetTableName = 'target';
  const columnName = 'line_name';

  axios
    .get('/api/where', { params: { value: lineName, table_name: targetTableName, column_name: columnName } })
    .then((res) => { targetProduct.data = res.data; })
    .catch((error) => { console.error('Error fetching target data:', error.message); });
  
  const productValue = computed(() => {
    if (!targetProduct.data.length) return null;
    const matchingProduct = targetProduct.data.find( (item) => (item.model === formInputs.model) || (!item.model || item.model === "ALL") );
    return matchingProduct ? matchingProduct.product : null;
  });

  watch(
    [productValue, () => formInputs.work_minute],
    ([currentProductValue, currentWorkMinute]) => {
      if (currentProductValue && currentWorkMinute) {
        formInputs.target = parseFloat((currentProductValue * currentWorkMinute).toFixed(0));
      }
    }
  );

  return targetProduct;
}


export function callRepeatData(formInputs, searchDate, lineName) {
  const repeadtData = reactive({ data: [] });
  const rdItem = computed(() => repeadtData.data.map(d => ({ ...d })));

  watch(() => formInputs.shift, (newShift) => {
    if (newShift) {
      const repeatFormData = {
        tableName: "repeatdata",
        values: {
          year: searchDate.year,
          month: searchDate.month,
          day: searchDate.day,
          line_name: lineName,
          shift: newShift,
        },
      };
      axios
        .get('/api/repeatData', { params: repeatFormData })
        .then((res) => {          
          repeadtData.data = res.data;
        })
        .catch((error) => {
          console.error('Error fetching repeat data:', error.message);
        });
    }
  });

  watch(() => rdItem.value, (newRdItem) => {
    if (newRdItem && newRdItem.length > 0) {
      const lastItem = newRdItem[newRdItem.length - 1];
      formInputs.supervisor = lastItem.supervisor;
      formInputs.leader = lastItem.leader;
      formInputs.technician = lastItem.technician;
      formInputs.shift_name = lastItem.shiftname;
      formInputs.operator_qty = lastItem.operatorqty;
      formInputs.workorder = lastItem.workorder;
    }
  });

  return repeadtData;
}

export function writeEndTime(formInputs) {
  watch(
    () => formInputs.start_time,
    (newStartTime) => {
      const startTime = parseInt(newStartTime, 10);
      if (startTime < 24) {
        formInputs.end_time = (startTime + 1).toString().padStart(2, '0');
      } else {
        formInputs.end_time = '01';
      }
    }
  );
}

export function getHeaders(lineName) {
  const formattedLineName = lineName.toLowerCase().replace(/\s+/g, '');
  return lineData[formattedLineName].pr_headers;
}

export function getSumsTemplate(lineName) {
  const formattedLineName = lineName.toLowerCase().replace(/\s+/g, '');
  return lineData[formattedLineName].pr_sum_temp;
}

export function getShiftKeys(lineName) {
  const formattedLineName = lineName.toLowerCase().replace(/\s+/g, '');
  return lineData[formattedLineName].shift;
}

export function getModelKeys(lineName) {
  const formattedLineName = lineName.toLowerCase().replace(/\s+/g, '');
  return lineData[formattedLineName].models;
}

export function getInputAndNgItemKeys(lineName) {
  const formattedLineName = lineName.toLowerCase().replace(/\s+/g, '');
  return lineData[formattedLineName].inputAndNgItem;
}

export const calculateInputAndNgRate = (items) => {
  const input = items.ok + items.ng;
  const fakeng = Object.entries(items)
    .filter(([key]) => !['ok', 'ng'].includes(key))
    .reduce((sum, [, value]) => sum + (value || 0), 0);

  let ngRate = 0;
  let fn_ngRate = 0;
  if (input !== 0) {
    ngRate = ((items.ng / input) * 100).toFixed(1);
  }
  if (input !== 0 && fakeng !== 0) {
    fn_ngRate = ((fakeng / input) * 100).toFixed(1);
  }
  return { input, ngRate, fakeng, fn_ngRate };
};

export function calculateTotalNg(formInputs) {
  watchEffect(() => {
    const ngKeys = Object.keys(formInputs).filter(key => key.startsWith('ng_'));
    formInputs.ng = ngKeys.reduce((sum, key) => {
      return sum + (parseInt(formInputs[key], 10) || 0);
    }, 0);
  });
}