<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add Q-WALL</h3>
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -시작- -->
            <table>
              <tr>
                <td>
            <div class="form-group date-time-group">
                <label>Year/Month/Day:</label>
                <div class="date-time-inputs">
                  <input type="text" id="year" v-model="searchDate.year"  />
                  <input type="text" id="month" v-model="searchDate.month" />
                  <input type="text" id="day" v-model="searchDate.day" />                  
                </div> 
            </div>                                                           
            <div class="form-group">
              <label for="model">Model:</label>
              <select id="model" v-model="formInputs.model">            
                <option v-for="model in models" :key="model">{{ model }}</option>
              </select>                 
            </div> 
          </td>
          <td>      
            <div class="form-group">
              <label for="ng">NG SUM:</label>
              <input type="number" id="ng" v-model="formInputs.ng" /> 
            </div>   
            <div class="form-group">
              <label for="ng_busbarassembly">NG Busbar assembly :</label>
              <input type="number" id="ng_busbarassembly" v-model="formInputs.ng_busbarassembly" />
            </div>        
            <div class="form-group">
              <label for="ng_pcs">NG PCS:</label>
              <input type="number" id="ng_pcs" v-model="formInputs.ng_pcs" />
            </div>
            <div class="form-group">
              <label for="ng_leaktest">NG Leak Test:</label>
              <input type="number" id="ng_leaktest" v-model="formInputs.ng_leaktest"  />
            </div>
            <div class="form-group">
              <label for="ng_harnessassembly">NG Harness assembly:</label>
              <input type="number" id="ng_harnessassembly" v-model="formInputs.ng_harnessassembly" />
            </div>      
          </td>
          <td>   
            <div class="form-group">
              <label for="ng_eol">NG EOL:</label>
              <input type="number" id="ng_eol" v-model="formInputs.ng_eol" />
            </div>
            <div class="form-group">
              <label for="ng_final">NG Final:</label>
              <input type="number" id="ng_final" v-model="formInputs.ng_final" />
            </div>    
            <div class="form-group">
              <label for="ng_final">NG OQC:</label>
              <input type="number" id="ng_final" v-model="formInputs.ng_oqc" />
            </div>                   
          </td>
          </tr>
          </table>                                
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -끝- -->
            <button type="submit" id="save">Save</button>                   
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
<script>
import { useForm } from '../0_formStyle/modal/form_qw_modal';

export default {
  props: { onSubmit: { type: Function, required: true }, lineName: { type: String, required: true } },
  setup(props, { emit }) {
    return useForm(props, emit);
  }
};
</script>
    
<style scoped src="../0_formStyle/modal/style_qw_modal.css"></style>