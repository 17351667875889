<template>
  <div class="header">    
    <nav class="menu-nav">
      <div class="menu-titles">
        <div class="title" @click="toggleMenu(1)">Product</div>        
        <div class="title" @click="toggleMenu(2)">Schedule</div>                  
        <div class="title" @click="toggleMenu(3)">Material</div>
        <div class="title" @click="toggleMenu(4)">Document</div>  
        <div class="title" @click="toggleMenu(5)">Report</div>               
        <div class="title" @click="toggleMenu(6)">Attendance</div>
        <div class="title" @click="toggleMenu(7)">Check & Parameter</div>
        <div class="title" @click="toggleMenu(8)">Maintenance</div>
        <div class="title" @click="toggleMenu(9)">Purchase</div>        
        <div class="title" @click="toggleMenu(10)">Data</div>
        <div class="title" @click="goToDashBoard">Dash Board</div>            
      </div>    
      <ul class="menu-list" v-if="activeMenu === 1">
        <li class="violet" @click="toggleSubMenu(16)">1st Production</li>
        <li class="violet" @click="toggleSubMenu(17)">2nd Production</li>
        <li class="littleviolet" @click="goToPacking">Packing</li>
        <li class="littleskyblue" @click="toggleSubMenu(18)">Inkatom</li>
        <li class="skyblue" @click="goToNgList">NG List</li>
        <li class="orange" @click="goToTargetList">Target</li>
        <li class="littlegray" @click="goToDataDelete">Data Delete</li>       
      </ul>
      <ul class="menu-list" v-if="activeMenu === 2">
        <li class="violet" @click="goToProductionPlan">Production Plan</li>
        <li class="littlegray" @click="goToSchedule">Schedule</li>       
      </ul>      
      <ul class="menu-list" v-if="activeMenu === 3"> <!--Material -->
        <li class="littlegray" @click="toggleSubMenu(19)">Material Request</li>
        <li class="littlegray" @click="goToMaterialList">Material List</li>        
        <li class="skyblue" @click="toggleSubMenu(2)">Stock</li>
        <li class="littlegray" @click="goToMovementLog">Movement Log</li>
        <li class="orange" @click="toggleSubMenu(15)">Scrap</li>        
        <li class="violet" @click="toggleSubMenu(14)">Barcode</li>        
      </ul>
      <ul class="menu-list" v-if="activeMenu === 4">
        <li class="littlegray" @click="goToToDoList">To Do List</li>
        <li class="littlegray" @click="goToProductionSpare">Production Spare</li>                
        <li class="littlegray" @click="goToLPA">LPA</li>        
        <li class="littlegray" @click="goToMasterSample">Master Sample List</li>      
        <li class="skyblue" @click="goToPfmea">PFMEA</li>      
        <li class="skyblue" @click="goToFlowChart">Flow Chart</li>        
        <li class="skyblue" @click="goToControlPlan">Control Plan</li>   
        <li class="orange" @click="goToInvestList">Invest List</li>     
        <li class="orange" @click="goToAssetList">Asset List</li>  
      </ul>   
      <ul class="menu-list" v-if="activeMenu === 5"> <!--Report -->
        <li class="orange" @click="goToShipping">Shipping</li> 
        <li class="littleskyblue" @click="goTosearchPeriod">SearchPeriod</li>
        <li class="littleskyblue" @click="goToReport01">Report</li>
        <li class="littleskyblue" @click="goToReport02">Daily Report</li>   
        <li class="littleskyblue" @click="goToMonthlyReport">Monthly Report</li>                   
        <li class="littleskyblue" @click="goToFailureReport">Failure Report</li>   
        <li class="littlegray" @click="goToCoAc">Corrective Action</li>           
        <li class="littlegray" @click="goToDocumentReport">Document Report</li>  
      </ul>      
      <ul class="menu-list" v-if="activeMenu === 6"> 
        <li class="littleskyblue" @click="toggleSubMenu(7)">Team attendance</li>        
        <li class="orange" @click="goToSearchAll">ALL List</li>   
        <li class="orange" @click="goToWeekendWork">Request Manpower</li>  
        <li class="littleviolet" @click="toggleSubMenu(8)">OS Company DB</li>       
        <li class="violet" @click="toggleSubMenu(9)">Monthly Data</li>     
        <li class="littlegray" @click="goToLineWorkerList">Line Worker List</li>
        <li class="strongskyblue" @click="toggleSubMenu(11)">Evaluation</li>
        <li class="littlegray" @click="goToLsList">LS List</li>
      </ul>
      <ul class="menu-list" v-if="activeMenu === 7">
        <li class="strongskyblue" @click="toggleSubMenu(12)">1st Check Sheet</li>
        <li class="violet" @click="toggleSubMenu(3)">2nd Check Sheet</li> 
        <li class="skyblue" @click="toggleSubMenu(4)">Parameter</li>  
        <li class="littleviolet" @click="toggleSubMenu(5)">Cleaning</li>          
        <li class="littlegray" @click="goToTakeOver">Take Over</li>        
        <li class="violet" @click="toggleSubMenu(23)">QA</li>       
      </ul>
      <ul class="menu-list" v-if="activeMenu === 8"> <!-- Maintenance -->  
        <li class="littleviolet"  @click="toggleSubMenu(20)">Annual Maintenance</li>        
        <li class="strongskyblue"  @click="toggleSubMenu(13)">1st Check Sheet</li>
        <li class="violet"  @click="toggleSubMenu(6)">2nd Check Sheet</li>
        <li class="littlegray" @click="goToMaintenanceWorkList">Maintenance Work List</li>
        <li class="littlegray" @click="goToSpareList">Spare List</li> 
        <li class="skyblue"  @click="toggleSubMenu(10)">Failure Rate</li>   
        <li class="littlegray" @click="goToEquipmentLedger">Equipment Ledger</li>            
        <li class="littlegray" @click="goToMachineGrade">Machine Grade</li> 
        <li class="littlegray" @click="goToConstructionInspection">Construction/equipment Inspection</li> 
      </ul>
      <ul class="menu-list" v-if="activeMenu === 9">        
        <li class="littlegray"  @click="goToPurchaseList">Purchase List</li>        
        <li class="littlegray"  @click="goToPurchaseRequest">Purchase Request</li>     
      </ul>      
      <ul class="menu-list" v-if="activeMenu === 10">
        <li class="littlegray" @click="goToExcelUpload">Excel Upload</li>    
        <li class="skyblue" @click="goToExchangeRate">Exchange Rate</li>        
        <li class="violet" @click="toggleSubMenu(22)">Fetch Data</li>                
        <li class="strongskyblue" @click="toggleSubMenu(21)">Arduino</li>    
        <li class="littlegray" @click="goToSetting">Setting</li>      
        <li class="skyblue" @click="goToMaxiStockList">Stock List</li>    
        <li class="littleskyblue" @click="goToOsCost">OS Cost</li>    
        <li class="littlegray" @click="goToStockPricePrediction">Stock Price Prediction</li>                        
        <li class="littlegray" @click="goToAuthSetting">Authority Setting</li>      
      </ul>

      <!--Sub Menu Start-->
      <ul class="sub-menu" v-if="activeSubMenu === 2">
        <li class="skyblue" @click="goToStockList">Stock</li>             
        <li class="skyblue" @click="goToNotNormalStock">Not Normal Stock</li>     
        <li class="skyblue" @click="goToTrayStock">Tray Stock</li>     
        <li class="skyblue" @click="goToInOutMaterial">In-Out Material</li>   
        <li class="skyblue" @click="goToDailyInventoryCheck">Daily Inventory Check</li> 
        <li class="skyblue" @click="goToMonthlyInventoryCheck">Monthly Inventory Check</li> 
      </ul> 
      <ul class="sub-menu" v-if="activeSubMenu === 3">
        <li class="violet" @click="goToCheckTarget">Target</li>     
        <li class="violet" @click="goToTr3Smt">TR3 SMT</li>             
        <li class="violet" @click="goToTr3Crimping">TR3 Crimping</li> 
        <li class="violet" @click="goToTr3Fpca">TR3 FPCA</li> 
        <li class="violet" @click="goToTr3Bfa2p">EVO 1</li> 
        <li class="violet" @click="goToTr3Bfa3p">EVO 2</li> 
        <li class="violet" @click="goToCheckEvo3">EVO 3</li>         
        <li class="violet" @click="goToEvo">EVO Hybrid</li> 
        <li class="violet" @click="goToEv202060kw">EV2020 60kW</li> 
        <li class="violet" @click="goToEv20204060kw">EV2020 4060kW</li>                 
        <li class="littleviolet" @click="goToCheckSheetHistory">History</li>      
      </ul>
      <ul class="sub-menu" v-if="activeSubMenu === 4">
        <li class="skyblue" @click="goToEolConnectorPin">EOL Connector Pin</li>             
        <li class="skyblue"  @click="goToSmtLaser">SMT Laser</li>   
        <li class="skyblue"  @click="goToBfaQrLaser">QR Marking Laser</li>   
        <li class="skyblue"  @click="goToBfaWeldLaser">Weld Laser</li>   
        <li class="skyblue"  @click="goToHeatTipCleaning">Heat Staking Tip</li>   
        <li class="skyblue" @click="goToCalibrationDate">Calibration</li>
      </ul>
      <ul class="sub-menu" v-if="activeSubMenu === 5">
        <li class="littleviolet" @click="goToCleaningCycle1st">Cleaning Cycle 1st</li>     
        <li class="littleviolet" @click="goToCleaningActual1st">Cleaning Actual 1st</li> 
        <li class="littleviolet" @click="goToCleaningCycle2nd">Cleaning Cycle 2nd</li>  
        <li class="littleviolet" @click="goToCleaningActual2nd">Cleaning Actual 2nd</li>    
      </ul>
      <ul class="sub-menu" v-if="activeSubMenu === 6">
        <li class="violet" @click="goToMaintenanceCheckTarget">Target</li>             
        <li class="violet" @click="goToMaintenanceCheckTR3SMT">TR3 SMT</li>   
        <li class="violet" @click="goToMaintenanceCheckTR3Crimping">TR3 Crimping</li>  
        <li class="violet" @click="goToMaintenanceCheckTR3FPCA">TR3 FPCA</li>  
        <li class="violet" @click="goToMaintenanceCheckEVO1">EVO 1</li>  
        <li class="violet" @click="goToMaintenanceCheckEVO2">EVO 2</li>
        <li class="violet" @click="goToMaintenanceCheckEVO3">EVO 3</li>  
        <li class="violet" @click="goToMaintenanceCheckEVOHybrid">EVO Hybrid</li>          
        <li class="violet" @click="goToMaintenanceCheckEV202060">EV2020 60kW</li>          
        <li class="violet" @click="goToMaintenanceCheckEV20204060">EV2020 4060kW</li>     
        <li class="skyblue" @click="toggleSubSubMenu(2)">Utility</li>             
        <li class="littleviolet" @click="goToMaintenanceCheckHistory">History</li>
      </ul>
      <ul class="sub-menu" v-if="activeSubMenu === 7">
        <li class="littleskyblue" @click="goToProduction1st">Production 1st List</li>     
        <li class="littleskyblue" @click="goToProduction2nd">Production 2nd List</li>     
        <li class="littleskyblue" @click="goToQA">QA List</li>   
        <li class="littleskyblue" @click="goToWarehouse">Warehouse List</li>   
        <li class="littleskyblue" @click="goToInkatom">Inkatom List</li>   
      </ul>
      <ul class="sub-menu" v-if="activeSubMenu === 8">
        <li class="littleviolet" @click="goToMaxPowerDB">Max Power DB</li>    
        <li class="littleviolet" @click="goToMegaStaffDB">Mega Staff DB</li>    
        <li class="littleviolet" @click="goToZxyDB">ZYX DB</li>           
        <li class="littleviolet" @click="goToInkatomDB">Inkatom DB</li>  
        <li class="littleviolet" @click="goToLsevpDB">LSEVP DB</li>  
      </ul>
      <ul class="sub-menu" v-if="activeSubMenu === 9">
        <li class="violet" @click="goToMonthlyMaxPower">Monthly Max Power</li>
        <li class="violet" @click="goToMonthlyMegaStaff">Monthly Mega Staff</li>
        <li class="violet" @click="goToMonthlyZyx">Monthly ZYX</li>
        <li class="violet" @click="goToMonthlyInkatom">Monthly Inkatom</li>
        <li class="violet" @click="goToMonthlyLineCost">Monthly Line Cost</li>
      </ul>
      <ul class="sub-menu" v-if="activeSubMenu === 10">
        <li class="skyblue"  @click="goToFailureTarget">Failure Target</li>  
        <li class="skyblue"  @click="goToFailureClass">Failure Class</li>                     
      </ul>
      <ul class="sub-menu" v-if="activeSubMenu === 11">
        <li class="strongskyblue" @click="goToEvaluationLine">Evaluation Line Base</li>
        <li class="strongskyblue" @click="goToEvaluationPerson">Evaluation Person Base</li>
      </ul>
      <ul class="sub-menu" v-if="activeSubMenu === 12">
        <li class="strongskyblue" @click="goToCheckTarget">Target</li>     
        <li class="strongskyblue" @click="goToTr2Smt">TR2 SMT</li>             
        <li class="strongskyblue" @click="goToTr2Crimping">TR2 Crimping</li> 
        <li class="strongskyblue" @click="goToTr2Fpca">TR2 FPCA</li>       
        <li class="strongskyblue" @click="goToCheckBmw12v">BMW 12V</li>       
        <li class="strongskyblue" @click="goToCheckBmw48v">BMW 48V</li>
        <li class="strongskyblue" @click="goToCheckFcaFlexBc">FCA FLEX BC</li>
        <li class="strongskyblue" @click="goToCheckFca2">FCA2</li>
        <li class="strongskyblue" @click="goToCheckFord2Front">Ford 2 Front</li>
        <li class="strongskyblue" @click="goToCheckFord2Rear">Ford 2 Rear</li>
        <li class="strongskyblue" @click="goToCheckFord3Front">Ford 3 Front</li>
        <li class="strongskyblue" @click="goToCheckFord3Rear">Ford 3 Rear</li>
        <li class="littleviolet" @click="goToCheckSheetHistory">History</li>     
      </ul>
      <ul class="sub-menu" v-if="activeSubMenu === 13">
        <li class="strongskyblue" @click="goToMaintenanceCheckTarget">Target</li>             
        <li class="strongskyblue" @click="goToMaintenanceCheckTR2SMT">TR2 SMT</li>           
        <li class="strongskyblue" @click="goToMaintenanceCheckTR2Crimping">TR2 Crimping</li>  
        <li class="strongskyblue" @click="goToMaintenanceCheckTR2Fpca">TR2 FPCA</li>  
        <li class="strongskyblue" @click="goToMaintenanceCheckFord2Front">Ford 2 Front</li>      
        <li class="strongskyblue" @click="goToMaintenanceCheckFord2Rear">Ford 2 Rear</li>  
        <li class="strongskyblue" @click="goToMaintenanceCheckFord3Front">Ford 3 Front</li>      
        <li class="strongskyblue" @click="goToMaintenanceCheckFord3Rear">Ford 3 Rear</li>           
        <li class="strongskyblue" @click="goToMaintenanceCheckBMW48V">BMW 48V</li>  
        <li class="strongskyblue" @click="goToMaintenanceCheckBMW12V">BMW 12V</li>   
        <li class="strongskyblue" @click="goToMaintenanceCheckFca2">FCA 2</li>   
        <li class="skyblue" @click="toggleSubSubMenu(3)">Utility</li>   
        <li class="littleviolet" @click="goToMaintenanceCheckHistory">History</li>
      </ul>
      <ul class="sub-menu" v-if="activeSubMenu === 14">
        <li class="violet" @click="goToBarcodePrint">Barcode Print</li>             
        <li class="violet" @click="goToBarcodeRead">Barcode Read</li>                   
      </ul>
      <ul class="sub-menu" v-if="activeSubMenu === 15">
        <li class="orange" @click="goToScrap">Scrap</li>
        <li class="orange" @click="goToScrapLog">Scrap Log</li>
        <li class="orange" @click="goToScrapMeasurement">Scrap Measurement/Sell</li>
      </ul>
      <ul class="sub-menu" v-if="activeSubMenu === 16">
        <li class="violet" @click="goToTr2smtPr">TR2 SMT</li>     
        <li class="violet" @click="goToTr2crimpingPr">TR2 Crimp</li>
        <li class="violet" @click="goToTr2fpcaPr">TR2 FPCA</li>  
        <li class="violet" @click="goToTr2bfa3pPr">TR2 BFA 3P</li>                                     
        <li class="littleskyblue" @click="goToFord1frPr">Ford#1 FR</li>   
        <li class="littleskyblue" @click="goToFord1rrPr">Ford#1 RR</li>   
        <li class="littleskyblue" @click="goToFord2frPr">Ford#2 FR</li>   
        <li class="littleskyblue" @click="goToFord2rrPr">Ford#2 RR</li>   
        <li class="littleskyblue" @click="goToFord3frPr">Ford#3 FR</li>   
        <li class="littleskyblue" @click="goToFord3rrPr">Ford#3 RR</li>           
        <li class="littleviolet" @click="goToBmw12vPr">BMW 12V</li>               
        <li class="skyblue" @click="toggleSubSubMenu(1)">EOP Lines</li>   
        <li class="orange" @click="goToResultVerification">Result Verification</li>    
      </ul>      
      <ul class="sub-menu" v-if="activeSubMenu === 17">
        <li class="violet" @click="goToTr3smtPr">TR3 SMT</li>       
        <li class="violet" @click="goToTr3crimpingPr">TR3 Crimp</li>
        <li class="violet" @click="goToTr3fpcaPr">TR3 FPCA</li>    
        <li class="violet" @click="goToTr3bfa2pPr">EVO 1</li>            
        <li class="violet" @click="goToTr3bfa3pPr">EVO 2</li>  
        <li class="violet" @click="goToTr2bfa2pPr">EVO 3</li>
        <li class="violet" @click="goToEvoPr">EVO Hybrid</li>    
        <li class="littlegray" @click="goToThermistorTape">Thermistor Tape</li>    
        <li class="littlegray" @click="goToThermistorPad">Thermistor Pad</li>   
        <li class="littlegray" @click="goToBendingEvo">Bending EVO FPCA</li>  
        <li class="littleviolet" @click="goToEv202060FrPr">EV2020 60 FR</li>    
        <li class="littleviolet" @click="goToEv202060RrPr">EV2020 60 RR</li>   
        <li class="littleviolet" @click="goToEv20204060FrPr">EV2020 4060 FR</li>   
        <li class="littleviolet" @click="goToEv20204060RrPr">EV2020 4060 RR</li> 
        <li class="littleskyblue" @click="goToBmw48vPr">BMW 48V</li>  
        <li class="orange" @click="goToResultVerification">Result Verification</li>                      
      </ul>
      <ul class="sub-menu" v-if="activeSubMenu === 18">
        <li class="violet" @click="goToInkatomFirst">1st Factory (BFA)</li>  
        <li class="littlegray" @click="goToInkatom45">1st Factory (45)</li>
        <li class="littlegray" @click="goToInkatom67">1st Factory (67)</li>
        <li class="littlegray" @click="goToInkatomPo">1st Factory (Positive)</li>
        <li class="littlegray" @click="goToInkatomNe">1st Factory (Negative)</li>
        <li class="violet" @click="goToInkatomSecond">2nd Factory (EVO)</li> 
        <li class="orange" @click="goToInkatomSearch">Period Search</li> 
      </ul> 
      <ul class="sub-menu" v-if="activeSubMenu === 19">        
        <li class="littlegray" @click="goToMaterialRequest">Material Request</li>
        <li class="littlegray" @click="goToRequestLog">Request Log</li>        
      </ul> 
      <ul class="sub-menu" v-if="activeSubMenu === 20">        
        <li class="littleviolet" @click="goToAnnualMaintenance">Annual Maintenance</li>      
        <li class="littleviolet" @click="goToAnnualCalculation">Annul Cycle Date</li> 
      </ul> 
      <ul class="sub-menu" v-if="activeSubMenu === 21">        
        <li class="strongskyblue" @click="goToArduino">Arduino</li>
        <li class="strongskyblue" @click="goToArduinoLog">Arduino Log</li>             
      </ul> 
      <ul class="sub-menu" v-if="activeSubMenu === 22">        
        <li class="violet" @click="goToLineData">Line Data</li>
        <li class="violet" @click="goToMachineData">Machine Data</li>
        <li class="violet" @click="goToProductionClass">Production Class</li>
        <li class="violet" @click="goToSelectOption">Select Option</li>
        <li class="violet" @click="goToAssetClass">Asset Class</li>
        <li class="violet" @click="goToAttendenceProcess">Attendance Process</li>
      </ul> 
      <ul class="sub-menu" v-if="activeSubMenu === 23">        
        <li class="violet"  @click="goPeelTensile">Peel Tensile</li>        
      </ul> 


      <!--Sub Sub Menu Start-->
      <ul class="sub-menu" v-if="active3Menu === 1">
        <li class="skyblue" @click="goToFca1Pr">FCA #1</li>   
        <li class="skyblue" @click="goToFca2Pr">FCA #2</li>   
        <li class="skyblue" @click="goToBt6Pr">BT6</li>   
        <li class="skyblue" @click="goToFcaFlexBcPr">Flex Busbar Carrier</li>     
        <li class="skyblue" @click="goToFcaFlexPoPr">Flex Positive</li>     
        <li class="skyblue" @click="goToFcaFlex67Pr">Flex 6+7-</li>    
        <li class="skyblue" @click="goToFcaFlexNePr">Flex Negative</li>    
        <li class="skyblue" @click="goToFcaFlex45Pr">Flex 4-5+</li>   
      </ul>      
      <ul class="sub-menu" v-if="active3Menu === 2">
        <li class="skyblue" @click="goTo2ndAHU">AHU</li>   
        <li class="skyblue" @click="goTo2ndBoiler">Boilers</li>   
        <li class="skyblue" @click="goTo2ndComporessor">Compressor</li>   
        <li class="skyblue" @click="goTo2ndDustCollector">Dust Collector</li>     
        <li class="skyblue" @click="goTo2ndTransformer">Transformer</li>             
      </ul>    
      <ul class="sub-menu" v-if="active3Menu === 3">
        <li class="skyblue" @click="goTo1stAHU">AHU</li>   
        <li class="skyblue" @click="goTo1stBoiler">Boilers</li>   
        <li class="skyblue" @click="goTo1stComporessor">Compressor</li>   
        <li class="skyblue" @click="goTo1stDustCollector">Dust Collector</li>     
        <li class="skyblue" @click="goTo1stTransformer">Transformer</li>             
      </ul>    
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {      
      activeMenu: null, // 활성화된 메뉴를 추적
      activeSubMenu: null, // 활성화된 서브메뉴를 추적
      active3Menu: null, // 활성화된 서브메뉴를 추적
    };
  },
  methods: {    
    toggleMenu(menuNumber) {if (this.activeMenu === menuNumber) {this.activeMenu = null; this.activeSubMenu = null;} else {this.activeMenu = menuNumber; this.activeSubMenu = null; this.active3Menu = null; }},
    toggleSubMenu(subMenuNumber) {if (this.activeSubMenu === subMenuNumber) {this.activeSubMenu = null; this.active3Menu = null;} else {this.activeSubMenu = subMenuNumber; this.active3Menu = null; }},
    toggleSubSubMenu(subSubMenuNumber) {if (this.active3Menu === subSubMenuNumber) {this.active3Menu = null;} else {this.active3Menu = subSubMenuNumber; }},
        
    goToTr2smtPr() { this.$router.push('/tr2smtpr'); this.active3Menu = null; },
    goToTr2fpcaPr() { this.$router.push('/tr2fpcapr'); this.active3Menu = null; },
    goToTr2crimpingPr() { this.$router.push('/tr2crimpingpr'); this.active3Menu = null; },
    goToTr2bfa2pPr() { this.$router.push('/tr2bfa2ppr'); this.active3Menu = null; },
    goToTr2bfa3pPr() { this.$router.push('/tr2bfa3ppr'); this.active3Menu = null; },
    goToFord1frPr() { this.$router.push('/ford1frpr'); this.active3Menu = null; },
    goToFord1rrPr() { this.$router.push('/ford1rrpr'); this.active3Menu = null; },
    goToFord2frPr() { this.$router.push('/ford2frpr'); this.active3Menu = null; },
    goToFord2rrPr() { this.$router.push('/ford2rrpr'); this.active3Menu = null; },
    goToFord3frPr() { this.$router.push('/ford3frpr'); this.active3Menu = null; },
    goToFord3rrPr() { this.$router.push('/ford3rrpr'); this.active3Menu = null; },
    goToFca1Pr() { this.$router.push('/fca1pr'); },
    goToFca2Pr() { this.$router.push('/fca2pr'); },
    goToBt6Pr() { this.$router.push('/bt6pr'); },
    goToBmw12vPr() { this.$router.push('/bmw12vpr'); this.active3Menu = null; },
    goToBmw48vPr() { this.$router.push('/bmw48vpr'); this.active3Menu = null; },
    goToFcaFlexBcPr() { this.$router.push('/fcaflexbcpr'); },    
    goToFcaFlexPoPr() { this.$router.push('/fcaflexpopr'); },  
    goToFcaFlex67Pr() { this.$router.push('/fcaflex67pr'); },  
    goToFcaFlexNePr() { this.$router.push('/fcaflexnepr'); }, 
    goToFcaFlex45Pr() { this.$router.push('/fcaflex45pr'); }, 
    goToTr3smtPr() { this.$router.push('/tr3smtpr'); },
    goToTr3crimpingPr() { this.$router.push('/tr3crimpingpr'); },       
    goToTr3fpcaPr() { this.$router.push('/tr3fpcapr'); },   
    goToTr3bfa2pPr() { this.$router.push('/tr3bfa2ppr'); },   
    goToTr3bfa3pPr() { this.$router.push('/tr3bfa3ppr'); },   
    goToEvoPr() { this.$router.push('/evopr'); },    
    goToThermistorTape() { this.$router.push('/thermistortape'); },        
    goToThermistorPad() { this.$router.push('/thermistorpad'); },        
    goToBendingEvo() { this.$router.push('/bendingevo'); },   
    
    goToEv202060FrPr() { this.$router.push('/ev202060frpr'); },     
    goToEv202060RrPr() { this.$router.push('/ev202060rrpr'); },     
    goToEv20204060FrPr() { this.$router.push('/ev20204060frpr'); },     
    goToEv20204060RrPr() { this.$router.push('/ev20204060rrpr'); }, 
    goToResultVerification() { this.$router.push('/resultverification'); this.active3Menu = null; }, 
    goToPacking() { this.$router.push('/packingpr'); this.activeSubMenu = null; },

    goToDataDelete() { this.$router.push('/datadelete'); this.activeSubMenu = null; this.active3Menu = null;},

    goTosearchPeriod() { this.$router.push('/searchPeriod'); },    
    goToReport01() { this.$router.push('/report01'); },
    goToReport02() { this.$router.push('/report02'); },
    goToMonthlyReport() { this.$router.push('/monthlyreport'); },
    goToOsCost() { this.$router.push('/oscost'); },
    goToStockPricePrediction() { this.$router.push('/stockpriceprediction'); },    
    goToCoAc() { this.$router.push('/correctiveaction'); },
    goToInvestList() { this.$router.push('/investlist'); }, 
    goToAssetList() { this.$router.push('/assetlist'); }, 
    goToDocumentReport() { this.$router.push('/documentreport'); }, 

    goToShipping() { this.$router.push('/shipping'); this.activeSubMenu = null; },
    goToTargetList() { this.$router.push('/targetList'); this.activeSubMenu = null;},    
    goToNgList() { this.$router.push('/ngList'); this.activeSubMenu = null;},    
    goToScrap() { this.$router.push('/scrap'); },
    goToScrapLog() { this.$router.push('/scraplog'); },
    goToScrapMeasurement() { this.$router.push('/scrapmeasurement'); },
    
    goToMaxiStockList() { this.$router.push('/maxistocklist'); this.activeSubMenu = null;},

    goToPfmea() { this.$router.push('/pfmea'); this.activeSubMenu = null;},
    goToControlPlan() { this.$router.push('/controlplan'); this.activeSubMenu = null;},
    goToFlowChart() { this.$router.push('/flowchart'); this.activeSubMenu = null;},
    
    goToCheckTarget() { this.$router.push('/checktarget'); },    
    
    //각 설비마다 설비명을 적어줘서 전달해야 됨
    goToTr3Smt() { this.$router.push({ name: 'checkMain', params: { lineName: 'TR3 SMT' } }); },        
    goToTr3Crimping() { this.$router.push({ name: 'checkMain', params: { lineName: 'TR3 Crimping' } });},
    goToTr3Fpca() { this.$router.push({ name: 'checkMain', params: { lineName: 'TR3 FPCA' } });},
    goToTr3Bfa2p() { this.$router.push({ name: 'checkMain', params: { lineName: 'EVO 1' } });},
    goToTr3Bfa3p() { this.$router.push({ name: 'checkMain', params: { lineName: 'EVO 2' } });},
    goToCheckEvo3() { this.$router.push({ name: 'checkMain', params: { lineName: 'EVO 3' } });},
    goToEvo() { this.$router.push({ name: 'checkMain', params: { lineName: 'EVO Hybrid' } });},
    goToEv202060kw() { this.$router.push({ name: 'checkMain', params: { lineName: 'EV2020 60kW' } });},
    goToEv20204060kw() { this.$router.push({ name: 'checkMain', params: { lineName: 'EV2020 4060kW' } });},  
    goToTr2Smt() { this.$router.push({ name: 'checkMain', params: { lineName: 'TR2 SMT' } });},      
    goToTr2Crimping() { this.$router.push({ name: 'checkMain', params: { lineName: 'TR2 Crimping' } });},
    goToTr2Fpca() { this.$router.push({ name: 'checkMain', params: { lineName: 'TR2 FPCA' } });},        
    goToCheckBmw48v() { this.$router.push({ name: 'checkMain', params: { lineName: 'BMW48V' } });},
    goToCheckBmw12v() { this.$router.push({ name: 'checkMain', params: { lineName: 'BMW12V' } });},
    goToCheckFcaFlexBc() { this.$router.push({ name: 'checkMain', params: { lineName: 'FCA Flex BC' } });},
    goToCheckFca2() { this.$router.push({ name: 'checkMain', params: { lineName: 'FCA 2' } });},
    goToCheckFord2Front() { this.$router.push({ name: 'checkMain', params: { lineName: 'Ford 2 Front' } });},
    goToCheckFord2Rear() { this.$router.push({ name: 'checkMain', params: { lineName: 'Ford 2 Rear' } });},
    goToCheckFord3Front() { this.$router.push({ name: 'checkMain', params: { lineName: 'Ford 3 Front' } });},
    goToCheckFord3Rear() { this.$router.push({ name: 'checkMain', params: { lineName: 'Ford 3 Rear' } });},
    goToMasterSample() { this.$router.push('/masterSample'); },
    goToCheckSheetHistory() { this.$router.push({ name: 'checkHistory', params: { type: 'checksheet' }, query: { headName: 'Check Sheet' } }); },
    
    goToLPA() { this.$router.push('/lpa'); },

    goToEolConnectorPin() { this.$router.push('/eolConnectorPin'); }, 
    goToSmtLaser() { this.$router.push('/smtlaser'); }, 
    goToBfaQrLaser() { this.$router.push('/bfaqrlaser'); }, 
    goToBfaWeldLaser() { this.$router.push('/bfaweldlaser'); }, 
    goToHeatTipCleaning() { this.$router.push('/heattipcleaning'); }, 
    goToCalibrationDate() { this.$router.push('/calibrationDate'); },   

    
    goToCleaningCycle1st() { this.$router.push({ name: 'cleaningcycle1st', params: { dbTableName: 'cleaningcycle1st' } }); this.active3Menu = null},
    goToCleaningActual1st() { this.$router.push({ name: 'cleaningactual1st', params: { dbTableName: 'cleaninglist1st' }, query: {cycleTableName: 'cleaningcycle1st'} }); this.active3Menu = null},        
    goToCleaningCycle2nd() { this.$router.push({ name: 'cleaningcycle2nd', params: { dbTableName: 'cleaningcycle2nd' } }); this.active3Menu = null},
    goToCleaningActual2nd() { this.$router.push({ name: 'cleaningactual2nd', params: { dbTableName: 'cleaninglist2nd' }, query: {cycleTableName: 'cleaningcycle2nd'} }); this.active3Menu = null},    
    
    goToTakeOver() { this.$router.push('/takeover'); this.activeSubMenu = null; this.active3Menu = null },  
    goPeelTensile() { this.$router.push('/peeltensile');  },      
    
    goToInkatomFirst() { this.$router.push('/inkatomFirst'); },    
    goToInkatom45() { this.$router.push('/inkatom45'); },     
    goToInkatom67() { this.$router.push('/inkatom67'); },     
    goToInkatomPo() { this.$router.push('/inkatomPo'); },     
    goToInkatomNe() { this.$router.push('/inkatomNe'); },     
    goToInkatomSecond() { this.$router.push('/inkatomSecond'); },      
    goToInkatomSearch() { this.$router.push('/inkatomSearch'); },  

    goToAnnualMaintenance() { this.$router.push('/annualmaintenance'); },
    goToMaintenanceCheckTarget() { this.$router.push('/maintenancechecktarget'); },
    goToMaintenanceCheckTR3SMT() { this.$router.push({ name: 'maintenanceCheck', params: { lineName: 'TR3 SMT' } }); this.active3Menu = null; },
    goToMaintenanceCheckTR3Crimping() { this.$router.push({ name: 'maintenanceCheck', params: { lineName: 'TR3 Crimping' } }); this.active3Menu = null;},
    goToMaintenanceCheckTR3FPCA() { this.$router.push({ name: 'maintenanceCheck', params: { lineName: 'TR3 FPCA' } }); this.active3Menu = null;},
    goToMaintenanceCheckEVO1() { this.$router.push({ name: 'maintenanceCheck', params: { lineName: 'EVO 1' } });this.active3Menu = null;},
    goToMaintenanceCheckEVO2() { this.$router.push({ name: 'maintenanceCheck', params: { lineName: 'EVO 2' } });this.active3Menu = null;},
    goToMaintenanceCheckEVO3() { this.$router.push({ name: 'maintenanceCheck', params: { lineName: 'EVO 3' } });this.active3Menu = null;},
    goToMaintenanceCheckEVOHybrid() { this.$router.push({ name: 'maintenanceCheck', params: { lineName: 'EVO Hybrid' } });this.active3Menu = null;},
    goToMaintenanceCheckEV202060() { this.$router.push({ name: 'maintenanceCheck', params: { lineName: 'EV2020 60kW' } });this.active3Menu = null;},
    goToMaintenanceCheckEV20204060() { this.$router.push({ name: 'maintenanceCheck', params: { lineName: 'EV2020 4060kW' } });this.active3Menu = null;},
    goToMaintenanceCheckTR2SMT() { this.$router.push({ name: 'maintenanceCheck', params: { lineName: 'TR2 SMT' } });this.active3Menu = null;},
    goToMaintenanceCheckTR2Crimping() { this.$router.push({ name: 'maintenanceCheck', params: { lineName: 'TR2 Crimping' } });this.active3Menu = null;},
    goToMaintenanceCheckTR2Fpca() { this.$router.push({ name: 'maintenanceCheck', params: { lineName: 'TR2 FPCA' } });this.active3Menu = null;},
    goToMaintenanceCheckFord2Front() { this.$router.push({ name: 'maintenanceCheck', params: { lineName: 'Ford 2 Front' } });this.active3Menu = null;},
    goToMaintenanceCheckFord2Rear() { this.$router.push({ name: 'maintenanceCheck', params: { lineName: 'Ford 2 Rear' } });this.active3Menu = null;},
    goToMaintenanceCheckFord3Front() { this.$router.push({ name: 'maintenanceCheck', params: { lineName: 'Ford 3 Front' } });this.active3Menu = null;},
    goToMaintenanceCheckFord3Rear() { this.$router.push({ name: 'maintenanceCheck', params: { lineName: 'Ford 3 Rear' } });this.active3Menu = null;},
    goToMaintenanceCheckBMW48V() { this.$router.push({ name: 'maintenanceCheck', params: { lineName: 'BMW48V' } });this.active3Menu = null;},
    goToMaintenanceCheckBMW12V() { this.$router.push({ name: 'maintenanceCheck', params: { lineName: 'BMW12V' } });this.active3Menu = null;},
    goToMaintenanceCheckFca2() { this.$router.push({ name: 'maintenanceCheck', params: { lineName: 'FCA 2' } });this.active3Menu = null;},
    goToMaintenanceCheckHistory() { this.$router.push({ name: 'maintenanceCheckHistory', params: { type: 'maintenancechecksheet' }, query: { headName: 'Maintenance Check Sheet' } });this.active3Menu = null;},
    goTo2ndAHU() { this.$router.push({ name: 'maintenanceCheck', params: { lineName: 'AHU Factory 2nd' } });},
    goTo2ndBoiler() { this.$router.push({ name: 'maintenanceCheck', params: { lineName: 'Boiler Factory 2nd' } });},
    goTo2ndComporessor() { this.$router.push({ name: 'maintenanceCheck', params: { lineName: 'Compressor Factory 2nd' } });},
    goTo2ndDustCollector() { this.$router.push({ name: 'maintenanceCheck', params: { lineName: 'Dust collector Factory 2nd' } });},
    goTo2ndTransformer() { this.$router.push({ name: 'maintenanceCheck', params: { lineName: 'Transformer Factory 2nd' } });},
    goTo1stAHU() { this.$router.push({ name: 'maintenanceCheck', params: { lineName: 'AHU Factory 1st' } });},
    goTo1stBoiler() { this.$router.push({ name: 'maintenanceCheck', params: { lineName: 'Boiler Factory 1st' } });},
    goTo1stComporessor() { this.$router.push({ name: 'maintenanceCheck', params: { lineName: 'Compressor Factory 1st' } });},
    goTo1stDustCollector() { this.$router.push({ name: 'maintenanceCheck', params: { lineName: 'Dust collector Factory 1st' } });},
    goTo1stTransformer() { this.$router.push({ name: 'maintenanceCheck', params: { lineName: 'Transformer Factory 1st' } });},
    
    
    goToMaintenanceWorkList() { this.$router.push('/maintenanceworklist'); this.activeSubMenu = null; },      
    goToFailureTarget() { this.$router.push('/failuretarget'); },  
    goToFailureClass() { this.$router.push('/failureclass'); },   
    goToFailureReport() { this.$router.push('/failurereport'); },      
    goToAnnualCalculation() { this.$router.push('/annualcalculation'); },  
    goToEquipmentLedger() { this.$router.push('/equipmentledger'); this.activeSubMenu = null; },  
    goToMachineGrade() { this.$router.push('/machinegrade'); this.activeSubMenu = null;},  
    goToConstructionInspection() { this.$router.push('/constructioninspection');this.activeSubMenu = null; },  
    
    goToSpareList() { this.$router.push('/spareList');this.activeSubMenu = null; }, 
        
    goToMaterialRequest() { this.$router.push('/materialrequest'); },
    goToRequestLog() { this.$router.push('/requestlog'); },
    goToMaterialList() { this.$router.push('/materiallist'); this.activeSubMenu = null; },
    goToStockList() { this.$router.push('/stockList');  },        
    goToNotNormalStock() { this.$router.push('/notnormalstock'); },     
    goToTrayStock() { this.$router.push('/trayStock'); },     
    goToInOutMaterial() { this.$router.push('/inoutmaterial'); },     
    goToDailyInventoryCheck() { this.$router.push('/dailyinventorycheck'); }, 
    goToMonthlyInventoryCheck() { this.$router.push('/monthlyinventorycheck'); }, 
    goToMovementLog() { this.$router.push('/movementlog'); this.activeSubMenu = null; },
    goToProductionSpare() { this.$router.push('/productionspare'); this.activeSubMenu = null; },
    goToBarcodePrint() { this.$router.push('/barcodeprint'); },
    goToBarcodeRead() { this.$router.push('/barcoderead'); },

    goToProductionPlan() { this.$router.push('/productionplan'); },
    goToSchedule() { this.$router.push('/monthlyschedule'); },   
    
    goToProduction1st() { this.$router.push('/production1stattendance'); },    
    goToProduction2nd() { this.$router.push('/production2ndattendance'); },    
    goToQA() { this.$router.push('/qaattendance'); },    
    goToWarehouse() { this.$router.push('/warehouseattendance'); },    
    goToInkatom() { this.$router.push('/inkatomattendance'); },
    goToSearchAll() { this.$router.push('/attendancesearchall'); this.activeSubMenu = null; },    
    goToWeekendWork() { this.$router.push('/weekendWork'); this.activeSubMenu = null; },      
    goToMaxPowerDB() { this.$router.push('/maxpowerdb'); },  
    goToMegaStaffDB() { this.$router.push('/megastaffdb'); }, 
    goToZxyDB() { this.$router.push('/zxydb'); }, 
    goToInkatomDB() { this.$router.push('/inkatomdb'); }, 
    goToLsevpDB() { this.$router.push('/lsevpdb'); }, 
    
    goToMonthlyMaxPower() { this.$router.push('/monthlymaxpower'); }, 
    goToMonthlyMegaStaff() { this.$router.push('/monthlymegastaff'); }, 
    goToMonthlyZyx() { this.$router.push('/monthlyzyx'); }, 
    goToMonthlyInkatom() { this.$router.push('/monthlyinkatom'); }, 
    goToMonthlyLineCost() { this.$router.push('/monthlylinecost'); },
    goToLineWorkerList() { this.$router.push('/lineworkerlist'); this.activeSubMenu = null; }, 
    goToEvaluationLine() { this.$router.push('/evaluationline'); }, 
    goToEvaluationPerson() { this.$router.push('/evaluationperson'); }, 
    goToLsList() { this.$router.push('/lslist'); this.activeSubMenu = null;}, 

    goToDashBoard() { this.$router.push('/dashboard'); this.activeMenu = null; this.activeSubMenu = null; this.active3Menu = null;},    
    goToToDoList() { this.$router.push('/todolist'); },
    goToPurchaseList() { this.$router.push('/purchaselist'); },
    goToPurchaseRequest() { this.$router.push('/purchaserequest'); },

    goToExcelUpload() { this.$router.push('/excelupload'); this.activeSubMenu = null; },    
    goToExchangeRate() { this.$router.push('/exchangerate'); this.activeSubMenu = null; },    

    goToLineData() {this.$router.push('/linedata'); },
    goToMachineData() {this.$router.push('/machinedata'); },
    goToProductionClass() {this.$router.push('/productionclass'); },
    goToSelectOption() {this.$router.push('/selectoption'); },
    goToAssetClass() {this.$router.push('/assetclass'); },
    goToAttendenceProcess() {this.$router.push('/attendanceprocess'); },
    goToSetting() { this.$router.push('/setting'); this.activeSubMenu = null; },    
    goToArduino() { this.$router.push('/arduino'); },    
    goToArduinoLog() { this.$router.push('/arduinolog'); },      
    goToAuthSetting() { this.$router.push('/authSetting'); },  
  },
};
</script>

<style scoped>
.header { font-family: Arial, sans-serif; text-align: left; margin-bottom: 1rem; padding-left: 10px; }
h1 { font-size: 1.5rem; text-align: left; margin-bottom: 0.5rem;}
/* .menu-nav { display: flex; justify-content: left; flex-direction: column; } */
.menu-titles { margin-top: 10px; display: flex;  justify-content: left;  }
.menu-titles div { cursor: pointer; padding: 0.5rem; background-color: #4f54eb; border: 1px solid #ccc; margin-right: 10px; color: white;}
.menu-titles div:hover {  cursor: pointer; padding: 0.5rem; background-color: #262bbb; border: 1px solid #ccc; margin-right: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.menu-list { display: flex; list-style: none; padding: 0; margin: 0; margin-top: 0.5rem;  margin-bottom: 0.2rem; /* Added margin between the two lists */}
.title { font-weight: bold; font-size : 20px;}
li { font-size: 1rem; text-align: center; margin-left: 10px; padding: 0.3rem; border: 1px solid #000000; }
.sub-menu { display: flex; list-style: none; padding: 0; margin: 0; margin-top: 0.5rem;  margin-bottom: 0.2rem; }

.littlegray {
  cursor: pointer; padding: 0.5rem 1.0 rem; margin: 0 3px;
  background-color: #f1f1f1; border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.littlegray:hover { box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);}

.skyblue {
  cursor: pointer; padding: 0.5rem 1.0 rem; margin: 0 3px;
  background-color: #9ecef5; border: 1px solid #5e78ff;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.skyblue:hover { box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);}

.littleskyblue {
  cursor: pointer; padding: 0.5rem 1.0 rem; margin: 0 3px;
  background-color: #c6d3ff; border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);  
}
.littleskyblue:hover { box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);}

.orange {  
  cursor: pointer; padding: 0.5rem 1.0 rem; margin: 0 3px;
  background-color: #f5c04e; border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.orange:hover { box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);}

.littleviolet {  
  cursor: pointer; padding: 0.5rem 1.0 rem; margin: 0 3px;
  background-color: #c06ef7; border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.littleviolet:hover { box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);}

.violet {  
  cursor: pointer; padding: 0.5rem 1.0 rem; margin: 0 3px;
  background-color: #4b20af; color: white; border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.violet:hover { box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);}

.strongskyblue {  
  cursor: pointer; padding: 0.5rem 1.0 rem; margin: 0 3px;
  background-color: #3a9dee; border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.strongskyblue:hover { box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);}
</style>
